import SignalRModule from "./signalR";
import RequestUtils from '../api/RequestUtils';
import { decode } from "./encryption";

export default class ChatHub {

    static call = {
        listenerRoom: "ListenerRoom",
        sendGroupMessage: "GroupMessage", // Trocar o nome ?
        sendListener: "Listener",
        deleteGroupMessage: "DeleteGroupMessage"
    }

    static events = {
        onDeleteMessage: "onDeleteReceived",
        onRoomOpened: "onRoomOpened",
        onChatEvent: "onChatReceived",
        onGroupEvent: "onGroupReceived",
        onTicketEvent: "onTicketReceived"
    }

    static hub;

    static start() {
        ChatHub.hub = SignalRModule.connectToHub(RequestUtils.HUB_CHAT);
        ChatHub.hub.serverTimeoutInMilliseconds = 60 * 1000;
        return SignalRModule.start(ChatHub.hub);
    }

    static stop() {
        let p0 = ChatHub.hub && SignalRModule.stop(ChatHub.hub);
        ChatHub.hub = null;
        return p0;
    }

    static send(methodName, ...body) {
        return SignalRModule.send(methodName, ChatHub.hub, ...body);
    }

    static invoke(methodName, ...body) {
        return SignalRModule.invoke(methodName, ChatHub.hub, ...body);
    }

    static subscribe(methodName, callback) {
        ChatHub.hub && SignalRModule.subscribe(methodName, ChatHub.hub, callback);
    }

    static unsubscribe(methodName) {
        ChatHub.hub && SignalRModule.unsubscribe(methodName, ChatHub.hub);
    }

    static onClose(callback) {
        ChatHub.hub && SignalRModule.onClose(callback, ChatHub.hub);
    }

    static getMessageByType(type, message) {
        switch (type) {
            case "audio":
                return "Audio";
            case "select":
                return "Selecione uma opção";
            case "image":
                return "Imagem";
            case "text":
                return JSON.parse(decode(message));
            default:
                return type;
        }
    }
}
