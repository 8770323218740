import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Avatar,
    Card,
    CardContent,
    Grid,
    Typography,
    colors
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ErrorIcon from '@mui/icons-material/Error';

const useStyles = makeStyles(() => ({
    root: {
        height: '100%'
    },
    avatar: {
        backgroundColor: colors.orange[600],
        height: 56,
        width: 56
    }
}));

function PanicTriggersLast7Days({ className, amountItens, awaitData, ...rest }) {
    const classes = useStyles();
    return (
        <Card className={clsx(classes.root, className)} {...rest}>
            <CardContent>
                <Grid container justifyContent="space-between" spacing={3}>
                    <Grid item>
                        <Typography color="textSecondary" variant="h6" gutterBottom>
                            BOTÃO DE PÂNICO NOS ÚLTIMOS 7 DIAS
                        </Typography>
                        {!awaitData && (
                            <Typography color="textPrimary" variant="h3">
                                {amountItens}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item>
                        <Avatar className={classes.avatar}>
                            <ErrorIcon />
                        </Avatar>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

PanicTriggersLast7Days.propTypes = {
    className: PropTypes.string,
    amountItens: PropTypes.number,
    awaitData: PropTypes.bool
};

export default PanicTriggersLast7Days;
