import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { PrivateRoute, PublicRoute } from 'routes/components';
import RequestUtils from 'modules/api/RequestUtils';
import useAuth from 'hooks/useAuth';

// pages
import { Login, Errors } from 'pages';
import Routes from 'routes';

export default function App() {
	const { getToken } = useAuth();
	RequestUtils.setAuthToken(getToken());
	return (
		<Router>
			<Switch>
				<PublicRoute path="/login" component={Login} />
				<PrivateRoute path="/" component={Routes} />
				<Route component={Errors} />
			</Switch>
		</Router>
	);
};