import React from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Box, Grid, Divider, Card, CardContent, CardHeader, Typography } from '@mui/material';

import { FormikTextField, FormikSelect } from 'components/formElements';
import { masks } from 'modules/utils/mask';

const useStyles = makeStyles(() => ({
	statusUser: {
		display: 'flex',
		justifyContent: 'start',
		alignItems: 'center',
	}
}));

function VideoForm({ className, formik, idVideo, videoData, awaitData, ...rest }) {
	const classes = useStyles();

	const states = [
		{ value: false, label: 'Comum' },
		{ value: true, label: 'Premium' },
	];

    return (
        <Card {...rest} className={clsx(classes.root, className)}>
            <CardHeader
                title={idVideo === undefined ? "Cadastrar novo vídeo" : "Editar vídeo"}
                subheader={idVideo === undefined ?
                    "Informe todos os dados para cadastrar um vídeo"
                    :
                    "Informe todos os dados para editar o vídeo"
                }
            />
            <Divider />
            <form onSubmit={formik.handleSubmit}>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                            <FormikTextField fullWidth formik={formik} name="title" label="Título" />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormikTextField fullWidth formik={formik} name="url" label="URL do vídeo" />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormikSelect fullWidth formik={formik} options={states} name="premium" label="Tipo do vídeo" />
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <FormikTextField
                                fullWidth
                                formik={formik}
                                name="description"
                                label="Descrição"
                                multiline
                                rows={3}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider />
                <Box display="flex" justifyContent={idVideo === undefined ? "flex-end" : "space-between"} p={2}>

                    {idVideo &&
                        <Grid container spacing={2}>
                            <Grid item md={12} xs={12}>
                                <Typography variant="body2" color="text.secondary">
                                    Criado em {videoData?.createAt ? moment(videoData?.createAt).format('DD/MM/YYYY') : "--/--/----"}
                                </Typography>
                                <div className={classes.statusUser}>
                                    <Typography
                                        color="textSecondary"
                                        display="inline"
                                        variant="body2"
                                    >
                                        Status:
                                    </Typography>
                                    <FiberManualRecordIcon
                                        className={classes.statsIcon}
                                        sx={{ color: videoData?.videoIsActive ? 'green' : 'red' }}
                                    />
                                    <Typography
                                        color="textSecondary"
                                        display="inline"
                                        variant="body2"
                                    >
                                        {videoData?.videoIsActive ? 'Ativo' : 'Inativo'}
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    }

                    <LoadingButton
                        type="submit"
                        color="primary"
                        variant="contained"
                        loading={awaitData}
                    >
                        {idVideo === undefined ? "Cadastrar" : "Editar"}
                    </LoadingButton>
                </Box>
            </form>
        </Card>
    )
}

VideoForm.propTypes = {
    className: PropTypes.string,
    formik: PropTypes.object.isRequired,
    idVideo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    videoData: PropTypes.object,
    awaitData: PropTypes.bool.isRequired,
};

export default VideoForm;