import React from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import { NavLink as RouterLink } from 'react-router-dom';
import ChatIcon from '@mui/icons-material/Chat';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { FormikAutocomplete, FormikCheckbox, FormikSelect, FormikTextField } from 'components/formElements';
import { Box, Grid, Divider, Card, CardContent, CardHeader, Typography, IconButton } from '@mui/material';

const useStyles = makeStyles(() => ({
	statusChallenge: {
		display: 'flex',
		justifyContent: 'start',
		alignItems: 'center',
	}
}));

function ChallengeForm({ className, formik, idChallenge, awaitData, challengeData, stateMarathons, stateChallenges, maratonNotSelected, ...rest }) {
	const classes = useStyles();

	const statesAllowImage = [
		{ value: 1, label: 'Sim' },
		{ value: 0, label: 'Não' },
	];

	return (
		<Card {...rest} className={clsx(classes.root, className)}>
			<CardHeader
				title={idChallenge === undefined ? "Cadastrar novo desafio" : "Editar desafio"}
				subheader={idChallenge === undefined ?
					"Informe todos os dados para cadastrar um desafio"
					:
					"Informe todos os dados para editar o desafio"
				}

				action={
					<Box>
						{idChallenge && challengeData && challengeData.tblForum[0] &&
							<IconButton
								aria-label="health"
								component={RouterLink}
								to={`/foruns/mensagens/${challengeData?.tblForum[0]?.idForum}`}
							>
								<ChatIcon />
							</IconButton>
						}
					</Box>
				}
			/>
			<Divider />
			<form onSubmit={formik.handleSubmit}>
				<CardContent>
					<Grid container spacing={3}>
						<Grid item md={12} xs={12}>
							<FormikTextField fullWidth formik={formik} name="title" label="Título" />
						</Grid>
						<Grid item md={12} xs={12}>
							<FormikTextField
								fullWidth
								formik={formik}
								name="description"
								label="Descrição"
								multiline
								rows={3}
							/>
						</Grid>
						<Grid item md={8} xs={8}>
							<FormikTextField
								fullWidth
								formik={formik}
								name="videoUrl"
								label="Url do Vídeo"
							/>
						</Grid>
						<Grid item md={4} xs={4}>
							<FormikCheckbox
								formik={formik}
								name="isInitial"
								label="Primeiro Desafio?"
							/>
						</Grid>
						<Grid item md={6} xs={6}>
							<FormikAutocomplete
								filterSelectedOptions
								fullWidth
								name="marathon"
								formik={formik}
								label="Maratona"
								placeholder="Selecionar Maratona"
								options={stateMarathons}
								optionLabel="label"
							/>
						</Grid>
						<Grid item md={6} xs={6}>
							<FormikAutocomplete
								filterSelectedOptions
								fullWidth
								name="idNextChallenge"
								formik={formik}
								label="Próximo Desafio"
								placeholder="Selecionar Desafio"
								disabled={maratonNotSelected}
								options={stateChallenges}
								optionLabel="label"
							/>
						</Grid>
						{!idChallenge &&
							<>
								<Grid item md={8} xs={8}>
									<FormikTextField fullWidth formik={formik} name="forumTitle" label="Nome do Fórum" />
								</Grid>
								<Grid item md={4} xs={4}>
									<FormikSelect
										fullWidth
										formik={formik}
										name="allowImage"
										label="Permitir Imagens"
										options={statesAllowImage}
									/>
								</Grid>
							</>
						}
					</Grid>
				</CardContent>
				<Divider />
				<Box display="flex" justifyContent="flex-end" p={2}>
					{idChallenge &&
						<Grid container spacing={2}>
							<Grid item md={12} xs={12}>
								<Typography variant="body2" color="text.secondary">
									Criado em {challengeData?.challengeCreateAt ? moment(challengeData?.challengeCreateAt).format('DD/MM/YYYY') : "--/--/----"}
								</Typography>
								<div className={classes.statusChallenge}>
									<Typography
										color="textSecondary"
										display="inline"
										variant="body2"
									>
										Status:
									</Typography>
									<FiberManualRecordIcon
										className={classes.statsIcon}
										sx={{ color: challengeData?.challengeIsActive ? 'green' : 'red' }}
									/>
									<Typography
										color="textSecondary"
										display="inline"
										variant="body2"
									>
										{challengeData?.challengeIsActive ? 'Ativo' : 'Inativo'}
									</Typography>
								</div>
							</Grid>
						</Grid>
					}

					<LoadingButton
						type="submit"
						color="primary"
						variant="contained"
						loading={awaitData}
					>
						{idChallenge === undefined ? "Cadastrar" : "Editar"}
					</LoadingButton>
				</Box>
			</form>
		</Card>
	);
};

ChallengeForm.propTypes = {
	className: PropTypes.string,
	formik: PropTypes.object.isRequired,
	idChallenge: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	awaitData: PropTypes.bool.isRequired,
	challengeData: PropTypes.object,
	stateMarathons: PropTypes.array.isRequired,
	stateChallenges: PropTypes.array.isRequired,
	maratonNotSelected: PropTypes.bool.isRequired,
};

export default ChallengeForm;