import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import SendIcon from '@mui/icons-material/Send';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { CircularProgress, Paper, InputBase, IconButton } from '@mui/material';

import { readFileAsDataURL } from "modules/utils/encryption";

const useStyles = makeStyles(() => ({
    inputContainer: {
        p: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '80%',
    },
    inputStyle: {
        flex: 1,
        marginLeft: '10px'
    },
    buttonContainer: {
        p: '10px',
    },
    loadingSize: {
        width: '1em !important',
        height: '1em !important',
    }
}));

function InputMessage({
    setCommentValue, awaitDataMessage, commentValue,
    sendMessage, replayValue, groupMember, ...rest
}) {
    const classes = useStyles();

    function onChangeFile(file) {
        const base64Picture = readFileAsDataURL(file);
        base64Picture.then((res) => {
            sendMessage(res, 'img');
        })
    }

    return (
        <Paper variant="outlined" className={classes.inputContainer} {...rest}>
            <InputBase
                className={classes.inputStyle}
                placeholder="Escreva um comentário..."
                disabled={awaitDataMessage || !groupMember}
                value={commentValue}
                multiline
                maxRows={3}
                onChange={(e) => setCommentValue(e.target.value)}
                inputProps={{ 'aria-label': 'write-message' }}
            />
            <IconButton
                className={classes.buttonContainer}
                aria-label="add-img"
                variant="contained"
                disabled={awaitDataMessage || !groupMember}
                component="label"
            >
                <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => onChangeFile(e.target.files[0])}
                    hidden
                />
                <AddPhotoAlternateIcon />
            </IconButton>
            <IconButton
                color="primary"
                disabled={awaitDataMessage || !groupMember}
                className={classes.buttonContainer}
                onClick={() => sendMessage(commentValue, 'text')}
                aria-label="send-message"
            >
                {!awaitDataMessage ? (
                    <SendIcon />
                ) : (
                    <CircularProgress
                        className={classes.loadingSize}
                        color="primary"
                    />
                )}
            </IconButton>
        </Paper>
    )
}

InputMessage.propTypes = {
    awaitDataMessage: PropTypes.bool.isRequired,
    groupMember: PropTypes.bool.isRequired,
    setCommentValue: PropTypes.func.isRequired,
    commentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    replayValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sendMessage: PropTypes.func.isRequired,
}

export default InputMessage;