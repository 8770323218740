//import React from 'react';
import PropTypes from "prop-types";

const propTypes = {
	height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	init: PropTypes.func.isRequired,
	center: PropTypes.shape({
		lat: PropTypes.number.isRequired,
		lng: PropTypes.number.isRequired
	}),
	mapTypeId: PropTypes.oneOf(["roadmap", "satellite", "hybrid", "terrain"]),
	zoom: PropTypes.number,
	zoomControl: PropTypes.bool,
	mapTypeControl: PropTypes.bool,
	scaleControl: PropTypes.bool,
	streetViewControl: PropTypes.bool,
	rotateControl: PropTypes.bool,
	fullscreenControl: PropTypes.bool
};

const defaultProps = {
	height: "750px",
	center: { lat: 0.0, lng: 0.0 },
	zoom: 5,
	zoomControl: true,
	mapTypeId: "terrain",
	mapTypeControl: true,
	scaleControl: true,
	streetViewControl: true,
	rotateControl: true,
	fullscreenControl: true
};

function Map(props) {
	//const { height, init, ...rest } = props;

	// const componentMount = () => {
	// 	const map = new window.google.maps.Map(document.getElementById('map'), {
	// 		...rest,
	// 		mapTypeControlOptions: {
	// 			style: window.google.maps.MapTypeControlStyle.DROPDOWN_MENU
	// 		}
	// 	});
	// 	init(map);
	// }

	//useEffect(componentMount, []);

	//return <div id="map" style={{ width: "100%", height }} />
}

Map.propTypes = propTypes;
Map.defaultProps = defaultProps;

export default Map;