import axios from 'axios';

export default class RequestUtils {
	//static URL = 'http://localhost:44314';
	//static URL = 'http://api.parardefumarfumando.app.br';
	//static URL = 'http://apihom.parardefumarfumando.app.br';
	static URL = 'http://pareapi.alamo.com.vc';

	static HUB_CHAT = `${this.URL}/chat`;

	static API = axios.create({
		baseURL: this.URL,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			'Access-Control-Allow-Origin': '*'
		}
	});

	static setAuthToken(authToken) {
		this.API.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
	}

	static get(url, config = null) {
		return this.API.get(url, config).then((response) => response.data);
	}

	static post(url, data, config = null) {
		return this.API.post(url, data, config).then((response) => response.data);
	}

	static put(url, data, config = null) {
		return this.API.put(url, data, config).then((response) => response.data);
	}

	static delete(url, config = null) {
		return this.API.delete(url, config).then((response) => response.data);
	}

	// USER ------------------------

	static authUser = (body) => RequestUtils.post(`/User/Auth`, body, null);

	static addUser = (body) => RequestUtils.post(`/User/Add`, body, null);

	static updateUser = (body) => RequestUtils.post(`/User/UpdateAdmin`, body, null);

	static getUserById = (idUser) => RequestUtils.get(`/User/GetById?id=${idUser}`, null);

	static logicalDeleteUser = (idUser) => RequestUtils.post(`/User/LogicalDelete?idUser=${idUser}`, null);

	static getUsersPaged = (searchTerm, page, amount, userType, userActive, userPlan) => {
		let url = `/User/GetUsersPaged?searchTerm=${searchTerm}&page=${page}&amount=${amount}&userType=${userType}&userActive=${userActive}`;
		if (userPlan != null && userPlan != undefined) url += `&userPlan=${userPlan}`;
		
		return RequestUtils.get(url, null);
	};

	static updateStatusUser = (idUser) => RequestUtils.post(`/User/UpdateStatus?idUser=${idUser}`, null, null);

	static updatePasswordAdmin = (body) => RequestUtils.post(`/User/UpdatePasswordAdmin`, body, null);

    static getPasswordKey = () => RequestUtils.get(`/User/GetPasswordKey`);
	
	static getExportUsers = () => RequestUtils.get(`/User/ExportToCsv`);
	

	// DASHBOARD

	static getAmountItemsDashboard = () => RequestUtils.get(`/User/GetAmountItemsDashboard`);
	static getAmountUsersByType = () => RequestUtils.get(`/User/GetAmountUserPremiumAndCommon`);
	static getAmountUserByResultTest = () => RequestUtils.get(`/User/GetAmountUserByResultTest`);
	static getLastMessages = () => RequestUtils.get(`/Dashboard/GetTop10Messages`);
	static authAdmin = (body) => RequestUtils.post(`/User/AuthAdmin`, body, null);
    static getPanicTriggersLast24Hours = () => RequestUtils.get(`/Dashboard/GetPanicTriggersLast24Hours`);
	static getAmountPanicTriggersLast7Days = () => RequestUtils.get(`/Dashboard/GetAmountPanicTriggersLast7Days`);

	// USER HEALTH

	static getHealthDataByIdUser = (idUser) => RequestUtils.get(`/UserHealthData/GetByIdUser?idUser=${idUser}`);

	// GROUP ------------------------

	static addGroup = (body) => RequestUtils.post(`/Group/Add`, body, null);

	static updateGroup = (body) => RequestUtils.post(`/Group/Update`, body, null);

	static getGroupById = (idGroup) => RequestUtils.get(`/Group/GetById?id=${idGroup}`, null);

	static getGroupsPaged = (searchTerm, page, amount, groupActive) => RequestUtils.get(
		`/Group/GetGroupsPaged?searchTerm=${searchTerm}&page=${page}&amount=${amount}&groupActive=${groupActive}`,
		null
	);

	static updateStatusGroup = (idGroup) => RequestUtils.post(`/Group/UpdateStatus?idGroup=${idGroup}`, null, null);

	// GROUP MEMBER ------------------------

	static getGroupMembersByIdGroup = (idGroup) => RequestUtils.get(`/GroupMember/GetByIdGroup?idGroup=${idGroup}`, null);

	static addGroupMember = (body) => RequestUtils.post(`/GroupMember/Add`, body, null);

	static deleteGroupMember = (idGroupMember) => RequestUtils.post(`/GroupMember/Delete?id=${idGroupMember}`, null, null);

	static getGroupsByIdUser = (idUser) => RequestUtils.get(`/GroupMember/GroupsByIdUser?idUser=${idUser}`, null);

	static verifyGroupMember = (idUser, idGroup) => RequestUtils.get(`/GroupMember/VerifyGroupMember?idUser=${idUser}&idGroup=${idGroup}`, null);

	//GROUP MESSAGE

	static getMessageByIdGroup = (idGroup) => RequestUtils.get(`/GroupMessage/GetByIdGroup?idGroup=${idGroup}`, null);

	static getMessagePaged = (idGroup, searchTerm, page, amount) => RequestUtils.get(`/GroupMessage/GetMessagePagedByGroup?idGroup=${idGroup}&searchTerm=${searchTerm}&page=${page}&amount=${amount}`)
	
	// DASHBOARD ------------------------

	static getAmountTestSmokingLoading = () => RequestUtils.get(`/Dashboard/GetAmountTestSmokingLoading`);

	static getAmountTestFagerstrom = () => RequestUtils.get(`/Dashboard/GetAmountTestFagerstrom`);

	static getAmountForumMessageByForum = () => RequestUtils.get(`/Dashboard/GetAmountForumMessageByForum`);

	static getAmountChallengesCompletedLast24Hours = () => RequestUtils.get(`/Dashboard/GetAmountChallengesCompletedLast24Hours`);

	static getAmountMessageForumLast24Hours = () => RequestUtils.get(`/Dashboard/GetAmountMessageForumLast24Hours`);

	static getTop10Messages = () => RequestUtils.get(`/Dashboard/GetTop10Messages`);

	// REQUEST HELP ------------------------

	static getRequestHelpPaged = (date, searchTerm, page, amount) => RequestUtils.get(`/RequestHelp/GetHelpRequestPaged?date=${date}&searchTerm=${searchTerm}&page=${page}&amount=${amount}`);

	// CHALLENGE ------------------------

	static addChallenge = (body) => RequestUtils.post(`/Challenge/Add`, body, null);

	static updateChallenge = (body) => RequestUtils.post(`/Challenge/Update`, body, null);

	static unlinkMarathon = (idChallenge) => RequestUtils.post(`/Challenge/UnlinkMarathonAndNextChallenge?id=${idChallenge}`, null);

	static getChallengeById = (idChallenge) => RequestUtils.get(`/Challenge/GetById?id=${idChallenge}`, null);

	static getChallengeByIdMarathon = (idMarathon) => RequestUtils.get(`/Challenge/GetByIdChallengeMarathon?idChallengeMarathon=${idMarathon}`, null);

	static getAllChallenge = () => RequestUtils.get(`/Challenge/GetAll`, null);

	static deleteChallenge = (idChallenge) => RequestUtils.post(`/Challenge/Delete?id=${idChallenge}`, null, null);

	static getChallengePaged = (searchTerm, page, amount, challengeIsActive) => RequestUtils.get(
		`/Challenge/GetChallengePaged?searchTerm=${searchTerm}&page=${page}&amount=${amount}&challengeIsActive=${challengeIsActive}`,
		null
	);

	static userPercentageOfConclusion = (idUser) => RequestUtils.get(`/Challenge/UserPercentageOfConclusion?idUser=${idUser}`, null);

	static getByIdChallengeMarathon = (idChallengeMarathon) => RequestUtils.get(`/Challenge/GetByIdChallengeMarathon?idChallengeMarathon=${idChallengeMarathon}`, null);

	static updateStatusChallenge = (idChallenge) => RequestUtils.post(`/Challenge/UpdateStatus?idChallenge=${idChallenge}`, null, null);

	static getChallengeUserParticipateAndProgress = (idUser) => RequestUtils.get(`Challenge/GetChallengeUserParticipateAndProgress?idUser=${idUser}`, null);

	static getChallengeNextChallengeInfo = (idChallengeMarathon) => RequestUtils.get(`Challenge/GetByIdChallengeMarathonWithNextChallengeInfo?idChallengeMarathon=${idChallengeMarathon}`, null);

	// CHALLENGE  USER------------------------

	static getUsersByIdChallenge = (idChallenge) => RequestUtils.get(`/ChallengeUser/GetUsersByIdChallenge?idChallenge=${idChallenge}`, null);

	static deleteChallengeUser = (idChallengeUser) => RequestUtils.post(`/ChallengeUser/Delete?id=${idChallengeUser}`, null, null);

	static addChallengeUser = (body) => RequestUtils.post(`/ChallengeUser/Add`, body, null);

	// CHALLENGE MARATHON------------------------

	static getAllMarathon = () => RequestUtils.get(`/ChallengeMarathon/GetAll`, null);

	// VIDEO ------------------------

	static addVideo = (body) => RequestUtils.post(`/Video/Add`, body, null);

	static updateVideo = (body) => RequestUtils.post(`/Video/Update`, body, null);

	static getVideoById = (idVideo) => RequestUtils.get(`/Video/GetById?id=${idVideo}`, null);

	static getVideoPaged = (searchTerm, page, amount, premium, challengeIsActive) => RequestUtils.get(
		`/Video/GetVideosPaged?searchTerm=${searchTerm}&page=${page}&amount=${amount}&premium=${premium}&isActive=${challengeIsActive}`,
		null
	);

	static updateStatusVideo = (idVideo) => RequestUtils.post(`/Video/UpdateStatus?idVideo=${idVideo}`, null, null);

	// CHALLENGE STEPS ------------------------

	static addChallengeStep = (body) => RequestUtils.post(`/ChallengeStep/Add`, body, null);

	static deleteChallengeStep = (idChallengeStep) => RequestUtils.post(`/ChallengeStep/Delete?id=${idChallengeStep}`, null, null);

	static challengeStepFisicalDelete = (idChallengeStep) => RequestUtils.post(`/ChallengeStep/FisicalDelete?id=${idChallengeStep}`, null, null);

	static getChallengeStepsByIdChallenge = (idChallenge) => RequestUtils.get(`/ChallengeStep/GetByIdChallenge?idChallenge=${idChallenge}`, null);

	// CHALLENGE USER STEP

	static getChallengeStepsByUserAndChallenge = (idUser, idChallenge) => RequestUtils.get(`/ChallengeUserStep/GetStepsByUser?idUser=${idUser}&idChallenge=${idChallenge}`, null);

	// NOTIFICATION ------------------------

	static addNotification = (body) => RequestUtils.post(`/Notification/Add`, body, null);

	static getNotificationPaged = (searchTerm, page, amount) => RequestUtils.get(
		`/Notification/GetNotificationPaged?searchTerm=${searchTerm}&page=${page}&amount=${amount}`,
		null
	);

	static getNotificationById = (idNotification) => RequestUtils.get(`/Notification/GetById?id=${idNotification}`, null);

	// DIARY

	static getDiaryByIdUser = (idUser) => RequestUtils.get(`/UserDiary/GetByIdUser?idUser=${idUser}`, null);

	// TEST SMOKINGLOAD ------------------------

	static getTestSmokingLoadByIdUser = (idUser) => RequestUtils.get(`/TestSmokingLoad/GetByIdUser?idUser=${idUser}`, null);

	// TEST FARGESTROM ------------------------

	static getTestFargerstromByIdUser = (idUser) => RequestUtils.get(`/TestUser/GetLastTestByIdUser?idUser=${idUser}`, null);

	// FORUM ------------------------

	static addForum = (body) => RequestUtils.post(`/Forum/Add`, body, null);

	static updateForum = (body) => RequestUtils.post(`/Forum/Update`, body, null);

	static getForumById = (idForum) => RequestUtils.get(`/Forum/GetById?id=${idForum}`, null);

	static getForumPaged = (searchTerm, page, amount, forumIsActive) => RequestUtils.get(
		`/Forum/GetForumPaged?searchTerm=${searchTerm}&page=${page}&amount=${amount}&forumIsActive=${forumIsActive}`,
		null
	);

	static updateStatusForum = (idForum) => RequestUtils.post(`/Forum/UpdateStatus?idForum=${idForum}`, null, null);

	// FORUM MESSAGE ------------------------

	static getMessageByIdForum = (idForum, searchTerm, page, amount) => RequestUtils.get(`/ForumMessage/GetMessageByForum?idForum=${idForum}&searchTerm=${searchTerm}&page=${page}&amount=${amount}`, null, null);

	static addForumMessage = (body) => RequestUtils.post(`/ForumMessage/Add`, body, null);

	static deleteForumMessage = (idMessageForum) => RequestUtils.delete(`/ForumMessage/Delete?id=${idMessageForum}`, null);

	// FORUM REACTIONS ------------------------

	static addForumReaction = (body) => RequestUtils.post(`/ForumReaction/Add`, body, null);

	static deleteForumReaction = (idForumReaction) => RequestUtils.delete(`/ForumReaction/Delete?id=${idForumReaction}`, null);

	// MARATHON ------------------------

	static getMarathonPaged = (searchTerm, page, amount, isActive) => RequestUtils.get(`/ChallengeMarathon/GetChallengeMarathonPaged?page=${page}&amount=${amount}&searchTerm=${searchTerm}&challengeMarathonIsActive=${isActive}`, null);

	static addMarathon = (body) => RequestUtils.post(`/ChallengeMarathon/Add`, body, null);

	static updateStatusMarathon = (idMarathon) => RequestUtils.post(`/ChallengeMarathon/UpdateStatus?idMarathon=${idMarathon}`, null, null);

	static updateMarathon = (body) => RequestUtils.post(`/ChallengeMarathon/Update`, body, null);

	static getMarathonById = (idMarathon) => RequestUtils.get(`/ChallengeMarathon/GetById?id=${idMarathon}`, null);

	static deleteMarathon = (idMarathon) => RequestUtils.delete(`/ChallengeMarathon/Delete?id=${idMarathon}`, null);

	// LINK PREMIUM ------------------------

	static getLinkPremiumById = () => RequestUtils.get(`/LinkPremium/Get`, null);

	static updateLinkPremium = (body) => RequestUtils.post(`/LinkPremium/Update`, body, null);
}