import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Grid, Typography,
  Avatar, Button
} from '@mui/material'

import useStyles from "./ImagePicker.styles";

import { readFileAsDataURL } from "modules/utils/encryption";

const propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  error: PropTypes.string,
  size: PropTypes.number,
  fileSizeLimt: PropTypes.number,
  image: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  title: 'Imagem',
  error: '',
  size: 80,
  fileSizeLimt: 5,
  image: '',
};

function ImagePicker (props) {
  const { name, title, error, size, fileSizeLimt, image, onChange } = props;
  const classes = useStyles({size});
  const [ sizeError, setSizeError ] = useState(null);

  const handleChange = async (e) => {
    const file = e.target.files[0];
    if(!file) return;
    const fileSize = ((file.size/1024)/1024).toFixed(4);
    if(fileSize > fileSizeLimt) return setSizeError(`Arquivo maior que ${fileSizeLimt}Mb`);
    setSizeError(null);
    const imageBase64 = await readFileAsDataURL(file);
    onChange(imageBase64, e);
  }

  const removeImage = (e) => {
    const input = document.getElementById(name);
    input.value = '';
    setSizeError(null);
    onChange('', e);
  }

  return (
    <>
      <input
        type="file"
        accept="image/*"
        id={name}
        onChange={handleChange}
        className={classes.input}
      />
      <Grid container spacing={3}>
        <Grid item>
          <div className={classes.frame}>
            <Avatar
              alt="image-picker"
              variant="square"
              title={title}
              src={image}
              className={classes.image}
            >
              Logo
            </Avatar>
          </div>
        </Grid>
        <Grid item xs>
          <Grid container direction="column">
            <Typography variant="h6">{title}</Typography>
            {fileSizeLimt && <Typography variant="caption" gutterBottom>Tamanho máximo do arquivo {fileSizeLimt}Mb</Typography>}
          </Grid>
          <Grid container spacing={1}>
            <Grid item>
              <Button 
                size="small" 
                variant="outlined" 
                component="label" 
                htmlFor={name}
              >
                Selecione uma imagem
              </Button>
            </Grid>
            <Grid item>
              <Button 
                size="small" 
                variant="outlined" 
                onClick={removeImage}
                className={classes.removeButton}
              >
                Remover imagem
              </Button>
            </Grid>
          </Grid>
          {sizeError ? (
            <Typography className={classes.errorText} variant="caption">{sizeError}</Typography>
          ) : error && (
            <Typography className={classes.errorText} variant="caption">{error}</Typography>
          )}
        </Grid>
      </Grid>
    </>
  )
}

ImagePicker.propTypes = propTypes;
ImagePicker.defaultProps = defaultProps;

export default ImagePicker;