import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import {
	Box, Card, CardContent,
	CardHeader, Divider, Typography,
	colors, useTheme, CircularProgress
} from '@mui/material';

import Chart from 'components/Chart';
import Paid from '@mui/icons-material/Paid';
import AccountCircle from '@mui/icons-material/AccountCircle';

const useStyles = makeStyles(() => ({
	root: {
		height: '100%'
	}
}));


function UsersByType({ className, amountUsers, awaitData, ...rest }) {
	const classes = useStyles();
	const theme = useTheme();

	function getPercentageData() {
		const totalUsers = amountUsers?.amountUserPremium + amountUsers?.amountUserCommon;

		const percentageValues = {
			common: (amountUsers?.amountUserCommon * 100) / totalUsers,
			premium: (amountUsers?.amountUserPremium * 100) / totalUsers
		}

		return percentageValues;
	}

	const data = {
		datasets: [
			{
				data: [amountUsers?.amountUserPremium, amountUsers?.amountUserCommon],
				backgroundColor: [
					colors.green[700],
					colors.lightBlue[700]
				],
				borderWidth: 8,
				borderColor: colors.common.white,
				hoverBorderColor: colors.common.white
			}
		],
		labels: ['Premium', 'Comum']
	};

	const options = {
		animation: false,
		cutoutPercentage: 80,
		layout: { padding: 0 },
		legend: {
			display: false
		},
		maintainAspectRatio: false,
		responsive: true,
		tooltips: {
			backgroundColor: theme.palette.background.default,
			bodyFontColor: theme.palette.text.secondary,
			borderColor: theme.palette.divider,
			borderWidth: 1,
			enabled: true,
			footerFontColor: theme.palette.text.secondary,
			intersect: false,
			mode: 'index',
			titleFontColor: theme.palette.text.primary
		}
	};

	const devices = [
		{
			title: 'Comum',
			value: getPercentageData().common,
			icon: AccountCircle,
			color: colors.lightBlue[700]
		},
		{
			title: 'Premium',
			value: getPercentageData().premium,
			icon: Paid,
			color: colors.green[700]
		}
	];

	return (
		<Card className={clsx(classes.root, className)} {...rest}>
			<CardHeader title="USUÁRIOS POR TIPO" />
			<Divider />
			<CardContent>

				<CardContent>
					{!awaitData ? (
						amountUsers ?
							<>
								<Chart
									height="335px"
									type="doughnut"
									data={data}
									options={options}
								/>
								<Box display="flex" justifyContent="center" mt={2}>
									{devices.map(({
										color,
										icon: Icon,
										title,
										value
									}) => (
										<Box key={title} p={1} textAlign="center">
											<Icon color="action" />
											<Typography color="textPrimary" variant="body1">
												{title}
											</Typography>
											<Typography style={{ color }} variant="h2">
												{value ? `${value.toFixed(2)}%` : `0%`}
											</Typography>
										</Box>
									))}
								</Box>
							</>
							:
							<Box mt={3} mb={3} display="flex" justifyContent="center" alignItems='center' style={{ height: 300 }}>
								<Typography variant="h6">
									Não há dados
								</Typography>
							</Box>
					) : (
						<Box mt={3} display="flex" justifyContent="center">
							<CircularProgress />
						</Box>
					)}
				</CardContent>
			</CardContent>
		</Card>
	);
};

UsersByType.propTypes = {
	className: PropTypes.string,
	amountUsers: PropTypes.object,
    awaitData: PropTypes.bool.isRequired
};

export default UsersByType;
