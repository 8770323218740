import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Box, Grid, Divider, Card, CardContent, CardHeader, Typography } from '@mui/material';

import { FormikTextField, FormikAutocomplete, FormikSelect } from 'components/formElements';

const useStyles = makeStyles(() => ({
    statusForum: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
    }
}));

function ForumForm({ className, formik, awaitData, arrayChallenges, forumData, ...rest }) {
    const classes = useStyles();

    const stateChallenges = useMemo(() => {
        const arr = arrayChallenges.map((challenges) => ({
            value: challenges.idChallenge,
            label: challenges.challengeTitle
        }));

        return arr;
    }, [arrayChallenges])

    const statesAllowImage = [
		{ value: 1, label: 'Sim' },
		{ value: 0, label: 'Não' },
	];

    return (
        <Card {...rest} className={clsx(classes.root, className)}>
            <CardHeader
                title={"Editar fórum"}
                subheader={"Informe todos os dados para editar o fórum"}
            />
            <Divider />
            <form onSubmit={formik.handleSubmit}>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                            <FormikTextField fullWidth formik={formik} name="title" label="Título" />
                        </Grid>
                        <Grid item md={8} xs={8}>
                            <FormikAutocomplete
                                filterSelectedOptions
                                fullWidth
                                name="idChallenge"
                                formik={formik}
                                label="Desafio"
                                placeholder="Selecionar desafio"
                                options={stateChallenges}
                                optionLabel="label"
                            />
                        </Grid>
                        <Grid item md={4} xs={4}>
                            <FormikSelect
                                fullWidth
                                formik={formik}
                                name="allowImage"
                                label="Permitir Imagens"
                                options={statesAllowImage}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider />

                <Box display="flex" justifyContent={"space-between"} p={2}>
                    {forumData &&
                        <Grid container spacing={2}>
                            <Grid item md={12} xs={12}>
                                <Typography variant="body2" color="text.secondary">
                                    Criado em {forumData?.forumCreateAt ? moment(forumData?.forumCreateAt).format('DD/MM/YYYY') : "--/--/----"}
                                </Typography>
                                <div className={classes.statusForum}>
                                    <Typography
                                        color="textSecondary"
                                        display="inline"
                                        variant="body2"
                                    >
                                        Status:
                                    </Typography>
                                    <FiberManualRecordIcon
                                        className={classes.statsIcon}
                                        sx={{ color: forumData?.forumIsActive ? 'green' : 'red' }}
                                    />
                                    <Typography
                                        color="textSecondary"
                                        display="inline"
                                        variant="body2"
                                    >
                                        {forumData?.forumIsActive ? 'Ativo' : 'Inativo'}
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    }
                    <LoadingButton
                        type="submit"
                        color="primary"
                        variant="contained"
                        loading={awaitData}
                    >
                        {"Editar"}
                    </LoadingButton>
                </Box>
            </form>
        </Card>
    );
}

ForumForm.propTypes = {
    className: PropTypes.string,
    formik: PropTypes.object.isRequired,
    awaitData: PropTypes.bool.isRequired,
    arrayChallenges: PropTypes.array.isRequired,
    forumData: PropTypes.object,
}

export default ForumForm;