import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { makeStyles } from '@mui/styles';
import { Box, Container } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import Page from 'components/Page';
import useAuth from 'hooks/useAuth';
import useSnackbar from 'hooks/useSnackbar';
import useLocalStorage from 'hooks/useLocalStorage';
import RequestUtils from 'modules/api/RequestUtils';
import { encryptPassword } from 'modules/utils/encryption';
import { FormikTextField } from 'components/formElements';
import { Logo } from 'assets/images';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		height: '100%',
		paddingBottom: theme.spacing(3),
		paddingTop: theme.spacing(3)
	}
}));

const validationSchema = Yup.object({
	email: Yup.string().email('Digite um email válido').max(255).required('Email é obrigatório'),
	password: Yup.string().max(255).required('Senha é obrigatória')
});

function LoginView() {
	const classes = useStyles();
	const snackbar = useSnackbar();
	const [currentUser, setCurrentUser] = useLocalStorage('currentUser');
	const { signIn } = useAuth();
	const [awaitData, setAwaitingData] = useState(false);

	function handleSignIn(dto) {
		setAwaitingData(true);
		RequestUtils.authAdmin(dto).then((res) => {
			if (res?.svStatus && res.data) {
				setCurrentUser(res.data.user);
				signIn(res.data.token);
			} else {
				snackbar(res.msg || "Não foi possível fazer login.").error();
			}
			setAwaitingData(false);
		}).catch((e) => {
			snackbar("Erro ao fazer login.").error();
			setAwaitingData(false);
		});
	}

	function handleLoginGetKey(values) {
		setAwaitingData(true);
		RequestUtils.getPasswordKey().then((res) => {
			if (res?.svStatus && res.data) {
				handleSignIn({
					email: values.email,
					password: encryptPassword(values.password, res.data.key)
				})
			} else {
				snackbar(res.msg || "Não foi possível autenticar o usuário.").error();
			}
			setAwaitingData(false);
		}).catch((e) => {
			snackbar("Erro ao autenticar o usuário.").error();
			setAwaitingData(false);
		});
	}

	const formik = useFormik({
		initialValues: {
			email: '',
			password: ''
		},
		validationSchema,
		onSubmit: handleLoginGetKey
	});

	return (
		<Page className={classes.root} title="Login">
			<Box
				display="flex"
				flexDirection="column"
				height="100%"
				justifyContent="center"
			>
				<Container maxWidth="sm">
					<form onSubmit={formik.handleSubmit}>
						<Box
							my={2}
							justifyContent="center"
							display="flex"
						>
							<img alt='Logo Kairos' height="200px"
								src={Logo}
							/>
						</Box>
						<FormikTextField
							fullWidth
							formik={formik}
							margin="normal"
							name="email"
							label="E-mail"
						/>
						<FormikTextField
							fullWidth
							formik={formik}
							margin="normal"
							name="password"
							type="password"
							label="Senha"
						/>
						<Box
							my={2}
							justifyContent="end"
							display="flex"
						>
							<LoadingButton
								type="submit"
								color="primary"
								disabled={awaitData}
								size="large"
								variant="contained"
								loading={awaitData}
							>
								Entrar
							</LoadingButton>
						</Box>
					</form>
				</Container>
			</Box>
		</Page >
	);
};

export default LoginView;