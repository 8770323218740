import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { NavLink as RouterLink } from 'react-router-dom';
import {
    Grid, Divider, Card,
    CardContent, CardHeader, Typography,
    Avatar, Paper, Box,
    CircularProgress,
} from '@mui/material';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    paperContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 5,
        cursor: 'pointer',
    }
}));

function GroupsByUserList({ className, arrGroups, awaitData, ...rest }) {
    const classes = useStyles();

    return (
        <Card {...rest} className={clsx(classes.root, className)}>
            <CardHeader
                title="Grupos"
                subheader="Todos os grupos do usuário atual"
            />
            <Divider />
            <CardContent>
                {!awaitData ? (
                    <>
                        {arrGroups.length > 0 ? (
                            <Grid container justifyContent="center" justifyItems="center" alignItems="center" spacing={1}>
                                {arrGroups.map((group, idx) => (
                                    <Grid key={idx} item md={4} sm={6} xs={12}>
                                        <Paper
                                            elevation={3}
                                            className={classes.paperContainer}
                                            component={RouterLink}
                                            to={`/grupos/editar/${group.idGroup}`}
                                        >
                                            <Avatar>
                                                {group?.idGroupNavigation?.groupTitle[0]}
                                            </Avatar>
                                            <Typography
                                                color="textSecondary"
                                                display="inline"
                                                variant="body2"
                                            >
                                                <b>{group?.idGroupNavigation?.groupTitle}</b>
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                ))}
                            </Grid>
                        ) : (
                            <Box mt={3} display="flex" justifyContent="center">
                                <Typography variant="body1" gutterBottom>
                                    Nenhum item encontrado.
                                </Typography>
                            </Box>
                        )}
                    </>
                ) : (
                    <Box mt={3} display="flex" justifyContent="center">
                        <CircularProgress />
                    </Box>
                )}
            </CardContent>
        </Card>
    )
}

GroupsByUserList.propTypes = {
    className: PropTypes.string,
    arrGroups: PropTypes.array.isRequired,
    awaitData: PropTypes.bool.isRequired,
}

export default GroupsByUserList;