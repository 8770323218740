//import { v5 as uuidv5 } from 'uuid';
import { useGlobalDispatch, useGlobalState } from 'providers/global';
import { signInAction, signOutAction } from 'providers/global/actions';
import { keys, encryptByAES, decryptByAES } from "modules/utils/encryption";

function useAuth() {
	const { isAuthenticated, token } = useGlobalState();
	const globalDispatch = useGlobalDispatch();

	const setToken = (token) => {
		//const uuid = uuidv5(token, uuidv5.DNS);
		const encryptedToken = encryptByAES(token, keys.AUTH);
		globalDispatch(signInAction(encryptedToken));
	};

	const getToken = () => {
		return decryptByAES(token, keys.AUTH) || '';
	};

	const signIn = (token) => {
		setToken(token);
	};

	const signOut = () => {
		globalDispatch(signOutAction());
	};

	return {
		isAuthenticated,
		setToken,
		getToken,
		signIn,
		signOut,
	};
}

export default useAuth;