import React from "react";
import PropTypes from "prop-types";
import {
	Table,
	TableHead,
	TableBody,
	TableContainer,
	TableRow,
	TableCell,
	TablePagination,
	TableSortLabel,
	Paper,
	Checkbox,
	IconButton,
	Backdrop,
	CircularProgress
} from "@mui/material/";
import clsx from 'clsx';
import {
	KeyboardArrowLeft,
	KeyboardArrowRight,
	FirstPage as FirstPageIcon,
	LastPage as LastPageIcon
} from '@mui/icons-material/';
import { useTheme } from "@mui/styles";

import { useEnhancedTableStyles, useTablePaginationStyles } from './EnhancedTable.styles';

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) return -1;
	if (b[orderBy] > a[orderBy]) return 1;
	return 0;
}

function getComparator({ order, orderBy }) {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
	const {
		columns,
		checkboxSelection,
		disableColumnSelector,
		onSelectAllClick,
		sortModel,
		numSelected,
		rowCount,
		onRequestSort,
	} = props;

	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				{checkboxSelection && (
					<TableCell padding="checkbox" align="center" style={{ width: 50 }}>
						{!disableColumnSelector && (
							<Checkbox
								indeterminate={numSelected > 0 && numSelected < rowCount}
								checked={rowCount > 0 && numSelected === rowCount}
								onChange={onSelectAllClick}
								inputProps={{ "aria-label": "select all rows" }}
							/>
						)}
					</TableCell>
				)}
				{columns.map((column) => {
					if (column.hide) return null;
					const sortActive = sortModel.orderBy === column.id;
					return (
						<TableCell
							key={column.id}
							variant="head"
							padding={column.paddingHeader || "normal"}
							align={column.align || "left"}
							sortDirection={column.sortable ? (sortActive ? sortModel.order : false) : false}
							style={{ width: column.width }}
						>
							{column.sortable ? (
								<TableSortLabel
									active={sortActive}
									direction={sortActive ? sortModel.order : "asc"}
									onClick={createSortHandler(column.id)}
								>
									{column.renderHeader ? column.renderHeader({ sortActive }) : column.headerName}
								</TableSortLabel>
							) : (
								column.renderHeader ? column.renderHeader({ sortActive }) : column.headerName
							)}
						</TableCell>
					)
				})}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	columns: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
		hide: PropTypes.bool,
		sortable: PropTypes.bool,
		width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		align: PropTypes.oneOf(["center", "inherit", "justify", "left", "right"]),
		padding: PropTypes.oneOf(["checkbox", "none", "default"]),
		paddingHeader: PropTypes.oneOf(["checkbox", "none", "default"]),
		renderHeader: PropTypes.func,
		headerName: PropTypes.string,
		renderCell: PropTypes.func,
	})).isRequired,
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	checkboxSelection: PropTypes.bool.isRequired,
	disableColumnSelector: PropTypes.bool.isRequired,
	sortModel: PropTypes.shape({
		order: PropTypes.oneOf(["asc", "desc"]),
		orderBy: PropTypes.string
	}).isRequired,
	rowCount: PropTypes.number.isRequired,
};

function TablePaginationActions(props) {
	const classes = useTablePaginationStyles();
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange } = props;

	const lastPage = Math.max(0, Math.ceil(count / rowsPerPage) - 1);

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, lastPage);
	};

	return (
		<div className={classes.root}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
				{theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</div>
	);
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired
};

function EnhancedTable(props) {
	const classes = useEnhancedTableStyles();
	const {
		className,
		loading,
		columns,
		rows,
		dense,
		disableSelectionOnClick,
		checkboxSelection,
		disableColumnSelector,
		rowsPerPageOptions,
		onChangeRowsPerPage,
		onChangeSortModel,
		onRowSelected,
		onRowClick,
		onSelectionChange,
		onChangePage
	} = props;

	const [rowsPerPage, setRowsPerPage] = React.useState(props.rowsPerPage);
	const [sortModel, setSortModel] = React.useState(props.sortModel);
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(props.page);

	const prevProps = React.useRef(props);

	React.useEffect(() => {
		if (prevProps.current.rowsPerPage !== props.rowsPerPage) setRowsPerPage(props.rowsPerPage);
		if (prevProps.current.sortModel !== props.sortModel) setSortModel(props.sortModel);
		if (prevProps.current.page !== props.page) setPage(props.page);
		prevProps.current = props;
	}, [props]);

	const handleRequestSort = (event, property) => {
		const isAsc = sortModel.orderBy === property && sortModel.order === "asc";
		const newSortModel = {
			order: isAsc ? "desc" : "asc",
			orderBy: property
		}

		if (onChangeSortModel) onChangeSortModel?.(newSortModel);
		else setSortModel(newSortModel);
	};

	const handleSelectAll = (event) => {
		if (event.target.checked) {
			const newSelected = rows.map((n) => n.id);
			onSelectionChange?.(newSelected);
			setSelected(newSelected);
		} else {
			onSelectionChange?.([]);
			setSelected([])
		}
	};

	const handleRowSelected = (event, id) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected = [];

		if (selectedIndex >= 0) {
			newSelected = newSelected.concat(selected);
			newSelected.splice(selectedIndex, 1);
		} else {
			newSelected = newSelected.concat(selected, id)
			onRowSelected?.(event, id);
		}

		onSelectionChange?.(newSelected);
		setSelected(newSelected);
	};

	const handleClickRow = (event, id) => {
		onRowClick?.(event, id);
		if (!disableSelectionOnClick) handleRowSelected(event, id);
	}

	const handleChangePage = (event, newPage) => {
		if (onChangePage) onChangePage?.(event, newPage);
		else setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		if (onChangeRowsPerPage) return onChangeRowsPerPage?.(event);
		setPage(0);
		setRowsPerPage(parseInt(event.target.value, 10));
		handleChangePage(event, 0);
	};

	const isSelected = (id) => selected.indexOf(id) !== -1;

	function getLoadingTableHeight() {
		if (checkboxSelection) return (dense ? 61 : 81) * rowsPerPage;
		return (dense ? 33 : 53) * rowsPerPage;
	}

	return (
		<Paper className={clsx(classes.paper, className)}>
			<Backdrop className={classes.backdropTable} open={loading}>
				<CircularProgress />
			</Backdrop>
			<TableContainer className={classes.tableContainer}>
				<Table
					id="enhanced-table"
					className={classes.table}
					aria-labelledby="tableTitle"
					size={dense ? "small" : "medium"}
					aria-label="enhanced table"
				>
					<EnhancedTableHead
						classes={classes}
						columns={columns}
						numSelected={selected.length}
						checkboxSelection={checkboxSelection}
						disableColumnSelector={disableColumnSelector}
						sortModel={sortModel}
						onSelectAllClick={handleSelectAll}
						onRequestSort={handleRequestSort}
						rowCount={rows.length}
					/>
					<TableBody>
						{loading ? (
							<TableRow style={{ height: getLoadingTableHeight() }} />
						) : stableSort(rows, getComparator(sortModel))
						.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
						.map((item, index) => {
							const isItemSelected = isSelected(item.id);
							const labelId = `enhanced-table-checkbox-${index}`;
							return (
								<TableRow
									hover
									onClick={(event) => handleClickRow(event, item.id)}
									role="checkbox"
									aria-checked={isItemSelected}
									tabIndex={-1}
									key={item.id}
									selected={isItemSelected}
								>
									{checkboxSelection && (
										<TableCell padding="checkbox" align="center" style={{ width: 50 }}>
											<Checkbox
												checked={isItemSelected}
												onChange={(event) => handleRowSelected(event, item.id)}
												inputProps={{ "aria-labelledby": labelId }}
											/>
										</TableCell>
									)}
									{columns.map((column, indexColumn) => {
										if (column.hide) return null;
										return (
											<TableCell
												key={indexColumn}
												variant="body"
												align={column.align || "left"}
												padding={column.padding || "normal"}
												style={{ width: column.width }}
											>
												{column.renderCell ? column.renderCell({ item, index, isItemSelected }) : item[column.id]}
											</TableCell>
										)
									})}
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				component="div"
				rowsPerPageOptions={rowsPerPageOptions}
				count={rows.length}
				rowsPerPage={rowsPerPage}
				labelRowsPerPage="Linhas por página:"
				labelDisplayedRows={({ from, to, count }) =>
					`${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`
				}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				ActionsComponent={TablePaginationActions}
			/>
		</Paper>
	);
}

EnhancedTable.propTypes = {
	className: PropTypes.string,
	loading: PropTypes.bool,
	dense: PropTypes.bool,
	rows: PropTypes.arrayOf(PropTypes.object).isRequired,
	columns: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
		hide: PropTypes.bool,
		sortable: PropTypes.bool,
		width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		align: PropTypes.oneOf(["center", "inherit", "justify", "left", "right"]),
		padding: PropTypes.oneOf(["checkbox", "none", "default"]),
		paddingHeader: PropTypes.oneOf(["checkbox", "none", "default"]),
		renderHeader: PropTypes.func,
		headerName: PropTypes.string,
		renderCell: PropTypes.func,
	})).isRequired,
	sortModel: PropTypes.shape({
		order: PropTypes.oneOf(["asc", "desc"]),
		orderBy: PropTypes.string
	}),
	onChangeSortModel: PropTypes.func,
	rowsPerPage: PropTypes.number,
	onChangeRowsPerPage: PropTypes.func,
	rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
	onRowClick: PropTypes.func,
	onRowSelected: PropTypes.func,
	onSelectionChange: PropTypes.func,
	page: PropTypes.number,
	onChangePage: PropTypes.func,
	disableSelectionOnClick: PropTypes.bool,
	checkboxSelection: PropTypes.bool,
	disableColumnSelector: PropTypes.bool,
};

EnhancedTable.defaultProps = {
	loading: false,
	dense: false,
	sortModel: { order: "asc", orderBy: "" },
	rowsPerPage: 5,
	rowsPerPageOptions: [5, 10, 25],
	page: 0,
	disableSelectionOnClick: true,
	checkboxSelection: false,
	disableColumnSelector: false,
};

export default EnhancedTable;
