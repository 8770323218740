import React, {useState, useEffect} from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
	Button,
	Hidden,
	ListItem,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import useAuth from 'hooks/useAuth';

const useStyles = makeStyles((theme) => ({
	item: {
		display: 'flex',
		paddingTop: 0,
		paddingBottom: 0
	},
	button: {
		color: theme.palette.text.secondary,
		fontWeight: theme.typography.fontWeightMedium,
		justifyContent: 'flex-start',
		letterSpacing: 0,
		padding: '10px 8px',
		textTransform: 'none',
		width: '100%'
	},
	icon: {
		marginRight: theme.spacing(1)
	},
	title: {
		marginRight: 'auto'
	},
	active: {
		color: theme.palette.primary.main,
		'& $title': {
			fontWeight: theme.typography.fontWeightMedium
		},
		'& $icon': {
			color: theme.palette.primary.main
		}
	}
}));

function NavItem({
	className,
	href,
	icon: Icon,
	title,
	action,
	...rest
}) {
	const classes = useStyles();
	const { signOut } = useAuth();
	const [execute, setExecute] = useState(null);

	useEffect(() => {
		if(action === "signOut") {
			setExecute(() => signOut);
		}
	}, []);

	function handleVisibility() {
		if(action){
			return(
				<Hidden lgUp mdUp>
					<Button
						activeclassname={classes.active}
						className={classes.button}
						component={RouterLink}
						to={href}
						onClick={execute}
					>
						{Icon && (
							<Icon
								className={classes.icon}
								size="20"
							/>
						)}
						<span className={classes.title}>
							{title}
						</span>
					</Button>
				</Hidden>
			)
		} else {
			return(
				<Button
					activeclassname={classes.active}
					className={classes.button}
					component={RouterLink}
					to={href}
				>
					{Icon && (
						<Icon
							className={classes.icon}
							size="20"
						/>
					)}
					<span className={classes.title}>
						{title}
					</span>
				</Button>
			)
		}
	}

	return (
		<ListItem
			className={clsx(classes.item, className)}
			disableGutters
			{...rest}
		>
			{handleVisibility()}
		</ListItem>
	);
};

NavItem.propTypes = {
	className: PropTypes.string,
	href: PropTypes.string,
	icon: PropTypes.elementType,
	title: PropTypes.string
};

export default NavItem;
