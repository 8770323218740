import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import {
    Card, Modal, Box,
    CardContent, Typography,
    CircularProgress,
    IconButton, CardHeader,
    Divider
} from '@mui/material';

const useStyles = makeStyles(() => ({
    modalContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        boxShadow: 24,
    },
    boxAllData: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    marker: {
        height: '200px',
        width: '200px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '100px',
        background: props => props.color ? `${props.color}aa` : '#434348',
        border: props => props.color ? `10px solid ${props.color}` : `10px solid #000`,
        fontSize: 'xxx-large',
        color: '#fff',
        marginBottom: '20px',
        marginTop: '20px'
    },
}));
function FagerstromModal({ className, testData, awaitData, statusModal, setStatusModal, ...rest }) {
    const classes = useStyles();

    function getColor(value) {
        if (value <= 2) {
            return "#52D800";

        } else if (value > 2 && value <= 4) {
            return "#71DD57";

        } else if (value === 5) {
            return "#4A72FC";

        } else if (value > 5 && value <= 7) {
            return "#FABD21";

        } else {
            return "#ED5A5A";
        }
    }

    function getMessage(value) {
        switch (value) {
            case 0:
                return "SEM DEPENDÊNCIA";
            case 1:
                return "MUITO BAIXO";
            case 2:
                return "MUITO BAIXO";
            case 3:
                return "BAIXO";
            case 4:
                return "BAIXO";
            case 5:
                return "MÉDIO";
            case 6:
                return "ELEVADO";
            case 7:
                return "ELEVADO";
            case 8:
                return "MUITO ELEVADO";
            case 9:
                return "MUITO ELEVADO";
            default:
                return "MUITO ELEVADO";
        }
    }

    const CircleColor = ({ children, ...props }) => {
        const { marker } = useStyles(props);
        return (
            <div className={`${marker}`}>
                {children}
            </div>
        );
    }

    return (
        <Modal
            open={statusModal}
            onClose={() => setStatusModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            {...rest}
        >
            <Card className={classes.modalContainer}>
                <CardHeader
                    action={
                        <IconButton aria-label="closeModal" onClick={() => setStatusModal(false)}>
                            <CloseIcon />
                        </IconButton>
                    }
                />
                <Divider />
                <CardContent>
                    {!awaitData ? (
                        <>
                            {testData !== undefined ? (
                                <Box p={2}>
                                    <Box mb={4}>
                                        <Typography variant="h3" align="center" gutterBottom>
                                            RESULTADO
                                        </Typography>
                                        <Typography variant="h4" align="center" gutterBottom>
                                            TESTE DE FAGERSTRÖM
                                        </Typography>
                                    </Box>
                                    <Box className={classes.boxAllData}>
                                        <Typography variant="body1" align="center" gutterBottom>
                                            Seu grau de dependência é:
                                        </Typography>
                                        <CircleColor color={getColor(testData.result)}>
                                            {testData.result != undefined ? testData.result : '-'}
                                        </CircleColor>
                                        <Typography variant="h4" align="center" gutterBottom>
                                            <b>{getMessage(testData.result)}</b>
                                        </Typography>
                                    </Box>
                                </Box>
                            ) : (
                                <Box mt={3} display="flex" justifyContent="center">
                                    <Typography variant="body1" gutterBottom>
                                        Nenhum dado encontrado.
                                    </Typography>
                                </Box>
                            )}
                        </>
                    ) : (
                        <Box mt={3} display="flex" justifyContent="center">
                            <CircularProgress />
                        </Box>
                    )}
                </CardContent>
            </Card>
        </Modal>
    );
}

FagerstromModal.propTypes = {
    className: PropTypes.string,
    testData: PropTypes.object,
    awaitData: PropTypes.bool.isRequired,
    statusModal: PropTypes.bool.isRequired,
    setStatusModal: PropTypes.func.isRequired,
}


export default FagerstromModal;