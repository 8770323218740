import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  colors,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import Chart from 'components/Chart';

const useStyles = makeStyles(() => ({
  root: {}
}));

function UsersByResult({ className, amountUsers, awaitData, ...rest }) {
  const classes = useStyles();
  const theme = useTheme();

  function getAmountUsers(position) {
    if (awaitData || !amountUsers?.length) return;
    return amountUsers[position].amountResult;
  }

  const data = {
    datasets: [
      {
        categoryPercentage: 0.5,
        barThickness: 12,
        maxBarThickness: 10,
        barPercentage: 0.5,
        backgroundColor: colors.indigo[500],
        data: [getAmountUsers(0), getAmountUsers(1), getAmountUsers(2), getAmountUsers(3), getAmountUsers(4)],
        label: 'Usuários'
      }
    ],
    labels: ['Resultado - 0', 'Resultado - 4', 'Resultado - 5', 'Resultado - 7', 'Resultado - 10']
  };

  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary
          },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          }
        }
      ]
    },
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader
        title="USUÁRIOS POR RESULTADO DO TESTE DE FARGESTRÖM"
      />
      <Divider />
      <CardContent>
        <Chart
          height="400px"
          type="bar"
          data={data}
          options={options}
        />
      </CardContent>
      <Divider />
      <Box display="flex" justifyContent="flex-end" p={2}>
        <Button
          color="primary"
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="text"
          href={`/usuarios`}
        >
          Detalhes
        </Button>
      </Box>
    </Card>
  );
};

UsersByResult.propTypes = {
  className: PropTypes.string,
  awaitData: PropTypes.bool,
  amountUsers: PropTypes.array
};

export default UsersByResult;
