import React from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Box, Grid, Divider, Card, CardContent, CardHeader, Typography } from '@mui/material';

import { FormikTextField } from 'components/formElements';

const useStyles = makeStyles(() => ({
    statusGroup: {
		display: 'flex',
		justifyContent: 'start',
		alignItems: 'center',
	}
}));

function GroupForm({ className, formik, idGroup, awaitData, groupData, ...rest }) {
    const classes = useStyles();

    return (
        <Card {...rest} className={clsx(classes.root, className)}>
            <CardHeader
                title={idGroup === undefined ? "Cadastrar novo grupo" : "Editar grupo"}
                subheader={idGroup === undefined ?
                    "Informe todos os dados para cadastrar um grupo"
                    :
                    "Informe todos os dados para editar o grupo"
                }
            />
            <Divider />
            <form onSubmit={formik.handleSubmit}>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                            <FormikTextField fullWidth formik={formik} name="title" label="Título" />
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <FormikTextField
                                fullWidth
                                formik={formik}
                                name="description"
                                label="Descrição"
                                multiline
                                rows={3}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider />

                <Box display="flex" justifyContent={idGroup === undefined ? "flex-end" : "space-between"} p={2}>
					{idGroup &&
						<Grid container spacing={2}>
							<Grid item md={12} xs={12}>
								<Typography variant="body2" color="text.secondary">
									Criado em {groupData?.groupCreateAt ? moment(groupData?.groupCreateAt).format('DD/MM/YYYY') : "--/--/----"}
								</Typography>
								
								<div className={classes.statusGroup}>
									<Typography
										color="textSecondary"
										display="inline"
										variant="body2"
									>
										Status:
									</Typography>
									<FiberManualRecordIcon
										className={classes.statsIcon}
										sx={{ color: groupData?.groupIsActive ? 'green' : 'red' }}
									/>
									<Typography
										color="textSecondary"
										display="inline"
										variant="body2"
									>
										{groupData?.groupIsActive ? 'Ativo' : 'Inativo'}
									</Typography>
								</div>
							</Grid>
						</Grid>
					}

					<LoadingButton
						type="submit"
						color="primary"
						variant="contained"
						loading={awaitData}
					>
						{idGroup === undefined ? "Cadastrar" : "Editar"}
					</LoadingButton>
				</Box>
            </form>
        </Card>
    );
}

GroupForm.propTypes = {
    className: PropTypes.string,
    formik: PropTypes.object.isRequired,
    idGroup: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	awaitData: PropTypes.bool.isRequired,
	groupData: PropTypes.object,
}

export default GroupForm;