import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import SendIcon from '@mui/icons-material/Send';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { CircularProgress, Paper, InputBase, IconButton } from '@mui/material';

import { readFileAsDataURL, encode } from "modules/utils/encryption";

const useStyles = makeStyles(() => ({
    inputContainer: {
        p: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    inputStyle: {
        flex: 1,
        marginLeft: '10px'
    },
    buttonContainer: {
        p: '10px',
    },
    loadingSize: {
        width: '1em !important',
        height: '1em !important',
    }
}));

function InputComment({
    setCommentValue, awaitDataMessage, commentValue, allowImage,
    sendMessage, replyValue, ...rest
}) {
    const classes = useStyles();

    function messageRequest(message, typeMessage, replyValue) {

        let formatedMsg = message;

        if (typeMessage === "img") {
            formatedMsg = message.split("base64,")[1];
        } else {
            formatedMsg = encode(formatedMsg);
        }
        sendMessage(formatedMsg, typeMessage, replyValue);
        setCommentValue('');
    }

    function onChangeFile(file) {
        const base64Picture = readFileAsDataURL(file);
        base64Picture.then((res) => {
            messageRequest(res, 'img', replyValue);
        })
    }

    return (
        <Paper variant="outlined" className={classes.inputContainer} {...rest}>
            <InputBase
                className={classes.inputStyle}
                placeholder="Escreva um comentário..."
                disabled={awaitDataMessage}
                multiline
                maxRows={3}
                value={commentValue}
                onChange={(e) => setCommentValue(e.target.value)}
                inputProps={{ 'aria-label': 'write-message' }}
            />
            {allowImage && (
                <IconButton
                    className={classes.buttonContainer}
                    aria-label="add-img"
                    variant="contained"
                    component="label"
                >
                    <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => onChangeFile(e.target.files[0])}
                        hidden
                    />
                    <AddPhotoAlternateIcon />
                </IconButton>
            )}
            <IconButton
                color="primary"
                disabled={awaitDataMessage}
                className={classes.buttonContainer}
                onClick={() => messageRequest(commentValue, 'text', replyValue)}
                aria-label="send-message"
            >
                {!awaitDataMessage ? (
                    <SendIcon />
                ) : (
                    <CircularProgress
                        className={classes.loadingSize}
                        color="primary"
                    />
                )}
            </IconButton>
        </Paper>
    )
}

InputComment.propTypes = {
    awaitDataMessage: PropTypes.bool.isRequired,
    allowImage: PropTypes.bool,
    setCommentValue: PropTypes.func.isRequired,
    commentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    replyValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sendMessage: PropTypes.func.isRequired,
}

export default InputComment;