import React from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import DirectionsRounded from '@mui/icons-material/DirectionsRounded';
import AddCircleOutlineRounded from '@mui/icons-material/AddCircleOutlineRounded';
import {
    Grid, Divider, Card, CircularProgress, Box,
    Paper, CardActions, CardMedia,
    CardContent, CardHeader, IconButton, Typography, Button
} from '@mui/material';

import InputMessage from './InputMessage';
import { decode } from 'modules/utils/encryption';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%'
    },
    containerMessage: {
        padding: '10px',
        overflowY: 'scroll',
        height: '400px',
        maxHeight: '400px'
    },
    footerStyle: {
        display: 'flex',
        justifyContent: 'center',
    },
    messageAlignLeft: {
        display: 'flex',
        justifyContent: 'flex-start'
    },
    messageAlignRight: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    messageHeader: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignContent: 'center'
    },
    messageImgContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    imgStyle: {
        maxWidth: '100%',
        maxHeight: '200px',
        height: 'auto',
        objectFit: 'contain !important'
    },
    boxCard: {
        width: '70%',
        display: 'flex',
        flexDirection: 'column',
    },
    messageCardUser: {
        background: `${theme.palette.message.primary} !important`,
        width: '100%',
        padding: '10px'
    },
    messageCardOtherUser: {
        background: `${theme.palette.message.secondary} !important`,
        width: '100%',
        padding: '10px'
    },
    boxActionsCard: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    actionsCard: {
        display: 'flex',
        alignItems: 'center',
    },
    noMemberCard: {
        minHeight: "400px"
    },
    loadingSize: {
        width: '1em !important',
        height: '1em !important',
    },
    noItensBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
    },
    progress: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
    messageText: {
        whiteSpace: 'pre-line',
    }
}));

function ChatRender({
    className,
    messages,
    commentValue,
    setCommentValue,
    addGroupMember,
    pagedObject,
    idUser,
    addMemberLoading,
    scrollToEnd,
    awaitData,
    awaitDataMessage,
    awaitDataAddMessage,
    getPagedMessages,
    sendMessage,
    groupData,
    groupMember,
    deleteMessage,
    ...rest
}) {
    const classes = useStyles();

    function messageComponent(message, userMessage) {
        return (
            <Box className={classes.boxCard}>
                <Card className={userMessage ? classes.messageCardUser : classes.messageCardOtherUser}>
                    <Grid container spacing={1}>
                        <Grid item md={12} xs={12} className={classes.messageHeader}>
                            <Typography variant="body1" gutterBottom>
                                <b> {message.name} </b>
                            </Typography>
                            <IconButton aria-label="settings" onClick={() => deleteMessage(message)} >
                                <DeleteIcon />
                            </IconButton>
                        </Grid>

                        {message.messageType == "text" ? (
                            <Grid item md={12} xs={12}>
                                <Typography variant="body1" className={classes.messageText} gutterBottom>
                                    {decode(message.message)}
                                </Typography>
                            </Grid>
                        ) : (
                            <Grid item md={12} xs={12} className={classes.messageImgContainer}>
                                <CardMedia
                                    component="img"
                                    className={classes.imgStyle}
                                    src={`data:image/webp;base64,${message.message}`}
                                    alt="img"
                                />
                            </Grid>
                        )}
                    </Grid>
                </Card>
                
                <Box display="flex" justifyContent={userMessage && "flex-end"}>
                    <Typography variant="body1" gutterBottom>
                        {moment(message.messageCreateAt).format('DD/MM/YYYY - HH:mm')}
                    </Typography>
                </Box>
            </Box>
        )
    }

    return (
        <Card {...rest} className={clsx(classes.root, className)}>
            <CardHeader
                title={groupData?.groupTitle || 'Grupo'}
                subheader={`Descrição: ${groupData?.groupDescription}` || "Desafio"}
            />
            <Divider />
            <CardContent>
                <Paper variant="outlined" className={classes.containerMessage}>
                    {!awaitData ? (
                        <>
                            {groupMember ? (
                                messages.length > 0 ? (
                                    <>
                                        {(pagedObject.page < pagedObject.amountPage) &&
                                            <Box mb={1} display={'flex'} justifyContent={'center'}>
                                                <IconButton onClick={getPagedMessages}>
                                                    {awaitDataAddMessage ? (
                                                        <CircularProgress color="inherit" className={classes.loadingSize} />
                                                    ) : (
                                                        <AddCircleOutlineRounded />
                                                    )}
                                                </IconButton>
                                            </Box>
                                        }
                                        <Grid container spacing={3}>
                                            {messages.map((message, idx) => (
                                                <Grid
                                                    item
                                                    md={12}
                                                    xs={12}
                                                    key={idx}
                                                    className={message.idUser == idUser ? classes.messageAlignRight : classes.messageAlignLeft}
                                                >
                                                    {messageComponent(message, (message.idUser == idUser))}
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </>
                                ) : (
                                    <Box className={classes.noItensBox}>
                                        <Typography variant="body1">
                                            Nenhum item encontrado.
                                        </Typography>
                                    </Box>
                                )
                            ) : (
                                <Box display="flex" justifyContent="center" flexDirection={"column"} className={classes.noMemberCard}>
                                    <Box display="flex" justifyContent="center">
                                        <Typography variant="body1">
                                            <b> Para conseguir visualizar as mensagens entre no grupo. </b>
                                        </Typography>
                                    </Box>
                                    <Box display="flex" justifyContent="center" pt={2} >
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            endIcon={!addMemberLoading ?
                                                <DirectionsRounded />
                                                :
                                                <CircularProgress
                                                    className={classes.loadingSize}
                                                    color="info"
                                                />
                                            }
                                            onClick={() => addGroupMember()}
                                        >
                                            Entrar no grupo
                                        </Button>
                                    </Box>
                                </Box>
                            )}
                        </>
                    ) : (
                        <Box className={classes.progress}>
                            <CircularProgress />
                        </Box>
                    )}
                    <div style={{ float: "left", clear: "both" }} ref={scrollToEnd} />
                </Paper>
            </CardContent>
            <Divider />
            <CardActions disableSpacing className={classes.footerStyle}>
                <InputMessage
                    awaitDataMessage={awaitDataMessage}
                    setCommentValue={setCommentValue}
                    commentValue={commentValue}
                    groupMember={groupMember}
                    replayValue={null}
                    sendMessage={sendMessage}
                />
            </CardActions>
        </Card >
    )
}

ChatRender.propTypes = {
    className: PropTypes.string,
    messages: PropTypes.array.isRequired,
    awaitDataAddMessage: PropTypes.bool.isRequired,
    getPagedMessages: PropTypes.func.isRequired,
    pagedObject: PropTypes.object.isRequired,
    commentValue: PropTypes.string,
    setCommentValue: PropTypes.func,
    idUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    awaitData: PropTypes.bool.isRequired,
    awaitDataMessage: PropTypes.bool.isRequired,
    sendMessage: PropTypes.func.isRequired,
    groupData: PropTypes.object,
    deleteMessage: PropTypes.func.isRequired,
}

export default ChatRender;