import CryptoJS from "crypto-js";
import { Buffer } from 'buffer';

const keys = {
    AUTH: '1b671a64-40d5-491e-99b0-da01ff1f3341',
}


function encode(unencoded) {
    return new Buffer(unencoded).toString('base64');
}

function decode(encoded) {
    return new Buffer(encoded, 'base64').toString('utf8');
}

function encryption(text, salt) {
    if (!text || !salt) return "";
    try {
        // transforma o json em um array de bytes
        let dataToEncrypt = CryptoJS.enc.Utf8.parse(text);
        // cria uma key de 256 bits em SHA-3 usando o salt
        let key = CryptoJS.SHA3(salt, { outputLength: 256 });
        // cria uma iv em Hex usando o salt
        let iv = CryptoJS.enc.Hex.parse(salt);
        // encripta o dado em AES
        let encrypted = CryptoJS.AES.encrypt(dataToEncrypt, key, { iv: iv });
        // retorna o dado no formato de base64
        return encrypted.toString();
    } catch (e) {
        return "";
    }
}

function encryptByAES(data, salt) {
    try {
        // converte dado em json
        const json = JSON.stringify(data)
        // transforma o json em um array de bytes
        const dataToEncrypt = CryptoJS.enc.Utf8.parse(json);
        // cria uma key de 256 bits em SHA-3 usando o salt
        const key = CryptoJS.SHA3(salt, { outputLength: 256 });
        // cria uma iv em Hex usando o salt
        const iv = CryptoJS.enc.Hex.parse(salt);
        // encripta o dado em AES
        const encrypted = CryptoJS.AES.encrypt(dataToEncrypt, key, { iv });
        // retorna o dado no formato de base64
        return encrypted.toString();
    } catch (e) {
        return "";
    }
}

function encryptPassword(text, salt) {
    if (!text || !salt) return "";
    try {
        // transforma o json em um array de bytes
        let dataToEncrypt = CryptoJS.enc.Utf8.parse(text);
        // cria uma key de 256 bits em SHA-3 usando o salt
        let key = CryptoJS.SHA3(salt, { outputLength: 256 });
        // cria uma iv em Hex usando o salt
        let iv = CryptoJS.enc.Hex.parse(salt);
        // encripta o dado em AES
        let encrypted = CryptoJS.AES.encrypt(dataToEncrypt, key, { iv: iv });
        // retorna o dado no formato de base64
        return encrypted.toString();
    } catch (e) {
        return "";
    }
}

function decryptByAES(ciphertext, salt) {
    try {
        // passa o dado em base64 para um array de bytes
        const dataToDecrypt = CryptoJS.enc.Base64.parse(ciphertext);
        // cria uma key de 256 bits em SHA-3 usando o salt
        const key = CryptoJS.SHA3(salt, { outputLength: 256 });
        // cria uma iv em Hex usando o salt
        const iv = CryptoJS.enc.Hex.parse(salt);
        // descripta o dado em AES
        const decrypted = CryptoJS.AES.decrypt({ ciphertext: dataToDecrypt }, key, { iv: iv });
        // retorna o dado em formato json
        const json = CryptoJS.enc.Utf8.stringify(decrypted);
        // converte de json para o dado original
        return JSON.parse(json);
    } catch (e) {
        return null;
    }
}

function convertToBase64(data) {
    try {
        const json = JSON.stringify(data)
        const words = CryptoJS.enc.Utf8.parse(json);
        return CryptoJS.enc.Base64.stringify(words);
    } catch (e) {
        return "";
    }
}

function convertFromBase64(base64) {
    try {
        const words = CryptoJS.enc.Base64.parse(base64);
        const json = CryptoJS.enc.Utf8.stringify(words)
        return JSON.parse(json);
    } catch (e) {
        return null;
    }
}

function readFileAsText(file) {
    return new Promise((resolve, reject) => {
        try {
            const fileReader = new FileReader();
            fileReader.onload = (e) => resolve(e.target.result);
            fileReader.readAsText(file);
        } catch (error) {
            reject({ error });
        }
    });
}

function readFileAsDataURL(file) {
    if (!file) return "";
    return new Promise((resolve, reject) => {
        try {
            const fileReader = new FileReader();
            fileReader.onload = (e) => resolve(e.target.result);
            fileReader.readAsDataURL(file);
        } catch (error) {
            reject({ error });
        }
    });
}

async function readFilesAsDataURL(files = []) {
    return Array.from(files).reduce(async (filesAsDataUrl, file) => {
        const accumulator = await filesAsDataUrl;
        const fileAsDataUrl = await readFileAsDataURL(file);
        accumulator.push(fileAsDataUrl);
        return Promise.all(accumulator);
    }, Promise.all([]));
}

async function readFilesAsText(files = []) {
    return Array.from(files).reduce(async (filesAsText, file) => {
        const accumulator = await filesAsText;
        const fileAsText = await readFileAsText(file);
        accumulator.push(fileAsText);
        return Promise.all(accumulator);
    }, Promise.all([]));
}

export {
    keys,
    encryptByAES,
    encryptPassword,
    encode, 
    decode,
    encryption,
    decryptByAES,
    convertToBase64,
    convertFromBase64,
    readFileAsText,
    readFileAsDataURL,
    readFilesAsDataURL,
    readFilesAsText,
};
