import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

function RouteWithLayout({ layout: Layout, component: Component, ...rest }) {
	return (
		<Route
			{...rest}
			component={(props => (
				<Layout {...props} {...rest}>
					<Component {...props} {...rest} />
				</Layout>
			))}
		/>
	);
};

RouteWithLayout.propTypes = {
	component: PropTypes.any.isRequired,
	layout: PropTypes.any.isRequired,
	path: PropTypes.string,
};

export default RouteWithLayout;