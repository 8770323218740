import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import {
    Modal, Box,
    CardContent, Typography,
    CircularProgress, TextareaAutosize,
    CardHeader, IconButton, Divider, TextField, Paper
} from '@mui/material';

const useStyles = makeStyles(() => ({
    modalContainer: {
        position: 'absolute',
        overflowY: 'scroll',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 650,
        boxShadow: 24,
        maxHeight: "85%",
    },
    input: {
        height: 100
    }
}));

function HealthModal({ className, testData, awaitData, statusModal, setStatusModal, ...rest }) {
    const classes = useStyles();

    function formatDiseases() {
        if (!testData) return;

        let diseases = JSON.parse(testData?.diseases) || []; 

        return diseases?.map((item, index) => {
            return (
                <Typography variant="body1" gutterBottom pr={1} key={index}>
                    {item.value &&
                        <b>
                            {item.label}
                        </b>
                    }
                </Typography>
            )
        })
    }

    return (
        <Modal
            open={statusModal}
            onClose={() => setStatusModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            {...rest}
        >
            <Paper className={classes.modalContainer}>
                <CardHeader
                    action={
                        <IconButton aria-label="closeModal" onClick={() => setStatusModal(false)}>
                            <CloseIcon />
                        </IconButton>
                    }
                />
                <Divider />
                <CardContent>
                    {!awaitData ? (
                        <>
                            {testData !== undefined ? (
                                <Box p={2}>
                                    <Box mb={4}>
                                        <Typography variant="h3" align="center" gutterBottom>
                                            DADOS DE SAÚDE
                                        </Typography>
                                        <Typography variant="h4" align="center" gutterBottom>
                                            QUESTIONÁRIO PESSOAL
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Box pt={1}>
                                            <Typography variant="body1" gutterBottom>
                                                Possui algum tipo de doença?
                                            </Typography>
                                            <Box>
                                                {formatDiseases()}
                                            </Box>
                                        </Box>
                                        <Box pt={1}>
                                            <Typography variant="body1" gutterBottom>
                                                Possui diabetes?
                                            </Typography>
                                            <Typography variant="body1" gutterBottom>
                                                <b>{testData?.diabetes ? "Sim" : "Não"}</b>
                                            </Typography>
                                        </Box>
                                        <Box pt={1}>
                                            <Typography variant="body1" gutterBottom>
                                                Possui pressão alta?
                                            </Typography>
                                            <Typography variant="body1" gutterBottom>
                                                <b>{testData?.highBloodPressure ? "Sim" : "Não"}</b>
                                            </Typography>
                                        </Box>
                                        <Box pt={1}>
                                            <Typography variant="body1" gutterBottom>
                                                Utiliza medicamentos?
                                            </Typography>
                                            <Typography variant="body1" gutterBottom>
                                                <b>{testData?.useMedications ? "Sim" : "Não"}</b>
                                            </Typography>
                                        </Box>
                                        <Box pt={1}>
                                            <Typography variant="body1" gutterBottom>
                                                Etnia:
                                            </Typography>
                                            <Typography variant="body1" gutterBottom>
                                                <b>{testData?.ethnicity}</b>
                                            </Typography>
                                        </Box>
                                        <Box pt={1}>
                                            <Typography variant="body1" gutterBottom>
                                                Estado civil:
                                            </Typography>
                                            <Typography variant="body1" gutterBottom>
                                                <b>{testData?.civilStatus}</b>
                                            </Typography>
                                        </Box>
                                        <Box pt={1}>
                                            <Typography variant="body1" gutterBottom>
                                                Renda familiar:
                                            </Typography>
                                            <Typography variant="body1" gutterBottom>
                                                <b>{testData?.rent}</b>
                                            </Typography>
                                        </Box>
                                        <Box pt={1}>
                                            <Typography variant="body1" gutterBottom>
                                                Religião:
                                            </Typography>
                                            <Typography variant="body1" gutterBottom>
                                                <b>{testData?.religion}</b>
                                            </Typography>
                                        </Box>
                                        <Box pt={1}>
                                            <Typography variant="body1" gutterBottom>
                                                Mora com outras pessoas que fumam?
                                            </Typography>
                                            <Typography variant="body1" gutterBottom>
                                                <b>{testData?.livingWithSmokers ? "Sim" : "Não"}</b>
                                            </Typography>
                                        </Box>
                                        <Box pt={1}>
                                            <Typography variant="body1" gutterBottom>
                                                Fez tentativas anteriores de parar de fumar?
                                            </Typography>
                                            <Typography variant="body1" gutterBottom>
                                                <b>{testData?.tryQuitSmoking ? "Sim" : "Não"}</b>
                                            </Typography>
                                        </Box>
                                        <Box pt={2} alignItems="center" width="100%" alignSelf="flex-start" justifyContent="space-between">
                                            <Typography variant="body1" gutterBottom>
                                                Como foi a experiência de parar de fumar?
                                            </Typography>
                                            <Typography variant="body1" gutterBottom>
                                                <b>{testData?.quitSmokingExperience}</b>
                                            </Typography>
                                        </Box>
                                        <Box pt={2} alignItems="center" width="100%" alignSelf="flex-start" justifyContent="space-between">
                                            <Typography variant="body1" gutterBottom>
                                                Porque decidiu parar de fumar agora?
                                            </Typography>
                                            <Typography variant="body1" gutterBottom>
                                                <b>{testData?.motivationStop}</b>
                                            </Typography>
                                        </Box>
                                        <Box pt={2} alignItems="center" width="100%" alignSelf="flex-start" justifyContent="space-between">
                                            <Typography variant="body1" gutterBottom>
                                                O que te ajudaria a manter a sua decisão de parar de fumar?
                                            </Typography>
                                            <Typography variant="body1" gutterBottom>
                                                <b>{testData?.howKeepDecision}</b>
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            ) : (
                                <Box mt={3} display="flex" justifyContent="center">
                                    <Typography variant="body1" gutterBottom>
                                        Nenhum dado encontrado.
                                    </Typography>
                                </Box>
                            )}
                        </>
                    ) : (
                        <Box mt={3} display="flex" justifyContent="center">
                            <CircularProgress />
                        </Box>
                    )}
                </CardContent>
            </Paper>
        </Modal>
    )
}

HealthModal.propTypes = {
    className: PropTypes.string,
    testData: PropTypes.object,
    awaitData: PropTypes.bool.isRequired,
    statusModal: PropTypes.bool.isRequired,
    setStatusModal: PropTypes.func.isRequired,
}

export default HealthModal;