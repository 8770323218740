import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { makeStyles } from '@mui/styles';
import { Container, Grid } from '@mui/material';

import Page from 'components/Page';
import { LinkForm } from './components';
import useSnackbar from 'hooks/useSnackbar';
import RequestUtils from 'modules/api/RequestUtils';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const validationSchema = Yup.object({
    link: Yup.string().required('Campo obrigatório'),
});

function Link() {
    const classes = useStyles();
    const snackbar = useSnackbar();
    const [awaitData, setAwaitingData] = useState(false);
    const [linkData, setLinkData] = useState();

    const formik = useFormik({
        initialValues: {
            link: '',
        },
        validationSchema,
        onSubmit: (values) => {
            updateLink(values);
        }
    });

    function componentMount() {
        getLinkPremiumData();
    }
    useEffect(componentMount, [])

    function getLinkPremiumData() {
        setAwaitingData(true);
        RequestUtils.getLinkPremiumById(null).then((res) => {
            if (res?.svStatus && res.data) {
                const link = res.data;
                setLinkData(link);
                formik.setValues({
                    link: link.link,
                });
            }
            setAwaitingData(false);
        }).catch((e) => {
            snackbar("Erro ao buscar dados do link.").error();
            setAwaitingData(false);
        });
    }

    function updateLink(data) {
        if (!linkData.idLinkPremium) return;

        const editLink = {
            idLinkPremium: Number(linkData.idLinkPremium),
            link: data.link,
        }

        setAwaitingData(true);
        RequestUtils.updateLinkPremium(editLink).then((res) => {
            if (res?.svStatus && res.data) {
                const link = res.data;
                setLinkData(link);
                formik.setValues({
                    link: link.link,
                });
                snackbar("Link atualizado com sucesso.").success();
            } else {
                snackbar(res.msg || "Não foi possível atualizar link.").error();
            }
            setAwaitingData(false);
        }).catch((e) => {
            snackbar("Erro ao atualizar link.").error();
            setAwaitingData(false);
        });
    }

    return (
        <Page className={classes.root} title={"Editar link"}>
            <Container maxWidth="lg">
                <Grid container justifyContent="center" spacing={3}>
                    <Grid item lg={10} md={10} xs={12}>
                        <LinkForm
                            formik={formik}
                            awaitData={awaitData}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Page>
    )
}

export default Link;