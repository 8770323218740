import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useFormik } from 'formik';
import {
  Box, Grid,
  Button, Card,
  CardContent, CardHeader,
  Divider, Typography,
  FormGroup
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormikCheckbox } from 'components/formElements';

const useStyles = makeStyles(({
  root: {},
}));

function Notifications({ className, ...rest }) {
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      notificationEmail: false,
      notificationPushNot: false,
      notificationTextMessages: false,
      notificationPhoneCalls: false,
      messageEmail: false,
      messagePushNot: false,
      messagePhoneCalls: false,
    },
    onSubmit: (values) => {
      window.alert(JSON.stringify(values))
    }
  });

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader subheader="Manage the notifications" title="Notifications" />
      <Divider />
      <form onSubmit={formik.handleSubmit}>
        <CardContent>
          <Grid container spacing={6} wrap="wrap">
            <Grid
              item
              md={4}
              sm={6}
              xs={12}
            >
              <Typography color="textPrimary" variant="h6" gutterBottom>
                Notifications
              </Typography>
              <FormGroup>
                <FormikCheckbox formik={formik} name="notificationEmail" label="Email" />
                <FormikCheckbox formik={formik} name="notificationPushNot" label="Push Notifications" />
                <FormikCheckbox formik={formik} name="notificationTextMessages" label="Text Messages" />
                <FormikCheckbox formik={formik} name="notificationPhoneCalls" label="Phone calls" />
              </FormGroup>
            </Grid>
            <Grid
              item
              md={4}
              sm={6}
              xs={12}
            >
              <Typography color="textPrimary" variant="h6" gutterBottom>
                Messages
              </Typography>
              <FormGroup>
                <FormikCheckbox formik={formik} name="messageEmail" label="Email" />
                <FormikCheckbox formik={formik} name="messagePushNot" label="Push Notifications" />
                <FormikCheckbox formik={formik} name="messagePhoneCalls" label="Phone calls" />
              </FormGroup>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button type="submit" color="primary" variant="contained">
            Save
          </Button>
        </Box>
      </form>
    </Card>
  );
};

Notifications.propTypes = {
  className: PropTypes.string
};

export default Notifications;
