import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { makeStyles } from '@mui/styles';
import { Container, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';

import useArray from 'hooks/useArray';
import useSnackbar from 'hooks/useSnackbar';
import RequestUtils from 'modules/api/RequestUtils';

import Page from 'components/Page';
import { ForumForm } from './components';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const validationSchema = Yup.object({
    title: Yup.string()
        .required('Campo obrigatório')
        .max(100, "O título não pode ultrapassar 100 caracteres."),
    idChallenge: Yup.object()
        .required('Campo obrigatório')
        .nullable('Campo obrigatório'),
    allowImage: Yup.boolean()
});

function Forum() {
    const classes = useStyles();
    const snackbar = useSnackbar();
    const { idForum } = useParams();
    const arrayChallenges = useArray([]);
    const [awaitData, setAwaitingData] = useState(false);
    const [forumData, setForumData] = useState();

    const formik = useFormik({
        initialValues: {
            title: '',
            idChallenge: null,
            allowImage: 1
        },
        validationSchema,
        onSubmit: (values) => {
            editForum(values);
        }
    });

    function componentMount() {
        getChallengeList();

        if (!idForum) return;
        getForumData(idForum);
    }
    useEffect(componentMount, [])

    function getChallengeList() {
        setAwaitingData(true);
        RequestUtils.getAllChallenge(null).then((res) => {
            if (res?.svStatus && res.data) {
                arrayChallenges.setValue(res.data);
            } else {
                snackbar(res.msg || "Não foi possível listar os desafios.").error();
            }
            setAwaitingData(false);
        }).catch((e) => {
            snackbar("Erro ao listar desafios.").error();
            setAwaitingData(false);
        });
    }

    function getForumData(idForum) {
        setAwaitingData(true);
        RequestUtils.getForumById(idForum).then((res) => {
            if (res?.svStatus && res.data) {
                const forum = res.data;
                formik.setValues({
                    title: forum.forumTitle,
                    allowImage: forum.allowImage ? 1 : 0,
                    idChallenge: { value: forum.idChallenge, label: forum.idChallengeNavigation.challengeTitle }
                });
                setForumData(forum);
            } else {
                snackbar(res.msg || "Não foi possível buscar dados do fórum.").error();
            }
            setAwaitingData(false);
        }).catch((e) => {
            snackbar("Erro ao buscar dados do fórum.").error();
            setAwaitingData(false);
        });
    }

    function editForum(data) {
        if (!forumData) return;
        if (idForum == undefined) return;

        const dto = {
            idForum: Number(forumData.idForum),
            idUserCreated: Number(forumData.idUserCreated),
            forumCreateAt: forumData.forumCreateAt,
            forumTitle: data.title,
            allowImage: Number(data.allowImage),
            forumIsActive: forumData.forumIsActive,
            idChallenge: data.idChallenge.value
        }

        setAwaitingData(true);
        RequestUtils.updateForum(dto).then((res) => {
            if (res?.svStatus && res.data) {
                const forum = res.data;
                formik.setValues({
                    title: forum.forumTitle,
                    allowImage: Number(forum.allowImage),
                    idChallenge: { value: forum.idChallenge, label: data.idChallenge.label }
                });
                setForumData(forum);
                snackbar("Fórum atualizado com sucesso.").success();
            } else {
                snackbar(res.msg || "Não foi possível atualizar fórum.").error();
            }
            setAwaitingData(false);
        }).catch((e) => {
            snackbar("Erro ao atualizar fórum.").error();
            setAwaitingData(false);
        });
    }

    return (
        <Page className={classes.root} title={"Editar fórum"}>
            <Container maxWidth="lg">
                <Grid container justifyContent="center" spacing={3}>
                    <Grid item lg={10} md={10} xs={12}>
                        <ForumForm formik={formik} awaitData={awaitData} forumData={forumData} arrayChallenges={arrayChallenges.value} />
                    </Grid>
                </Grid>
            </Container>
        </Page>
    )
}

export default Forum;