import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import LinearProgress from '@mui/material/LinearProgress';
import {
    Card, Modal, Box,
    CardContent, Typography,
    CircularProgress,
    IconButton, CardHeader,
    Divider
} from '@mui/material';

const useStyles = makeStyles((theme) => ({
    modalContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        boxShadow: 24,
    },
    boxAllData: {
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: theme.palette.inverse.main,
        borderRadius: "4px"
    },

}));


function PercentageModal({ className, percentageData, awaitData, statusModal, setStatusModal, userName, ...rest }) {
    const classes = useStyles();

    return (
        <Modal
            open={statusModal}
            onClose={() => setStatusModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            {...rest}
        >
            <Card className={classes.modalContainer}>
                <CardHeader
                    action={
                        <IconButton aria-label="closeModal" onClick={() => setStatusModal(false)}>
                            <CloseIcon />
                        </IconButton>
                    }
                />
                <Divider />
                <CardContent>
                    {!awaitData ? (
                        <>
                            {percentageData !== undefined ? (
                                <Box p={2}>
                                    <Box mb={4}>
                                        <Typography variant="h3" align="center" gutterBottom>
                                            PORCENTAGEM
                                        </Typography>
                                        <Typography variant="h4" align="center" gutterBottom>
                                            Progresso nos desafios da maratona
                                        </Typography>
                                    </Box>
                                    <Box className={classes.boxAllData} p={4}>
                                        <Typography variant="h5" align="center" gutterBottom mb={3}>
                                            Atualmente, {userName} completou {percentageData.percentage}% dos desafios da maratona.
                                        </Typography>

                                        <LinearProgress sx={{height: 10, borderRadius: 5}} variant="determinate" value={percentageData.percentage}/>
                                    </Box>
                                </Box>
                            ) : (
                                <Box mt={3} display="flex" justifyContent="center">
                                    <Typography variant="body1" gutterBottom>
                                        Nenhum dado encontrado.
                                    </Typography>
                                </Box>
                            )}
                        </>
                    ) : (
                        <Box mt={3} display="flex" justifyContent="center">
                            <CircularProgress />
                        </Box>
                    )}
                </CardContent>
            </Card>
        </Modal>
    );
}

PercentageModal.propTypes = {
    className: PropTypes.string,
    percentageData: PropTypes.object,
    awaitData: PropTypes.bool.isRequired,
    statusModal: PropTypes.bool.isRequired,
    setStatusModal: PropTypes.func.isRequired,
    userName: PropTypes.string.isRequired,
}

export default PercentageModal;