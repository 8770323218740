
import { colors } from '@mui/material';

export const light = {
	primary: {
		main: colors.blue[500]
	},
	secondary: {
		main: colors.blueGrey[500]
	},
	text: {
		primary: colors.blueGrey[900],
		secondary: colors.blueGrey[600]
	},
	message: {
		primary: colors.green[100],
		secondary: colors.grey[100],
		reply: colors.pink[100],
		replySecondary: colors.blue[100]
	},
	inverse: {
		main: colors.blueGrey[100]
	}
};

export const dark = {
	mode: "dark",
	primary: {
		main: colors.blue[500]
	},
	secondary: {
		main: colors.blueGrey[500]
	},
	text: {
		primary: colors.blueGrey[100],
		secondary: colors.blueGrey[50]
	},
	message: {
		primary: colors.green[700],
		secondary: colors.grey[700],
		reply: colors.pink[800],
		replySecondary: colors.blue[700]
	},
	inverse: {
		main: colors.blueGrey[900]
	}
};
