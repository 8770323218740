import React from 'react';
import ReactDOM from 'react-dom';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { GlobalProvider } from './providers/global';
import { SnackbarProvider } from './providers/snackbar';
import { ThemeProvider } from './providers/theme';
import App from './App';
/* import reportWebVitals from './reportWebVitals'; */

ReactDOM.render(
	<GlobalProvider>
		<ThemeProvider>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<SnackbarProvider>
					<App />
				</SnackbarProvider>
			</LocalizationProvider>
		</ThemeProvider>
	</GlobalProvider>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
/* reportWebVitals(console.log); */
