import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

export default class SignalRModule {

    static connectToHub(url) {
        return new HubConnectionBuilder()
            .withUrl(url)
            .configureLogging(LogLevel.None)
            .build();
    }

    static invoke = (methodName, connection, ...body) => connection.invoke(methodName, ...body);
    static onClose = (callback, connection) => connection.onclose(callback);
    static send = (methodName, connection, ...body) => connection.send(methodName, ...body);
    static start = (connection) => connection.start();
    static stop = (connection) => connection.stop();
    static subscribe = (methodName, connection, callback) => connection.on(methodName, callback);
    static unsubscribe = (methodName, connection) => connection.off(methodName);
}
