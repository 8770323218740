import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
	Box, Grid,
	Button, Divider,
	Card, CardContent,
	CardHeader
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormikTextField, FormikSelect, FormikDatepicker, FormikAutocomplete } from 'components/formElements';
import { masks, regex } from 'modules/utils/mask';

const states = [
	{
		value: 'alabama',
		label: 'Alabama'
	},
	{
		value: 'new-york',
		label: 'New York'
	},
	{
		value: 'san-francisco',
		label: 'San Francisco'
	}
];

const useStyles = makeStyles(() => ({
	root: {}
}));

const validationSchema = Yup.object({
	firstName: Yup.string().required('Campo obrigatório'),
	lastName: Yup.string().required('Campo obrigatório'),
	email: Yup.string().email('Digite um email válido').required('Campo obrigatório'),
	phone: Yup.string()
		.required('Campo obrigatório')
		.min(masks.cellphone.length, "Telefone está incompleto")
		.matches(regex.cellphone, "Telefone não aceita letras ou caracteres especiais"),
	state: Yup.string().required('Campo obrigatório'),
	country: Yup.string().required('Campo obrigatório'),
});

function ProfileDetails({ className, ...rest }) {
	const classes = useStyles();
	const formik = useFormik({
		initialValues: {
			firstName: 'Katarina',
			lastName: 'Smith',
			email: 'demo@devias.io',
			phone: '',
			state: 'Alabama',
			country: 'USA',
			date: null
		},
		validationSchema,
		onSubmit: (values) => {
			window.alert(JSON.stringify(values))
		}
	});

	return (
		<Card {...rest} className={clsx(classes.root, className)}>
			<CardHeader subheader="The information can be edited" title="Profile" />
			<Divider />
			<form onSubmit={formik.handleSubmit}>
				<CardContent>
					<Grid container spacing={3}>
						<Grid item md={6} xs={12}>
							<FormikTextField fullWidth formik={formik} name="firstName" label="First name" />
						</Grid>
						<Grid item md={6} xs={12}>
							<FormikTextField fullWidth formik={formik} name="lastName" label="Last name" />
						</Grid>
						<Grid item md={6} xs={12}>
							<FormikTextField fullWidth formik={formik} name="email" label="Email Address" />
						</Grid>
						<Grid item md={6} xs={12}>
							<FormikTextField fullWidth formik={formik} mask={masks.cellphone} name="phone" label="Phone Number" />
						</Grid>
						<Grid item md={6} xs={12}>
							<FormikTextField fullWidth formik={formik} name="country" label="Country" />
						</Grid>
						<Grid item md={6} xs={12}>
							<FormikSelect fullWidth formik={formik} name="state" label="Select State" options={states} />
						</Grid>
						<Grid item md={6} xs={12}>
							<FormikDatepicker fullWidth formik={formik} name="date" label="Select Date" />
						</Grid>
						<Grid item md={6} xs={12}>
							<FormikAutocomplete 
								multiple								
								filterSelectedOptions
								fullWidth
								name="car" 
								formik={formik} 
								label="Select Cars"
								placeholder="Favorites"
								options={[{ value: 1, label: "a"}, { value: 2, label: "b"}]} 
								optionLabel="label"								
							/>
						</Grid>
					</Grid>
				</CardContent>
				<Divider />
				<Box display="flex" justifyContent="flex-end" p={2}>
					<Button type="submit" color="primary" variant="contained">
						Save details
					</Button>
				</Box>
			</form>
		</Card>
	);
};

ProfileDetails.propTypes = {
	className: PropTypes.string
};

export default ProfileDetails;
