import React from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import CheckIcon from '@mui/icons-material/Check';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {
    Grid, Divider, Card,
    CardContent, CardHeader, Typography,
    IconButton, Paper, Avatar,
    Box, CircularProgress
} from '@mui/material';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    paperContainer: {
        padding: 5,
    },
    breakText: {
        wordBreak: 'break-word'
    }
}));

function UserChallengesList({ className, getSteps, arrChallenges, awaitData, ...rest }) {
    const classes = useStyles();

    function getChallengeStep(challenge) {
        const start = `Início: ${challenge?.challengeStartupDate ? moment(challenge?.challengeStartupDate).format('DD/MM/YYYY') : '-'}`;

        const end = `Fim: ${challenge?.challengeCompletionDate ? moment(challenge?.challengeCompletionDate).format('DD/MM/YYYY') : '-'}`

        return `${start} | ${end}`
    }

    return (
        <Card {...rest} className={clsx(classes.root, className)}>
            <CardHeader
                title="Desafios"
                subheader="Todos os desafios do usuário atual"
            />
            <Divider />
            <CardContent>
                {!awaitData ? (
                    <>
                        {arrChallenges?.length > 0 ? (
                            <Grid container justifyContent="center" justifyItems="center" alignItems="center" spacing={1}>
                                {arrChallenges.map((challenge, idx) => (
                                    <Grid key={idx} item lg={12} md={12} sm={12} xs={12}>
                                        <Paper elevation={3} className={classes.paperContainer}>
                                            <CardHeader
                                                avatar={
                                                    <Avatar aria-label="recipe">
                                                        {challenge.challengeCompletionDate ? (
                                                            <CheckIcon />
                                                        ) : (
                                                            <HourglassEmptyIcon />
                                                        )}
                                                    </Avatar>
                                                }
                                                action={
                                                    <IconButton
                                                        onClick={() => {
                                                            getSteps(challenge?.challenge?.idChallenge);
                                                        }}
                                                    >
                                                        <RemoveRedEyeIcon />
                                                    </IconButton>
                                                }
                                                title={
                                                    <a className={classes.breakText}>
                                                        {challenge?.challenge?.challengeTitle}
                                                    </a>
                                                }
                                                subheader={getChallengeStep(challenge)}
                                            />
                                        </Paper>
                                    </Grid>
                                ))}
                            </Grid>
                        ) : (
                            <Box mt={3} display="flex" justifyContent="center">
                                <Typography variant="body1" gutterBottom>
                                    Nenhum item encontrado.
                                </Typography>
                            </Box>
                        )}
                    </>
                ) : (
                    <Box mt={3} display="flex" justifyContent="center">
                        <CircularProgress />
                    </Box>
                )}
            </CardContent>
        </Card>
    )
}

UserChallengesList.propTypes = {
    className: PropTypes.string,
    getSteps: PropTypes.func.isRequired,
    arrChallenges: PropTypes.array.isRequired,
    awaitData: PropTypes.bool.isRequired,
}

export default UserChallengesList;