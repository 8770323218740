import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Grid, Divider, Card,
    CardContent, CardHeader, Typography,
    Avatar, IconButton, Paper,
    Box, CircularProgress
} from '@mui/material';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    containerAvatar: {
        marginRight: 15
    },
    gridItem: {
        display: 'flex',
        alignItems: 'center',
    },
    buttonRemove: {
        display: 'flex',
        justifyContent: 'end'
    },
    paperContainer: {
        padding: 5,
    }
}));

function GroupMembersList({ className, arrMembers, removeMember, setStatusModal, awaitData, ...rest }) {
    const classes = useStyles();
    return (
        <Card {...rest} className={clsx(classes.root, className)}>
            <CardHeader
                action={
                    <IconButton aria-label="settings" onClick={() => setStatusModal(true)}>
                        <AddIcon />
                    </IconButton>
                }
                title="Membros"
                subheader="Todos os membros do grupo atual"
            />
            <Divider />
            <CardContent>
                {!awaitData ? (
                    <>
                        {arrMembers.length > 0 ? (
                            <Grid container justifyContent="center" justifyItems="center" alignItems="center" spacing={1}>
                            {arrMembers.map((member, idx) => (
                                <Grid key={idx} item md={12} xs={12}>
                                    <Paper elevation={3} className={classes.paperContainer}>
                                        <Grid container justifyContent="center" justifyItems="center" alignItems="center">
                                            <Grid item md={4} sm={4} xs={12} className={classes.gridItem}>
                                                <Avatar className={classes.containerAvatar}>
                                                    {member?.idUserInsertedNavigation?.name[0]}
                                                </Avatar>
                                                <Typography
                                                    color="textSecondary"
                                                    display="inline"
                                                    variant="body2"
                                                >
                                                    <b>{member?.idUserInsertedNavigation?.name}</b>
                                                </Typography>
                                            </Grid>
                                            <Grid item md={4} sm={4} xs={12} className={classes.gridItem}>
                                                <Typography
                                                    color="textSecondary"
                                                    display="inline"
                                                    variant="body2"
                                                >
                                                    <b>E-mail:</b> {member?.idUserInsertedNavigation?.email}
                                                </Typography>
                                            </Grid>
                                            <Grid item md={4} sm={4} xs={12} className={clsx(classes.gridItem, classes.buttonRemove)}>
                                                <IconButton aria-label="settings" onClick={() => removeMember(member.idGroupMember)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            ))}
                        </Grid>
                        ) : (
                            <Box mt={3} display="flex" justifyContent="center">
                                <Typography variant="body1" gutterBottom>
                                    Nenhum item encontrado.
                                </Typography>
                            </Box>
                        )}
                    </>
                ) : (
                    <Box mt={10}  mb={10} display="flex" justifyContent="center">
                        <CircularProgress />
                    </Box>
                )}
            </CardContent>
        </Card>
    )
}

GroupMembersList.propTypes = {
    className: PropTypes.string,
    arrMembers: PropTypes.array.isRequired,
    removeMember: PropTypes.func.isRequired,
    setStatusModal: PropTypes.func.isRequired,
    awaitData: PropTypes.bool.isRequired,
}

export default GroupMembersList;