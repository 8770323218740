import React from 'react';
import clsx from "clsx";
import PropTypes from "prop-types";
import {
	AppBar, Toolbar,
	IconButton,
	Box
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
	Brightness7 as Brightness7Icon,
	Brightness4 as Brightness4Icon,
	Input as InputIcon
} from '@mui/icons-material/';
import { useThemeState } from 'providers/theme/';
import useAuth from 'hooks/useAuth';

const useStyles = makeStyles({
	root: {},
	toolbar: {
		height: 64,
	},
});

function TopBar({ className, ...rest }) {
	const classes = useStyles();
	const { darkMode, toggleDarkMode } = useThemeState();
	const { signOut } = useAuth();
	return (
		<AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
			<Toolbar className={classes.toolbar}>
				<Box flexGrow={1} />
				<IconButton color="inherit" onClick={() => toggleDarkMode()}>
					{darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
				</IconButton>
				{/* <IconButton
					color="inherit"
					onClick={() => snackbar("Messagem muito importante mesmo").success()}
				>
					<Badge
						badgeContent={notifications.length}
						color="primary"
						variant="dot"
					>
						<NotificationsIcon />
					</Badge>
				</IconButton> */}
				<IconButton color="inherit" onClick={signOut}>
					<InputIcon />
				</IconButton>
			</Toolbar>
		</AppBar>
	);
}

TopBar.propTypes = {
	className: PropTypes.string,
};

export default TopBar;
