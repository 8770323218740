import React, { useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import CreateIcon from '@mui/icons-material/Create';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { NavLink as RouterLink } from 'react-router-dom';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ForumIcon from '@mui/icons-material/Forum';
import {
    Box, Card, CardContent,
    Divider, Grid, Typography,
    CardHeader, IconButton, Menu,
    MenuItem
} from '@mui/material';

import stringRedutor from 'modules/utils/stringRedutor';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    statsItem: {
        alignItems: 'center',
        display: 'flex'
    },
    statsIcon: {
        marginRight: theme.spacing(1)
    }
}));

function ForumCard({ className, forum, updateStatusForum, ...rest }) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    function handleClick(currentTarget) {
        setAnchorEl(currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    return (
        <Card className={clsx(classes.root, className)} {...rest}>
            <CardHeader
                title={stringRedutor(forum.forumTitle, 25)}
                action={
                    <Box>
                        <IconButton
                            aria-label="edit"
                            component={RouterLink}
                            to={`/foruns/mensagens/${forum.idForum}`}
                        >
                            <ForumIcon />
                        </IconButton>
                        <IconButton
                            aria-label="edit"
                            component={RouterLink}
                            to={`/foruns/editar/${forum.idForum}`}
                        >
                            <CreateIcon />
                        </IconButton>
                        <IconButton aria-label="settings" onClick={(e) => handleClick(e.currentTarget)} >
                            <MoreVertIcon />
                        </IconButton>

                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={(e) => {
                                updateStatusForum(forum.idForum)
                                handleClick(e.currentTarget)
                            }}>
                                {forum.forumIsActive ? "Desativar fórum" : "Ativar fórum"}
                            </MenuItem>
                        </Menu>
                    </Box>
                }
            />
            <Box flexGrow={1} />
            <Divider />
            <CardContent>
                <Typography
                    align="center"
                    color="textPrimary"
                    variant="body1"
                >
                    {stringRedutor(forum.forumTitle, 25)}
                </Typography>
            </CardContent>
            <Box flexGrow={1} />
            <Divider />
            <Box p={2}>
                <Grid
                    container
                    justifyContent="space-between"
                    spacing={2}
                >
                    <Grid
                        className={classes.statsItem}
                        item
                    >
                        <AccessTimeIcon
                            className={classes.statsIcon}
                            color="action"
                        />
                        <Typography
                            color="textSecondary"
                            display="inline"
                            variant="body2"
                        >
                            {forum.forumCreateAt ? moment(forum.forumCreateAt).format('DD/MM/YYYY') : "--/--/----"}
                        </Typography>
                    </Grid>
                    <Grid
                        className={classes.statsItem}
                        item
                    >
                        <FiberManualRecordIcon
                            className={classes.statsIcon}
                            sx={{ color: forum.forumIsActive ? 'green' : 'red' }}
                        />
                        <Typography
                            color="textSecondary"
                            display="inline"
                            variant="body2"
                        >
                            {forum.forumIsActive ? 'Ativo' : 'Inativo'}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Card>
    );
}

ForumCard.propTypes = {
    className: PropTypes.string,
    updateStatusForum: PropTypes.func.isRequired,
    forum: PropTypes.object.isRequired,
}

export default ForumCard;