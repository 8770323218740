import React, { useState, useEffect, useMemo } from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import { useFormik } from 'formik';
import { makeStyles } from '@mui/styles';
import { Container, Grid } from '@mui/material';
import { useParams, useHistory } from 'react-router-dom';

import Page from 'components/Page';
import useArray from 'hooks/useArray';
import useSnackbar from 'hooks/useSnackbar';
import useLocalStorage from 'hooks/useLocalStorage';
import RequestUtils from 'modules/api/RequestUtils';
import { ChallengeForm, StepsList, AddStepModal, ChallengeMemberList, AddChallengeMemberModal } from './components';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const validationAddChallenge = Yup.object({
    title: Yup.string()
        .required('Campo obrigatório')
        .max(100, "O título não pode ultrapassar 100 caracteres."),
    description: Yup.string()
        .required('Campo obrigatório')
        .max(200, "A descrição não pode ultrapassar 200 caracteres."),
    idNextChallenge: Yup.object()
        .nullable(true),
    marathon: Yup.object()
        .required('Campo obrigatório')
        .nullable('Campo obrigatório'),
    videoUrl: Yup.string()
        .required('Campo obrigatório')
        .max(100, "A url não pode ultrapassar 250 caracteres."),
    forumTitle: Yup.string()
        .required('Campo obrigatório')
        .max(200, "O nome do fórum não pode ultrapassar 200 caracteres."),
    allowImage: Yup.boolean()
});

const validationEditChallenge = Yup.object({
    title: Yup.string()
        .required('Campo obrigatório')
        .max(100, "O título não pode ultrapassar 100 caracteres."),
    description: Yup.string()
        .required('Campo obrigatório')
        .max(200, "A descrição não pode ultrapassar 200 caracteres."),
    idNextChallenge: Yup.object()
        .nullable(true),
    marathon: Yup.object()
        .nullable(true),
    isInitial: Yup.bool()
        .required('Campo obrigatório'),
    marathon: Yup.object()
        .nullable(true),
    videoUrl: Yup.string()
        .required('Campo obrigatório')
        .max(100, "A url não pode ultrapassar 250 caracteres."),
});

const validationStep = Yup.object({
    title: Yup.string()
        .required('Campo obrigatório')
        .max(100, "O título não pode ultrapassar 100 caracteres."),
    description: Yup.string()
        .required('Campo obrigatório')
        .max(200, "A descrição não pode ultrapassar 200 caracteres."),
    position: Yup.number()
        .required('Campo obrigatório')
        .min(1, "Informe um valor maior que 0.")
});

function Challenge() {
    const classes = useStyles();
    const snackbar = useSnackbar();
    const history = useHistory();
    const [currentUser] = useLocalStorage('currentUser');
    const { idChallenge } = useParams();
    const arrSteps = useArray([]);
    const [stepModal, setStepModal] = useState(false);
    const [membersModal, setMembersModal] = useState(false);
    const [awaitData, setAwaitingData] = useState(false);
    const [awaitDataMembers, setAwaitingDataMembers] = useState(false);
    const arrMembers = useArray([]);
    const arrUsers = useArray([]);
    const [inputValue, setInputValue] = useState('');
    const [allLoaded, setAllLoaded] = useState(0);
    const [changeMarathonListener, setChangeMarathonListener] = useState(0);
    const [awaitDataSteps, setAwaitingDataSteps] = useState(false);
    const [challengeData, setChallengeData] = useState();
    const [maratonNotSelected, setMaratonNotSelected] = useState(true);
    const arrayMarathons = useArray([]);
    const arrayChallenges = useArray([]);

    const [pagedObject, setPagedObject] = useState({
        searchTerm: '',
        page: 1,
        itensPerPage: 6,
        userType: 0,
        userPlan: 2,
        amountPage: 1
    });

    const formikChallenge = useFormik({
        initialValues: {
            title: '',
            isInitial: false,
            description: '',
            marathon: null,
            videoUrl: '',
            idNextChallenge: null,
            forumTitle: '',
            allowImage: 1
        },
        validationSchema: idChallenge ? validationEditChallenge : validationAddChallenge,
        onSubmit: (values) => {
            handleSubmit(values);
        }
    });

    const formikStep = useFormik({
        initialValues: {
            title: '',
            description: '',
            position: 1
        },
        validationSchema: validationStep,
        onSubmit: (values) => {
            addChallengeStep(values);
        }
    });

    const stateMarathons = useMemo(() => {
        const arr = arrayMarathons.value.map((marathon) => ({
            value: marathon.idChallengeMarathon,
            label: marathon.title
        }));

        return arr;
    }, [arrayMarathons])

    const stateChallenges = useMemo(() => {
        const arr = arrayChallenges.value.map((challenge) => ({
            value: challenge.idChallenge,
            label: challenge.challengeTitle
        }));

        return arr;
    }, [arrayChallenges])

    useEffect(() => {

        if (!formikChallenge.values.marathon) {
            formikChallenge.setFieldValue('idNextChallenge', { value: null, label: '' });
            setMaratonNotSelected(true);
            return;
        }

        if (formikChallenge.values.marathon.label !== '') {
            handleMarathonChange(formikChallenge.values.marathon.value)
        }

    }, [formikChallenge.values.marathon])

    useEffect(() => {
        if (!allLoaded) return;
        if (idChallenge) {
            let marathonLabel = stateMarathons.find((marathon) => marathon.value === formikChallenge.values.marathon.value);
            if (marathonLabel) {
                formikChallenge.setFieldValue('marathon', { value: formikChallenge.values.marathon.value, label: marathonLabel?.label });
                setMaratonNotSelected(false);
            } else {
                setMaratonNotSelected(true);
            }
        }
    }, [allLoaded])

    useEffect(() => {
        if (!changeMarathonListener) return;
        if (idChallenge) {
            let challengeLabel = stateChallenges.find((challenge) => challenge.value === formikChallenge.values.idNextChallenge.value);

            if (challengeLabel) {
                formikChallenge.setFieldValue('idNextChallenge', { value: formikChallenge.values.idNextChallenge.value, label: challengeLabel.label });
            }
        }

    }, [changeMarathonListener])

    useEffect(componentMount, [])

    function componentMount() {
        if (idChallenge) {
            getChanllengeData(idChallenge);
            getChanllengeSteps(idChallenge);
            getChallengeMembers(idChallenge);
            handlePagedUsers(pagedObject);
        } else {
            getAllMarathon();
        }
    }

    function getChanllengeData(idChallenge) {
        setAwaitingData(true);
        RequestUtils.getChallengeById(idChallenge).then((res) => {
            if (res?.svStatus && res.data) {
                const challenge = res.data;

                setChallengeData(challenge);

                formikChallenge.setValues({
                    title: challenge?.challengeTitle || '',
                    description: challenge?.challengeDescription || '',
                    marathon: { value: challenge?.idChallengeMarathon, label: '' },
                    idNextChallenge: { value: challenge?.idNextChallenge, label: '' },
                    videoUrl: challenge?.urlVideo || '',
                    isInitial: challenge.isInitial,
                    allowImage: challenge.allowImage ? 1 : 0,
                    forumTitle: challenge.forumTitle
                });

                getAllMarathon();
            } else {
                snackbar(res.msg || "Não foi possível buscar dados do desafio.").error();
            }
            setAwaitingData(false);
        }).catch((e) => {
            snackbar("Erro ao buscar dados do desafio.").error();
            setAwaitingData(false);
        });
    }

    function handleMarathonChange(idMarathon) {
        RequestUtils.getByIdChallengeMarathon(idMarathon).then((res) => {
            if (res?.svStatus && res.data) {
                if (idChallenge) {
                    const arr = res.data.filter((item) => item.idChallenge != idChallenge);
                    arrayChallenges.setValue(arr);
                } else {
                    arrayChallenges.setValue(res.data);
                }
                setMaratonNotSelected(false);
                setChangeMarathonListener(changeMarathonListener + 1);
            } else {
                snackbar(res.msg || "Não existem desafios cadastrados nessa maratona.").error();
            }
        }).catch((error) => {
            snackbar("Erro ao receber os desafios cadastrados.").error();
        })
    }

    function getAllMarathon() {
        RequestUtils.getAllMarathon().then((res) => {
            if (res?.svStatus && res.data) {
                arrayMarathons.setValue(res.data);

                setAllLoaded(allLoaded + 1);
            } else {
                snackbar(res.msg || "Não há maratonas cadastradas.").error();
            }
        }).catch((error) => {
            snackbar("Erro ao receber as maratonas cadastradas.").error();
        })
    }

    function getChanllengeSteps(idChallenge) {
        setAwaitingDataSteps(true);
        RequestUtils.getChallengeStepsByIdChallenge(idChallenge).then((res) => {
            if (res?.svStatus && res.data) {
                const challengeSteps = res.data;
                arrSteps.setValue(challengeSteps);
            } else {
                snackbar(res.msg || "Não foi possível listas as etapas do desafio.").error();
            }
            setAwaitingDataSteps(false);
        }).catch((e) => {
            snackbar("Erro ao listas as etapas do desafio.").error();
            setAwaitingDataSteps(false);
        });
    }

    function createChallenge(data) {

        let dto = {
            challengeTitle: data.title,
            idUserCreated: Number(currentUser?.idUser),
            challengeDescription: data.description,
            urlVideo: data.videoUrl,
            idChallengeMarathon: data.marathon.value,
            isInitial: data.isInitial,
            forumTitle: data.forumTitle,
            allowImage: Number(data.allowImage)
        }

        if (data.idNextChallenge?.value) {
            dto.idNextChallenge = data.idNextChallenge.value;
        }

        setAwaitingData(true);
        RequestUtils.addChallenge(dto).then((res) => {
            if (res?.svStatus && res.data) {
                setAwaitingData(false);
                snackbar("Desafio cadastrado com sucesso.").success();
                history.push(`/passos-desafio/editar/${res.data.idChallenge}`);
            } else {
                snackbar(res.msg || "Não foi possível cadastrar desafio.").error();
            }
            setAwaitingData(false);
        }).catch((e) => {
            snackbar("Erro ao cadastrar desafio.").error();
            setAwaitingData(false);
        });
    }

    function editChallenge(data) {
        const dto = {
            idChallenge: Number(challengeData.idChallenge),
            challengeTitle: data.title,
            challengeDescription: data.description,
            urlVideo: data.videoUrl,
            isInitial: data.isInitial,
            idChallengeMarathon: data.marathon ? data.marathon.value : null,
            idNextChallenge: data.idNextChallenge ? data.idNextChallenge.value : null
        }
        setAwaitingData(true);
        RequestUtils.updateChallenge(dto).then((res) => {
            if (res?.svStatus && res.data) {
                const challenge = res.data;
                setChallengeData(challenge);

                formikChallenge.setValues({
                    title: challenge?.challengeTitle || '',
                    description: challenge?.challengeDescription || '',
                    marathon: challenge?.idChallengeMarathon ? { value: challenge?.idChallengeMarathon, label: formikChallenge.values.marathon.label } :
                        { value: null, label: '' },
                    idNextChallenge: challenge?.idNextChallenge && challenge?.idChallengeMarathon ? { value: challenge?.idNextChallenge, label: formikChallenge.values.idNextChallenge.label } :
                        { value: null, label: '' }
                    ,
                    videoUrl: challenge?.urlVideo || '',
                    isInitial: challenge.isInitial ? true : false
                });
                snackbar("Desafio atualizado com sucesso.").success();
            } else {
                snackbar(res.msg || "Não foi possível atualizar desafio.").error();
            }
            setAwaitingData(false);
        }).catch((e) => {
            snackbar("Erro ao atualizar desafio.").error();
            setAwaitingData(false);
        });
    }

    function handleSubmit(data) {
        if (idChallenge === undefined) {
            createChallenge(data);
        } else {
            editChallenge(data);
        }
    }

    function getChallengeMembers(idChallenge) {
        setAwaitingDataMembers(true);
        RequestUtils.getUsersByIdChallenge(idChallenge).then((res) => {
            if (res?.svStatus && res.data) {
                const challengeMembers = res.data;
                let filteredList = challengeMembers.filter((member) => member.challengeUserEnd == null);
                arrMembers.setValue(filteredList);
            } else {
                snackbar(res.msg || "Não foi possível listar os membros do desafio.").error();
            }
            setAwaitingDataMembers(false);
        }).catch((e) => {
            snackbar("Erro ao listar os membros do desafio.").error();
            setAwaitingDataMembers(false);
        });
    }

    function handleChange(label, value) {
        let obj = pagedObject;

        switch (label) {
            case "searchTerm":
                setInputValue(value);
                if (value === "") obj = { ...obj, page: 1, searchTerm: value };
                break;
            case "userType":
                obj = { ...obj, page: 1, userType: value };
                break;
            case "userPlan":
                obj = { ...obj, page: 1, userPlan: value };
                break;
            default:
                break;
        }

        setPagedObject(obj);
        if (label !== "searchTerm" || value === "") handlePagedUsers(obj);
    }

    function onPressButton() {
        const obj = { ...pagedObject, page: 1, searchTerm: inputValue };
        setPagedObject(obj);
        handlePagedUsers(obj);
    }

    function handleChangePage(event, value) {
        const obj = { ...pagedObject, page: value };
        setPagedObject(obj);
        handlePagedUsers(obj);
    };

    async function handlePagedUsers(pagedObject) {
        setAwaitingData(true);

        const plan = pagedObject.userPlan == 2 ? null : !!pagedObject.userPlan;

        RequestUtils.getUsersPaged(
            pagedObject.searchTerm,
            pagedObject.page,
            pagedObject.itensPerPage,
            pagedObject.userType,
            true,
            plan
        ).then((res) => {
            if (res?.svStatus && res.data) {
                const data = res.data;
                setPagedObject({ ...pagedObject, amountPage: data.pageCount });
                arrUsers.setValue(data.listUser);
            } else {
                snackbar(res.msg || "Não foi possível listar usuários.").error();
            }
            setAwaitingData(false);
        }).catch((e) => {
            snackbar("Erro ao listar usuários.").error();
            setAwaitingData(false);
        });
    }

    function addMember(idUser) {
        if (!currentUser.idUser) return;

        const dto = {
            idUser: idUser,
            idChallenge: Number(idChallenge),
        }
        let userExists = arrMembers.value.find(user => user.idUser === idUser);
        if (userExists) {
            snackbar("Este usuário já está vinculado a este desafio!").error()
        } else {
            setAwaitingDataMembers(true);
            RequestUtils.addChallengeUser(dto).then((res) => {
                if (res?.svStatus && res.data) {
                    let newChallengeUser = res.data;
                    let user = arrUsers.value.find(user => user.idUser === newChallengeUser.idUser);

                    user.idChallengeUser = newChallengeUser.idChallengeUser;
                    arrMembers.push(user);

                    setMembersModal(false);
                    snackbar("Usuário vinculado com sucesso.").success();
                } else {
                    snackbar(res.msg || "Não foi possível vincular usuário ao grupo.").error();
                }
                setAwaitingDataMembers(false);
            }).catch((e) => {
                snackbar("Erro ao vincular usuário ao desafio.").error();
                setAwaitingDataMembers(false);
            });
        }
    }

    function addChallengeStep(data) {
        if (!idChallenge) return;
        if (!currentUser.idUser) return;

        const stepObj = arrSteps.value.find((step) => step.challengeStepSort == data.position);
        if (stepObj) {
            snackbar("Já existe uma etapa com essa posição!").warning();
            return;
        }

        const dto = {
            idChallenge: Number(idChallenge),
            idUserCreated: currentUser.idUser,
            challengeStepCreateAt: moment().format(),
            challengeStepTitle: data.title,
            challengeStepDescription: data.description,
            challengeStepIsActive: true,
            challengeStepSort: data.position,
            challengeStepTimeAfterLast: 0,
        }

        setAwaitingDataSteps(true);
        RequestUtils.addChallengeStep(dto).then((res) => {
            if (res?.svStatus && res.data) {
                const step = res.data;
                arrSteps.push(step);
                formikStep.resetForm();
                setStepModal(false);
                snackbar("Etapa cadastrada com sucesso.").success();
            } else {
                snackbar(res.msg || "Não foi possível cadastrada a etapa.").error();
            }
            setAwaitingDataSteps(false);
        }).catch((e) => {
            snackbar("Erro ao cadastrada a etapa.").error();
            setAwaitingDataSteps(false);
        });
    }

    function removeChallengeStep(idChallengeStep) {
        setAwaitingDataSteps(true);
        RequestUtils.challengeStepFisicalDelete(idChallengeStep).then((res) => {
            if (res?.svStatus && res.data) {
                const arr = arrSteps.value.filter((step) => step.idChallengeStep != idChallengeStep);
                arrSteps.setValue(arr);
                snackbar("Etapa removida com sucesso.").success();
            } else {
                snackbar(res.msg || "Não foi possível remover etapa do desafio.").error();
            }
            setAwaitingDataSteps(false);
        }).catch((e) => {
            snackbar("Erro ao remover etapa do desafio.").error();
            setAwaitingDataSteps(false);
        });
    }

    function removeMember(idChallengeUser) {
        setAwaitingDataMembers(true);
        RequestUtils.deleteChallengeUser(idChallengeUser).then((res) => {
            if (res?.svStatus && res.data) {
                const arr = arrMembers.value.filter((members) => members.idChallengeUser != idChallengeUser);
                arrMembers.setValue(arr);
                snackbar("Usuário removido com sucesso.").success();
            } else {
                snackbar(res.msg || "Não foi possível remover usuário do desafio.").error();
            }
            setAwaitingDataMembers(false);
        }).catch((e) => {
            snackbar("Erro ao remover usuário do desafio.").error();
            setAwaitingDataMembers(false);
        });
    }

    return (
        <Page className={classes.root} title={idChallenge === undefined ? "Cadastrar desafio" : "Editar desafio"}>
            <Container maxWidth="lg">
                <Grid container justifyContent="center" spacing={3}>
                    <Grid item lg={10} md={10} xs={12}>
                        <ChallengeForm
                            formik={formikChallenge}
                            idChallenge={idChallenge}
                            awaitData={awaitData}
                            challengeData={challengeData}
                            stateMarathons={stateMarathons}
                            stateChallenges={stateChallenges}
                            maratonNotSelected={maratonNotSelected}
                        />
                    </Grid>
                    {idChallenge &&
                        <>
                            <Grid item lg={10} md={10} xs={12}>
                                <StepsList
                                    awaitData={awaitDataSteps}
                                    arrSteps={arrSteps.value}
                                    removeStep={removeChallengeStep}
                                    setStatusModal={setStepModal}
                                />
                            </Grid>
                            <Grid item lg={10} md={10} xs={12}>
                                <ChallengeMemberList
                                    arrMembers={arrMembers.value}
                                    removeMember={removeMember}
                                    setMembersModal={setMembersModal}
                                    awaitData={awaitDataMembers}
                                />
                            </Grid>
                            <Grid item lg={10} md={10} xs={12}>
                                <AddStepModal
                                    setStatusModal={setStepModal}
                                    statusModal={stepModal}
                                    awaitData={awaitDataSteps}
                                    formik={formikStep}
                                />
                            </Grid>
                            <Grid item lg={10} md={10} xs={12}>
                                <AddChallengeMemberModal
                                    addMember={addMember}
                                    statusModal={membersModal}
                                    setStatusModal={setMembersModal}
                                    handleChange={handleChange}
                                    pagedObject={pagedObject}
                                    inputValue={inputValue}
                                    onPressButton={onPressButton}
                                    handleChangePage={handleChangePage}
                                    arrUsers={arrUsers.value}
                                />
                            </Grid>
                        </>
                    }
                </Grid>
            </Container>
        </Page>
    );
}

export default Challenge;