import { makeStyles } from "@mui/styles";

const useTablePaginationStyles = makeStyles((theme) => ({
	root: {
		flexShrink: 0,
		marginLeft: theme.spacing(2.5),
	},
}));

const useEnhancedTableStyles = makeStyles(theme => ({
	paper: {
		position: "relative"
	},
	backdropTable: {
		zIndex: theme.zIndex.tooltip + 1,
		position: "absolute"
	},
	tableContainer: {
		width: "100%",
		overflowX: "auto"
	},
	table: {
		width: "100%",
		tableLayout: "fixed"
	},
}));

export {
	useTablePaginationStyles,
	useEnhancedTableStyles
};