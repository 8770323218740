import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    useTheme,
    colors,
    CircularProgress,
    Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import Chart from 'components/Chart';

const useStyles = makeStyles(() => ({
    root: {}
}));

function MessagesSentLast24h({ className, amountItens, awaitData, ...rest }) {
    const classes = useStyles();
    const theme = useTheme();

    const [messagesNames, setMessagesNames] = useState([]);
    const [noValues, setNoValues] = useState(false);
    const [messagesValues, setMessagesValues] = useState([]);

    const data = {
        datasets: [
            {
                categoryPercentage: 0.5,
                barThickness: 12,
                maxBarThickness: 10,
                barPercentage: 0.5,
                backgroundColor: colors.indigo[500],
                data: messagesValues,
                label: 'Nas ultimas 24 horas'
            },
        ],
        labels: messagesNames
    };

    const options = {
        animation: false,
        cornerRadius: 20,
        layout: { padding: 0 },
        legend: { display: false },
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            xAxes: [
                {
                    ticks: {
                        fontColor: theme.palette.text.secondary
                    },
                    gridLines: {
                        display: false,
                        drawBorder: false
                    }
                }
            ],
            yAxes: [
                {
                    ticks: {
                        fontColor: theme.palette.text.secondary,
                        beginAtZero: true,
                        min: 0
                    },
                    gridLines: {
                        borderDash: [2],
                        borderDashOffset: [2],
                        color: theme.palette.divider,
                        drawBorder: false,
                        zeroLineBorderDash: [2],
                        zeroLineBorderDashOffset: [2],
                        zeroLineColor: theme.palette.divider
                    }
                }
            ]
        },
        tooltips: {
            backgroundColor: theme.palette.background.default,
            bodyFontColor: theme.palette.text.secondary,
            borderColor: theme.palette.divider,
            borderWidth: 1,
            enabled: true,
            footerFontColor: theme.palette.text.secondary,
            intersect: false,
            mode: 'index',
            titleFontColor: theme.palette.text.primary
        }
    };

    useEffect(() => {
        if(!amountItens) return;
        let names = [];
        let values = [];

        amountItens.forEach((amount) => {
            names.push(amount.forumTitle);
            values.push(amount.amountMessage);
        })

        setMessagesNames(names);

        const sum = values.reduce((partialSum, a) => partialSum + a, 0);

        if(sum === 0){
            setNoValues(true);
        }
        
        setMessagesValues(values);

    }, [amountItens])

    return (
        <Card className={clsx(classes.root, className)} {...rest}>
            <CardHeader
                title="MENSAGENS ENVIADAS NAS ÚLTIMAS 24 HORAS"
            />
            <Divider />
            <CardContent>
                {!awaitData ? (
                    !noValues ? 
                        <Chart
                            height="400px"
                            type="bar"
                            data={data}
                            options={options}
                        />
                    :
                        <Box mt={3} mb={3} display="flex" justifyContent="center" alignItems='center' style={{height: 300}}>
                            <Typography variant="h6">
                                Não há mensagens
                            </Typography>
                        </Box>
                    
                ) : (
                    <Box mt={3} display="flex" justifyContent="center">
                        <CircularProgress />
                    </Box>
                )}
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
            </Box>
        </Card>
    );
};

MessagesSentLast24h.propTypes = {
    className: PropTypes.string,
    amountItens: PropTypes.array,
    awaitData: PropTypes.bool
};

export default MessagesSentLast24h;