import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Pagination } from '@mui/lab';
import {
    Box, Container, Grid,
    Typography, CircularProgress
} from '@mui/material';

import Page from 'components/Page';
import useArray from 'hooks/useArray';
import { Toolbar, NotificationCard } from './components';
import useSnackbar from 'hooks/useSnackbar';
import RequestUtils from 'modules/api/RequestUtils';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
}));

function NotificationsList() {
    const classes = useStyles();
    const snackbar = useSnackbar();
    const [awaitData, setAwaitingData] = useState(false);
    const arrNotifications = useArray([]);
    const [inputValue, setInputValue] = useState('');
    const [pagedObject, setPagedObject] = useState({
        searchTerm: '',
        page: 1,
        itensPerPage: 12,
        amountPage: 1
    });

    function componentMount() {
        handlePagedNotifications(pagedObject);
    }
    useEffect(componentMount, []);

    async function handlePagedNotifications(pagedObject) {
        setAwaitingData(true);
        RequestUtils.getNotificationPaged(
            pagedObject.searchTerm,
            pagedObject.page,
            pagedObject.itensPerPage,
        ).then((res) => {
            if (res?.svStatus && res.data) {
                const data = res.data;
                setPagedObject({ ...pagedObject, amountPage: data.pageCount });
                arrNotifications.setValue(data.listNotification);
            } else {
                snackbar(res.msg || "Não foi possível listar notificações.").error();
            }
            setAwaitingData(false);
        }).catch((e) => {
            snackbar("Erro ao listar notificações.").error();
            setAwaitingData(false);
        });
    }

    function onPressButton() {
        const obj = { ...pagedObject, page: 1, searchTerm: inputValue };
        setPagedObject(obj);
        handlePagedNotifications(obj);
    }

    function handleChange(label, value) {
        let obj = pagedObject;

        switch (label) {
            case "searchTerm":
                setInputValue(value);
                if (value === "") obj = { ...obj, page: 1, searchTerm: value };
                break;
            default:
                break;
        }

        setPagedObject(obj);
        if (label !== "searchTerm" || value === "") handlePagedNotifications(obj);
    }

    function handleChangePage(event, value) {
        const obj = { ...pagedObject, page: value };
        setPagedObject(obj);
        handlePagedNotifications(obj);
    };

    return (
        <Page className={classes.root} title="Notificações">
            <Container maxWidth={false}>
                <Toolbar
                    inputValue={inputValue}
                    pagedObject={pagedObject}
                    onPressButton={onPressButton}
                    handleChange={handleChange}
                />
                {!awaitData ? (
                    <>
                        {arrNotifications.value.length > 0 ? (
                            <>
                                <Box mt={3}>
                                    <Grid
                                        container
                                        spacing={3}
                                    >
                                        {arrNotifications.value?.map((notification) => (
                                            <Grid
                                                item
                                                key={notification.idNotification}
                                                lg={4}
                                                md={6}
                                                xs={12}
                                            >
                                                <NotificationCard
                                                    notification={notification}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>
                                <Box mt={3} display="flex" justifyContent="center">
                                    <Pagination
                                        color="primary"
                                        size="small"
                                        count={pagedObject.amountPage}
                                        page={pagedObject.page}
                                        onChange={handleChangePage}
                                    />
                                </Box>
                            </>
                        ) : (
                            <Box mt={3} display="flex" justifyContent="center">
                                <Typography variant="body1" gutterBottom color="primary">
                                    Nenhum item encontrado.
                                </Typography>
                            </Box>
                        )}
                    </>
                ) : (
                    <Box mt={3} display="flex" justifyContent="center">
                        <CircularProgress />
                    </Box>
                )}
            </Container>
        </Page>
    )
}

export default NotificationsList;