import React from 'react';
import {
  Box,
  Container,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'components/Page';
import { Notifications, Password } from './components';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

function SettingsView() {
  const classes = useStyles();
  return (
    <Page className={classes.root} title="Settings">
      <Container maxWidth="lg">
        <Notifications />
        <Box mt={3}>
          <Password />
        </Box>
      </Container>
    </Page>
  );
};

export default SettingsView;
