import React, { useState, createContext } from 'react';
import { Snackbar } from '@mui/material/';

const SnackbarContext = createContext();

function SnackbarProvider({ children }) {
	const [content, setContent] = useState(undefined);
	const [snackbarData, setSnackbarData] = useState({ open: false, message: "", onClose: () => { } });

	const close = () => setSnackbarData(prevData => ({ ...prevData, open: false }));
	const open = (content, data) => {
		setContent(content);
		setSnackbarData({ ...data, open: true, onClose: close })
	};

	return (
		<SnackbarContext.Provider value={{ open, close }}>
			{children}
			<Snackbar {...snackbarData}>{content}</Snackbar>
		</SnackbarContext.Provider>
	);
}

export { SnackbarContext, SnackbarProvider };