import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import moment from 'moment';
import {
    Avatar,
    Box,
    Card,
    CardHeader,
    CardMedia,
    CardContent,
    CircularProgress,
    Divider,
    Grid,
    IconButton,
    List,
    Paper,
    Typography,
} from '@mui/material';
import { decode } from "../../../modules/utils/encryption";
import { makeStyles } from '@mui/styles';
import { useThemeState } from 'providers/theme';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

const useStyles = makeStyles(({
    root: {
        height: '100%'
    },
    title: {
        height: 48,
        width: 48
    },
    imgStyle: {
        maxWidth: '100%',
        maxHeight: '100px',
        height: 'auto',
        paddingRight: "250px",
        objectFit: 'contain !important'
    },
    ball: {
        width: 5,
        height: 5,
        marginLeft: 10,
        marginRight: 10,
        borderRadius: 20
    },
    paperContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 5,
        cursor: 'pointer',
    }
}));

function LastForumMessages({ className, amountItens, awaitData, ...rest }) {
    const classes = useStyles();

    const [dataForum, setDataForum] = useState([]);
    const [lengthContainer, setLengthContainer] = useState(12);
    const theme = useThemeState();

    useEffect(() => {
        if (!amountItens) return;
        let forumComments = [];

        amountItens.forumMessageList.forEach((comment) => {

            const dto = {
                id: comment.idForumMessage,
                idForum: comment.idForumNavigation.idForum,
                title: comment.idForumNavigation.forumTitle,
                dateSend: moment(comment.messageCreateAt).format('DD/MM/YYYY - HH:mm'),
                messages: comment.messageType === 'img' ? comment.comment : decode(comment.comment),
                type: comment.messageType
            }

            forumComments.push(dto);
        })

        if(forumComments.length > 5){
            setLengthContainer(6);
        } else {
            setLengthContainer(12);
        }

        setDataForum(forumComments);
    }, [amountItens])

    return (
        <Card className={clsx(classes.root, className)} {...rest}>
            <CardHeader
                subtitle={`${dataForum.length} no total`}
                title="10 ÚLTIMAS MENSAGENS DOS FORUNS"
            />
            <Divider />
            <CardContent>
                {!awaitData ? (
                    <List>
                        {dataForum.length > 0 ? (
                            <Grid container p={2} display="flex" alignItems='center'>
                                {dataForum.map((object, index) => (
                                    <Grid item key={index} lg={lengthContainer} md={lengthContainer} sm={lengthContainer} xl={lengthContainer} xs={lengthContainer}>
                                        <Grid container p={2} display="flex" alignItems='center'>
                                            <Grid item lg={10} md={10} sm={10} xl={10} xs={10} display="flex" alignItems='center'>
                                                <Avatar>
                                                    {object.title[0]}
                                                </Avatar>
                                                <Box pl={2} display="flex" flexDirection="column">
                                                    <Typography variant="caption" display="flex" alignItems="center">
                                                    {object.title}
                                                    <Box className={classes.ball} backgroundColor={theme.darkMode ? "#FFF" : "#000"}/>
                                                    {object.dateSend}
                                                    </Typography>
                                                        {object.type === 'img' ? (
                                                            <CardMedia
                                                                component="img"
                                                                className={classes.imgStyle}
                                                                src={`data:image/webp;base64,${object.messages}`}
                                                                alt="img"
                                                            />
                                                        ) : (
                                                            <Typography variant="h6">
                                                                {object.messages}
                                                            </Typography>
                                                        )}
                                                </Box>
                                            </Grid>

                                            <Grid item lg={2} sm={2} xl={2} xs={2} display="flex" justifyContent='flex-end'>
                                                <Paper
                                                    className={classes.paperContainer}
                                                    component={RouterLink}
                                                    to={`/foruns/mensagens/${object.idForum}`}
                                                >
                                                    <IconButton size="small">
                                                        <ZoomInIcon />
                                                    </IconButton>
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        ) : (
                            <Box mt={3} mb={3} display="flex" justifyContent="center">
                                <Typography variant="h6">
                                    Não há mensagens
                                </Typography>
                            </Box>
                        )}
                    </List>
                ) : (
                    <Box mt={3} display="flex" justifyContent="center">
                        <CircularProgress />
                    </Box>
                )}
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
            </Box>
        </Card>
    );
};

LastForumMessages.propTypes = {
    className: PropTypes.string,
    amountItens: PropTypes.object,
    awaitData: PropTypes.bool
};

export default LastForumMessages;
