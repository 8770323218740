import React, { useState, useEffect } from "react";
import { Box, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Results } from "./components";
import Page from "components/Page";
import Toolbar from 'components/Toolbar';
import data from "./data";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

function CustomerListView() {
  const classes = useStyles();
  const [customers] = useState(data);
  const [arrayCustomers, setArrayCustomers] = useState([]);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setArrayCustomers(customers);
  }, []);

  function filterCostumers(value) {
    setInputValue(value);
    let filter = customers.filter(
      (costumer) =>
        costumer.name.toUpperCase().includes(value.toUpperCase()) ||
        costumer.email.toUpperCase().includes(value.toUpperCase()) ||
        costumer.phone.toUpperCase().includes(value.toUpperCase()) ||
        costumer.address.country.toUpperCase().includes(value.toUpperCase()) ||
        costumer.address.state.toUpperCase().includes(value.toUpperCase()) ||
        costumer.address.city.toUpperCase().includes(value.toUpperCase())
    );
    setArrayCustomers(filter);
  }

  return (
    <Page className={classes.root} title="Customers">
      <Container maxWidth={false}>
        <Toolbar
          filter={filterCostumers}
          inputValue={inputValue}
          inputLabel={"Search"}
          inputPlaceholder={"Search customer"}
          buttonPath={"/customers"}
          buttonText={"Add customer"}
          importButtonVisible={true}
          exportButtonVisible={true}
        />
        <Box mt={3}>
          <Results customers={arrayCustomers} />
        </Box>
      </Container>
    </Page>
  );
}

export default CustomerListView;
