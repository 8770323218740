import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import {
    Card, Modal, Box,
    CardContent, Typography,
    IconButton, CardHeader,
    Divider,
    Button,
} from '@mui/material';

const useStyles = makeStyles((theme) => ({
    modalContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        boxShadow: 24,
    },
    boxAllData: {
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: theme.palette.inverse.main,
        borderRadius: "4px"
    },
    buttonStyle: {
        width: 150, 
        height: 50
    }
}));


function UserDeleteModal({ statusModal, setStatusModal, idToDelete, deleteUser, ...rest }) {
    const classes = useStyles();

    return (
        <Modal
            open={statusModal}
            onClose={() => setStatusModal({idUser: 0, active: false})}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            {...rest}
        >
            <Card className={classes.modalContainer}>
                <CardHeader
                    action={
                        <IconButton aria-label="closeModal" onClick={() => setStatusModal({idUser: 0, active: false})}>
                            <CloseIcon />
                        </IconButton>
                    }
                />
                <Divider />
                <CardContent>
                    <Box p={2}>
                        <Box mb={4}>
                            <Typography variant="h3" align="center" gutterBottom>
                                Deseja realmente excluir o usuário?
                            </Typography>
                        </Box>
                        <Box container p={4} style={{ display: "flex", justifyContent: "space-around"}}>
                            <Button variant="contained" className={classes.buttonStyle} color="primary" onClick={() => {deleteUser(idToDelete); setStatusModal({idUser: 0, active: false})}}>
                                Sim
                            </Button>
                            <Button variant="outlined" className={classes.buttonStyle} onClick={() => setStatusModal({idUser: 0, active: false})}>
                                Não
                            </Button>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Modal>
    );
}

UserDeleteModal.propTypes = {
    idToDelete: PropTypes.number.isRequired,
    statusModal: PropTypes.bool.isRequired,
    setStatusModal: PropTypes.func.isRequired,
    deleteUser: PropTypes.func.isRequired,
}

export default UserDeleteModal;