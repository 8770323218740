import React from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import PersonIcon from '@mui/icons-material/Person';
import { NavLink as RouterLink } from 'react-router-dom';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {
    Box, Card, Divider,
    Grid, Typography, CardHeader,
    IconButton
} from '@mui/material';

import stringRedutor from 'modules/utils/stringRedutor';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    statsItem: {
        alignItems: 'center',
        display: 'flex'
    },
    statsIcon: {
        marginRight: theme.spacing(1)
    }
}));

function RequestHelpCard({ className, requestHelp, ...rest }) {
    const classes = useStyles();

    return (
        <Card className={clsx(classes.root, className)} {...rest}>
            <CardHeader
                title={stringRedutor(requestHelp.idUserNavigation.name, 25)}
                action={
                    <Box>
                        <IconButton
                            aria-label="edit"
                            component={RouterLink}
                            to={`/usuarios/editar/${requestHelp.idUser}`}
                        >
                            <PersonIcon />
                        </IconButton>
                    </Box>
                }
            />
            <Box flexGrow={1} />
            <Divider />
            <Box p={2}>
                <Grid
                    container
                    justifyContent="space-between"
                    spacing={2}
                >
                    <Grid
                        className={classes.statsItem}
                        item
                    >
                        <AccessTimeIcon
                            className={classes.statsIcon}
                            color="action"
                        />
                        <Typography
                            color="textSecondary"
                            display="inline"
                            variant="body2"
                        >
                            {requestHelp.date ? moment(requestHelp.date).format('DD/MM/YYYY HH:mm:ss') : "--/--/----"}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Card>
    );
}

RequestHelpCard.propTypes = {
    className: PropTypes.string,
    requestHelp: PropTypes.object.isRequired,
}

export default RequestHelpCard;