import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useLocalStorage from 'hooks/useLocalStorage';
import PropTypes from 'prop-types';
import {
	Avatar,
	Box,
	Divider,
	Drawer,
	Hidden,
	List,
	Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import NavItem from './components/';
import { items } from './data';


/* const user = {
	avatar: '/static/images/avatars/avatar_6.png',
	jobTitle: 'Senior Developer',
	name: {currentUser}
};
 */
const useStyles = makeStyles(() => ({
	mobileDrawer: {
		width: 256
	},
	desktopDrawer: {
		width: 256,
		top: 64,
		height: 'calc(100% - 64px)'
	},
}));

const NavBar = ({ onMobileClose, openMobile }) => {
	const classes = useStyles();
	const location = useLocation();
	const [currentUser] = useLocalStorage('currentUser');

	useEffect(() => {
		if (openMobile && onMobileClose) {
			onMobileClose();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname]);

	const content = (
		<Box
			height="100%"
			display="flex"
			flexDirection="column"
		>
			<Box
				alignItems="center"
				display="flex"
				flexDirection="column"
				p={2}
			>
				<Avatar
					sx={{ width: 64, height: 64 }}
					/* src={user.avatar} */
					to="/app/account"
				/>
				<Typography
					className={classes.name}
					color="textPrimary"
					variant="h5"
				>
					{currentUser?.name}
				</Typography>
				<Typography
					color="textSecondary"
					variant="body2"
				>
					{currentUser?.email}
				</Typography>
			</Box>
			<Divider />
			<Box p={2}>
				<List>
					{items.map((item) => (
						<NavItem
							href={item.href}
							key={item.title}
							title={item.title}
							icon={item.icon}
							action={item?.action}
						/>
					))}
				</List>
			</Box>
			<Box flexGrow={1} />
		</Box>
	);

	return (
		<>
			<Hidden lgUp>
				<Drawer
					anchor="left"
					classes={{ paper: classes.mobileDrawer }}
					onClose={onMobileClose}
					open={openMobile}
					variant="temporary"
				>
					{content}
				</Drawer>
			</Hidden>
			<Hidden mdDown>
				<Drawer
					anchor="left"
					classes={{ paper: classes.desktopDrawer }}
					open
					variant="persistent"
				>
					{content}
				</Drawer>
			</Hidden>
		</>
	);
};

NavBar.propTypes = {
	onMobileClose: PropTypes.func,
	openMobile: PropTypes.bool
};

NavBar.defaultProps = {
	onMobileClose: () => { },
	openMobile: false
};

export default NavBar;
