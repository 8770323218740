import { makeStyles } from "@mui/styles";
import { alpha } from '@mui/material'

export default makeStyles(theme => ({
  input: {
    display: "none!important"
  },
  image: {
    [theme.breakpoints.up("md")]: {
      width: props => props.size,
      height: props => props.size,
    },
    [theme.breakpoints.down("md")]: {
      width: props => props.size/2,
      height: props => props.size/2,
    },
    borderRadius: '2px'
  },
  frame: {
    padding: '2px',
    borderRadius: '2px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
  },
  removeButton: {
    borderColor: '#c10016',
    backgroundColor: '#c10016',
    color: '#fff',
		"&:hover": {
			backgroundColor: alpha('#c10016', 0.8),
		},
  },
  errorText: {
    color: '#c10016',
  }
}))
