import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Box, Grid,
  Divider,
  Button, Card,
  CardContent, CardHeader,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormikTextField } from 'components/formElements';

const useStyles = makeStyles(({
  root: {}
}));

const validationSchema = Yup.object({
  password: Yup.string()
    .required('Senha é obrigatória')
    .min(8, 'Senha deve conter no mínimo 8 caracteres')
    .matches(/(?=.*[a-z])/, "Senha deve contém pelo menos um caractere minúsculo")
    .matches(/(?=.*[A-Z])/, "Senha deve contém pelo menos um caractere maiúsculo")
    .matches(/(?=.*\d)/, "Senha deve contém pelo menos um dígito")
    .matches(/(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])/, "Senha deve contém pelo menos um caractere especial"),
  confirmPassword: Yup.string()
    .required('Senha é obrigatória')
    .oneOf([Yup.ref('password'), null], 'Senhas devem ser indênticas')
});

function Password({ className, ...rest }) {
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: (values) => {
      window.alert(JSON.stringify(values))
    }
  });

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader subheader="Update password" title="Password" />
      <Divider />
      <form onSubmit={formik.handleSubmit}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormikTextField fullWidth formik={formik} name="password" label="Password" />
            </Grid>
            <Grid item xs={12}>
              <FormikTextField fullWidth formik={formik} name="confirmPassword" label="Confirm password" />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button type="submit" color="primary" variant="contained">
            Update
          </Button>
        </Box>
      </form>
    </Card>
  );
};

Password.propTypes = {
  className: PropTypes.string
};

export default Password;
