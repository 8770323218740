import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useParams, useHistory } from 'react-router-dom';

import useArray from 'hooks/useArray';
import useSnackbar from 'hooks/useSnackbar';
import useLocalStorage from 'hooks/useLocalStorage';
import RequestUtils from 'modules/api/RequestUtils';

import Page from 'components/Page';
import { GroupForm, GroupMembersList, AddMemberModal } from './components';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const validationSchema = Yup.object({
    title: Yup.string()
        .required('Campo obrigatório')
        .max(100, "O título não pode ultrapassar 100 caracteres."),
    description: Yup.string()
        .required('Campo obrigatório')
        .max(200, "A descrição não pode ultrapassar 200 caracteres."),
});

function Group() {
    const classes = useStyles();
    const history = useHistory();
    const snackbar = useSnackbar();
    const { idGroup } = useParams();
    const [currentUser] = useLocalStorage('currentUser');
    const arrMembers = useArray([]);
    const arrUsers = useArray([]);
    const [awaitData, setAwaitingData] = useState(false);
    const [awaitDataMembers, setAwaitingDataMembers] = useState(false);
    const [statusModal, setStatusModal] = useState(false);
    const [groupData, setGroupData] = useState();
    const [inputValue, setInputValue] = useState('');
    const [pagedObject, setPagedObject] = useState({
        searchTerm: '',
        page: 1,
        itensPerPage: 6,
        userType: 0,
        userPlan: 2,
        amountPage: 1
    });

    const formik = useFormik({
        initialValues: {
            title: '',
            description: '',
        },
        validationSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        }
    });

    function componentMount() {
        if (!idGroup) return;

        getGroupData(idGroup);
        getGroupMembers(idGroup);
        handlePagedUsers(pagedObject);
    }
    useEffect(componentMount, [])

    function getGroupData(idGroup) {
        setAwaitingData(true);
        RequestUtils.getGroupById(idGroup).then((res) => {
            if (res?.svStatus && res.data) {
                const group = res.data;
                setGroupData(group);
                formik.setValues({
                    title: group.groupTitle,
                    description: group.groupDescription,
                });
            } else {
                snackbar(res.msg || "Não foi possível buscar dados do grupo.").error();
            }
            setAwaitingData(false);
        }).catch((e) => {
            snackbar("Erro ao buscar dados do grupo.").error();
            setAwaitingData(false);
        });
    }

    function getGroupMembers(idGroup) {
        setAwaitingDataMembers(true);
        RequestUtils.getGroupMembersByIdGroup(idGroup).then((res) => {
            if (res?.svStatus && res.data) {
                const groupMembers = res.data;
                arrMembers.setValue(groupMembers);
            } else {
                snackbar(res.msg || "Não foi possível listar os membros do grupo.").error();
            }
            setAwaitingDataMembers(false);
        }).catch((e) => {
            snackbar("Erro ao listar os membros do grupo.").error();
            setAwaitingDataMembers(false);
        });
    }

    function createGroup(data) {
        if (!currentUser.idUser) return;

        const newGroup = {
            idUserCreated: currentUser.idUser,
            groupTitle: data.title,
            groupDescription: data.description,
        }

        setAwaitingData(true);
        RequestUtils.addGroup(newGroup).then((res) => {
            if (res?.svStatus && res.data) {
                snackbar("Grupo cadastrado com sucesso.").success();
                history.push(`/grupos/editar/${res.data.idGroup}`);
            } else {
                snackbar(res.msg || "Não foi possível cadastrar grupo.").error();
            }
            setAwaitingData(false);
        }).catch((e) => {
            snackbar("Erro ao cadastrar grupo.").error();
            setAwaitingData(false);
        });
    }

    function editGroup(data) {
        const editGroup = {
            idGroup: Number(groupData.idGroup) || Number(idGroup),
            groupTitle: data.title,
            groupDescription: data.description,
            idUserCreated: Number(groupData.idUserCreated),
        }

        setAwaitingData(true);
        RequestUtils.updateGroup(editGroup).then((res) => {
            if (res?.svStatus && res.data) {
                const group = res.data;
                formik.setValues({
                    title: group.groupTitle,
                    description: group.groupDescription,
                });
                setGroupData(group);
                snackbar("Grupo atualizado com sucesso.").success();
            } else {
                snackbar(res.msg || "Não foi possível atualizar grupo.").error();
            }
            setAwaitingData(false);
        }).catch((e) => {
            snackbar("Erro ao atualizar grupo.").error();
            setAwaitingData(false);
        });
    }

    function handleSubmit(data) {
        if (idGroup === undefined) {
            createGroup(data);
        } else {
            editGroup(data);
        }
    }

    function onPressButton() {
        const obj = { ...pagedObject, page: 1, searchTerm: inputValue };
        setPagedObject(obj);
        handlePagedUsers(obj);
    }

    function handleChange(label, value) {
        let obj = pagedObject;

        switch (label) {
            case "searchTerm":
                setInputValue(value);
                if (value === "") obj = { ...obj, page: 1, searchTerm: value };
                break;
            case "userType":
                obj = { ...obj, page: 1, userType: value };
                break;
            case "userPlan":
                obj = { ...obj, page: 1, userPlan: value };
                break;
            default:
                break;
        }

        setPagedObject(obj);
        if (label !== "searchTerm" || value === "") handlePagedUsers(obj);
    }

    async function handlePagedUsers(pagedObject) {
        setAwaitingData(true);

        const plan = pagedObject.userPlan == 2 ? null : !!pagedObject.userPlan;

        RequestUtils.getUsersPaged(
            pagedObject.searchTerm,
            pagedObject.page,
            pagedObject.itensPerPage,
            pagedObject.userType,
            true,
            plan
        ).then((res) => {
            if (res?.svStatus && res.data) {
                const data = res.data;
                setPagedObject({ ...pagedObject, amountPage: data.pageCount });
                arrUsers.setValue(data.listUser);
            } else {
                snackbar(res.msg || "Não foi possível listar usuários.").error();
            }
            setAwaitingData(false);
        }).catch((e) => {
            snackbar("Erro ao listar usuários.").error();
            setAwaitingData(false);
        });
    }

    function handleChangePage(event, value) {
        const obj = { ...pagedObject, page: value };
        setPagedObject(obj);
        handlePagedUsers(obj);
    };

    function removeMember(idGroupMember) {
        setAwaitingDataMembers(true);
        RequestUtils.deleteGroupMember(idGroupMember).then((res) => {
            if (res?.svStatus && res.data) {
                const arr = arrMembers.value.filter((members) => members.idGroupMember != idGroupMember);
                arrMembers.setValue(arr);
                snackbar("Usuário removido com sucesso.").success();
            } else {
                snackbar(res.msg || "Não foi possível remover usuário do grupo.").error();
            }
            setAwaitingDataMembers(false);
        }).catch((e) => {
            snackbar("Erro ao remover usuário do grupo.").error();
            setAwaitingDataMembers(false);
        });
    }

    function addMember(idUser) {
        if (!currentUser.idUser) return;
        if (!idGroup) return;

        const dto = {
            idUser: currentUser.idUser,
            idGroup: Number(idGroup),
            idUserInserted: idUser
        }

        setAwaitingDataMembers(true);
        RequestUtils.addGroupMember(dto).then((res) => {
            if (res?.svStatus && res.data) {
                let newGroupMember = res.data;
                arrMembers.push(newGroupMember);
                setStatusModal(false);
                snackbar("Usuário vinculado com sucesso.").success();
            } else {
                snackbar(res.msg || "Não foi possível vincular usuário ao grupo.").error();
            }
            setAwaitingDataMembers(false);
        }).catch((e) => {
            snackbar("Erro ao vincular usuário ao grupo.").error();
            setAwaitingDataMembers(false);
        });
    }

    return (
        <Page className={classes.root} title={idGroup === undefined ? "Cadastrar grupo" : "Editar grupo"}>
            <Container maxWidth="lg">
                <Grid container justifyContent="center" spacing={3}>
                    <Grid item lg={10} md={10} xs={12}>
                        <GroupForm formik={formik} idGroup={idGroup} awaitData={awaitData} groupData={groupData} />
                    </Grid>
                    {idGroup &&
                        <Grid item lg={10} md={10} xs={12}>
                            <AddMemberModal
                                addMember={addMember}
                                statusModal={statusModal}
                                setStatusModal={setStatusModal}
                                handleChange={handleChange}
                                pagedObject={pagedObject}
                                inputValue={inputValue}
                                onPressButton={onPressButton}
                                handleChangePage={handleChangePage}
                                arrUsers={arrUsers.value}
                            />
                            <GroupMembersList
                                arrMembers={arrMembers.value}
                                removeMember={removeMember}
                                setStatusModal={setStatusModal}
                                awaitData={awaitDataMembers}
                            />
                        </Grid>
                    }
                </Grid>
            </Container>
        </Page>
    );
}

export default Group;