import React from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import MenuBook from '@mui/icons-material/MenuBook';

import { Copyright, SmokingRooms, FiberManualRecord, PublishedWithChanges, Favorite } from '@mui/icons-material';
import {
	Box, Grid, Divider,
	Card, CardContent, CardHeader,
	Typography, IconButton
} from '@mui/material';

import { FormikTextField, FormikSelect, FormikDatepicker } from 'components/formElements';
import { masks } from 'modules/utils/mask';

const useStyles = makeStyles(() => ({
	statusUser: {
		display: 'flex',
		justifyContent: 'start',
		alignItems: 'center',
	}
}));

function UserForm({ className, formik, idUser, userData, awaitData, setSmokingLoadModal, setFargerstromModal,
	setHealthModal, setPercentageModal, setDiaryModal, ...rest }) {
	const classes = useStyles();

	const statesTypeUser = [
		{ value: 0, label: 'Usuário' },
		{ value: 1, label: 'Admin' },
	];

	const statesGender = [
		{ value: 'Feminino', label: 'Feminino' },
		{ value: 'Masculino', label: 'Masculino' },
		{ value: 'Prefiro não informar', label: 'Prefiro não informar' },
	];

	const statesPremium = [
		{ value: 0, label: 'Não' },
		{ value: 1, label: 'Sim' },
	];

	function getSyncStatus() {
		if (userData?.syncHotmart && userData?.syncLeadLover) {
			return "Hotmart - LeadLover"
		} else if (userData?.syncLeadLover) {
			return "LeadLover"
		} else if (userData?.syncHotmart) {
			return "Hotmart"
		}
	}

	return (
		<Card {...rest} className={clsx(classes.root, className)}>
			<CardHeader
				title={idUser === undefined ? "Cadastrar novo usuário" : "Editar usuário"}
				subheader={idUser === undefined ?
					"Informe todos os dados para cadastrar um usuário"
					:
					"Informe todos os dados para editar o usuário"
				}

				action={
					<Box>
						{idUser &&
							<>
								<IconButton aria-label="health" onClick={() => setHealthModal(true)}>
									<Favorite />
								</IconButton>
								<IconButton aria-label="fargerston" onClick={() => setFargerstromModal(true)}>
									<SmokingRooms />
								</IconButton>
								<IconButton aria-label="settings" onClick={() => setSmokingLoadModal(true)} >
									<Copyright />
								</IconButton>
								<IconButton aria-label="diary" onClick={() => setDiaryModal(true)}>
									<MenuBook />
								</IconButton>
								<IconButton aria-label="percentage" onClick={() => setPercentageModal(true)}>
									<PublishedWithChanges />
								</IconButton>
							</>
						}
					</Box>
				}
			/>
			<Divider />
			<form onSubmit={formik.handleSubmit}>
				<CardContent>
					<Grid container spacing={3}>
						<Grid item md={12} xs={12}>
							<FormikTextField fullWidth formik={formik} name="name" label="Nome" />
						</Grid>
						<Grid item md={6} xs={12}>
							<FormikTextField fullWidth formik={formik} name="email" label="E-mail" />
						</Grid>
						<Grid item md={6} xs={12}>
							<FormikTextField fullWidth formik={formik} mask={masks.phone} name="phone" label="Telefone" />
						</Grid>
						<Grid item md={6} xs={12}>
							<FormikDatepicker fullWidth formik={formik} name="birthDate" label="Data de nascimento" />
						</Grid>
						<Grid item md={6} xs={12}>
							<FormikSelect fullWidth formik={formik} options={statesGender} name="gender" label="Gênero" />
						</Grid>
						<Grid item md={6} xs={12}>
							<FormikSelect fullWidth formik={formik} options={statesTypeUser} name="userType" label="Tipo" />
						</Grid>
						<Grid item md={6} xs={12}>
							<FormikSelect fullWidth formik={formik} options={statesPremium} name="userIsPremium" label="Premium" disabled={formik.values.userType == 1} />
						</Grid>
						{!idUser &&
							<>
								<Grid item md={6} xs={12}>
									<FormikTextField fullWidth formik={formik} type="password" name="password" label="Senha" />
								</Grid>
								<Grid item md={6} xs={12}>
									<FormikTextField fullWidth formik={formik} type="password" name="confirmPassword" label="Confirmar Senha" />
								</Grid>
							</>
						}
					</Grid>
				</CardContent>
				<Divider />
				<Box display="flex" justifyContent={idUser === undefined ? "flex-end" : "space-between"} p={2} alignItems="center">

					{idUser &&
						<Grid container spacing={2} display="flex">
							<Grid item md={12} xs={12} >
								<Typography variant="body2" color="text.secondary">
									Criado em {userData?.userCreatedAt ? moment(userData?.userCreatedAt).format('DD/MM/YYYY') : "--/--/----"}
								</Typography>
								<div className={classes.statusUser}>
									<Typography
										color="textSecondary"
										display="inline"
										variant="body2"
									>
										Status:
									</Typography>
									<FiberManualRecord
										className={classes.statsIcon}
										sx={{ color: userData?.userIsActive ? 'green' : 'red' }}
									/>
									<Typography
										color="textSecondary"
										display="inline"
										variant="body2"
									>
										{userData?.userIsActive ? 'Ativo' : 'Inativo'}
									</Typography>
								</div>

								{userData?.syncHotmart || userData?.syncLeadLover ? (

									<Typography
										color="textSecondary"
										display="inline"
										variant="body2"
									>

										Sincronização: {getSyncStatus()}
									</Typography>
								) : null}

							</Grid>
						</Grid>

					}

					<LoadingButton
						type="submit"
						color="primary"
						variant="contained"
						style={{ height: 42, width: 112 }}
						loading={awaitData}
					>
						{idUser === undefined ? "Cadastrar" : "Editar"}
					</LoadingButton>
				</Box>
			</form>
		</Card >
	);
};

UserForm.propTypes = {
	className: PropTypes.string,
	formik: PropTypes.object.isRequired,
	setDiaryModal: PropTypes.func.isRequired,
	idUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	userData: PropTypes.object,
	awaitData: PropTypes.bool.isRequired,
	setSmokingLoadModal: PropTypes.func.isRequired,
	setFargerstromModal: PropTypes.func.isRequired,
	setPercentageModal: PropTypes.func.isRequired
};

export default UserForm;