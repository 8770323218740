import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import Page from 'components/Page';
import GoogleMaps from 'components/GoogleMaps';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

function Map() {
  const classes = useStyles();
	const [map, setMap] = useState(null);

	const initMap = (map) => {
		map.addListener("zoom_changed", () => {
			/* console.log(map.zoom); */
		});
		map.addListener("center_changed", () => {
			/* console.log(`${map.center.lat()};${map.center.lng()}`); */
		});
		setMap(map);
	}

	return (
		<Page className={classes.root} title="Map">
			<GoogleMaps init={initMap} />
		</Page>
	);
};

export default Map;