import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Grid, Divider, Card, CardContent, CardHeader, TextField, InputAdornment, IconButton } from '@mui/material';
import { FormikSelect, FormikTextField } from 'components/formElements';
import { PersonAddAlt } from '@mui/icons-material';

const useStyles = makeStyles(() => ({
    statusUser: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
    }
}));

function NotificationForm({ className, formik, awaitData, setStatusModal, userSelected, ...rest }) {
    const classes = useStyles();

    const statesTypeNotification = [
        { value: "TYPE_TOPIC", label: 'Enviar para um tópico' },
        { value: "TYPE_USER", label: 'Enviar para um usuário' }
    ];

    const statesTopic = [
        { value: "USER_TYPE_FREE", label: 'Comum' },
        { value: "USER_TYPE_PREMIUM", label: 'Premium' }
    ];

    return (
        <Card {...rest} className={clsx(classes.root, className)}>
            <CardHeader
                title="Cadastrar nova notificação"
                subheader="Informe todos os dados para cadastrar uma notificação"
            />
            <Divider />
            <form onSubmit={formik.handleSubmit}>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                            <FormikSelect fullWidth formik={formik} options={statesTypeNotification} name="type" label="Tipo de Notificação" />
                        </Grid>
                        <Grid item md={8} xs={7}>
                            <FormikTextField fullWidth formik={formik} name="title" label="Título" />
                        </Grid>

                        {formik.values.type === "TYPE_TOPIC" ? (
                            <Grid item md={4} xs={5}>
                                <FormikSelect fullWidth formik={formik} options={statesTopic} name="topic" label="Tipo de Usuário" />
                            </Grid>
                        ) : (
                            <Grid item md={4} xs={5}>
                                <TextField
                                    fullWidth
                                    value={userSelected.name}
                                    name="title"
                                    label="Usuário Selecionado"
                                    disabled={userSelected.disabled}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton edge="end" color="primary">
                                                    {formik.values.type === 'TYPE_USER' && (
                                                        <IconButton aria-label="settings" onClick={() => setStatusModal(true)} >
                                                            <PersonAddAlt />
                                                        </IconButton>
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        )}
                        <Grid item md={12} xs={12}>
                            <FormikTextField
                                fullWidth
                                formik={formik}
                                name="body"
                                label="Descrição"
                                multiline
                                rows={3}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider />
                <Box display="flex" justifyContent="flex-end" p={2} >

                    <LoadingButton
                        type="submit"
                        color="primary"
                        variant="contained"
                        loading={awaitData}
                    >
                        Enviar
                    </LoadingButton>
                </Box>
            </form>
        </Card>
    )
}

NotificationForm.propTypes = {
    className: PropTypes.string,
    userSelected: PropTypes.object,
    formik: PropTypes.object.isRequired,
    awaitData: PropTypes.bool.isRequired,
}

export default NotificationForm;