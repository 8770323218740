import React from "react";
import PropTypes from "prop-types";
import { FormControlLabel, Checkbox } from '@mui/material';

const propTypes = {
	name: PropTypes.string.isRequired,
	formik: PropTypes.object.isRequired,
	label: PropTypes.string,
	labelPlacement: PropTypes.string,
};

const defaultProps = {
	labelPlacement: 'end'
}

function FormikCheckbox(props) {
	const { name, label, labelPlacement, formik, ...rest } = props;
	return (
		<FormControlLabel
			label={label}
			labelPlacement={labelPlacement}
			control={
				<Checkbox
					id={name}
					name={name}
					checked={formik.values[name]}
					onChange={formik.handleChange}
					{...rest}
				/>
			}
		/>
	);
}

FormikCheckbox.propTypes = propTypes;
FormikCheckbox.defaultProps = defaultProps;

export default FormikCheckbox;