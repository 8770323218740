import {
	BarChart as BarChartIcon,
	/* Lock as LockIcon, */

	Group as UsersIcon,
	Input as InputIcon,
	DirectionsRun,
	Campaign,
	Videocam,
	NotificationsActive,
	QuestionAnswer,
	Link,
	AccessibilityNew
} from '@mui/icons-material';

export const items = [
	{
		href: '/dashboard',
		icon: BarChartIcon,
		title: 'Dashboard'
	},
	{
		href: '/passos-desafio',
		icon: Campaign,
		title: 'Desafios'
	},
	{
		href: '/foruns',
		icon: QuestionAnswer,
		title: 'Fóruns'
	},
	{
		href: '/grupos',
		icon: UsersIcon,
		title: 'Grupos'
	},
	{
		href: '/link',
		icon: Link,
		title: 'Link'
	},
	{
		href: '/desafio',
		icon: DirectionsRun,
		title: 'Maratona de desafios'
	},
	{
		href: '/notificacoes',
		icon: NotificationsActive,
		title: 'Notificações'
	},
	{
		href: '/usuarios',
		icon: UsersIcon,
		title: 'Usuários'
	},
	{
		href: '/videos',
		icon: Videocam,
		title: 'Vídeos'
	},
	{
		href: '/solicitacoes-de-ajuda',
		icon: AccessibilityNew,
		title: 'Solicitações de ajuda',
	},
	{
		href: '/',
		icon: InputIcon,
		title: 'Sair',
		action: "signOut"
	},
];
