import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Pagination } from '@mui/lab';
import {
    Box, Container, Grid,
    Typography, CircularProgress
} from '@mui/material';

import RequestUtils from 'modules/api/RequestUtils';
import useSnackbar from 'hooks/useSnackbar';
import { MarathonCard, Toolbar } from './components';

import Page from 'components/Page';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    marathonCard: {
        height: '100%'
    }
}));

function MarathonList() {
    const classes = useStyles();
    const snackbar = useSnackbar();
    const [awaitData, setAwaitingData] = useState(false);
    const [arrayMarathon, setArrayMarathon] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [pagedObject, setPagedObject] = useState({
        searchTerm: '',
        page: 1,
        itensPerPage: 12,
        marathonActive: true,
        amountPage: 1
    });

    function componentMount() {
        handlePagedMarathon(pagedObject);
    }
    useEffect(componentMount, []);

    function handlePagedMarathon(pagedObject) {
        setAwaitingData(true);
        RequestUtils.getMarathonPaged(
            pagedObject.searchTerm,
            pagedObject.page,
            pagedObject.itensPerPage,
            pagedObject.marathonActive
        ).then((res) => {
            if (res?.svStatus && res.data) {
                const data = res.data;
                setPagedObject({ ...pagedObject, amountPage: data.pageCount });
                setArrayMarathon(data.listChallengeMarathon);
            } else {
                snackbar(res.msg || "Não foi possível listar maratonas.").error();
            }
            setAwaitingData(false);
        }).catch((e) => {
            snackbar("Erro ao listar maratonas.").error();
            setAwaitingData(false);
        });
    }

    function onPressButton() {
        const obj = { ...pagedObject, page: 1, searchTerm: inputValue };
        setPagedObject(obj);
        handlePagedMarathon(obj);
    }

    async function updateStatusMarathon(idChallengeMarathon) {
        setAwaitingData(true);
        RequestUtils.updateStatusMarathon(idChallengeMarathon).then((res) => {
            if (res?.svStatus && res.data) {
                let arr = arrayMarathon.filter((marathon) => {
                    if (marathon?.idChallengeMarathon != idChallengeMarathon) return marathon;
                });
                setArrayMarathon(arr);
                snackbar("Status da maratona atualizado com sucesso.").success();
            } else {
                snackbar(res.msg || "Não foi possível atualizar status da maratona.").error();
            }
            setAwaitingData(false);
        }).catch((e) => {
            snackbar("Erro ao atualizar status da maratona.").error();
            setAwaitingData(false);
        });
    }

    function handleChange(label, value) {
        let obj = pagedObject;

        switch (label) {
            case "searchTerm":
                setInputValue(value);
                if (value === "") obj = { ...obj, page: 1, searchTerm: value };
                break;
            case "marathonActive":
                obj = { ...obj, page: 1, marathonActive: value };
                break;
            default:
                break;
        }

        setPagedObject(obj);
        if (label !== "searchTerm" || value === "") handlePagedMarathon(obj);
    }

    function handleChangePage(event, value) {
        const obj = { ...pagedObject, page: value };
        setPagedObject(obj);
        handlePagedMarathon(obj);
    };

    return (
        <Page className={classes.root} title="Maratonas">
            <Container maxWidth={false}>
                <Toolbar
                    inputValue={inputValue}
                    pagedObject={pagedObject}
                    onPressButton={onPressButton}
                    handleChange={handleChange}
                />
                {!awaitData ? (
                    <>
                        {arrayMarathon.length > 0 ? (
                            <>
                                <Box mt={3}>
                                    <Grid
                                        container
                                        spacing={3}
                                    >
                                        {arrayMarathon?.map((marathon) => (
                                            <Grid
                                                item
                                                key={marathon.idChallengeMarathon}
                                                lg={4}
                                                md={6}
                                                xs={12}
                                            >
                                                <MarathonCard
                                                    className={classes.marathonCard}
                                                    marathon={marathon}
                                                    updateStatusMarathon={updateStatusMarathon}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>
                                <Box mt={3} display="flex" justifyContent="center">
                                    <Pagination
                                        color="primary"
                                        size="small"
                                        count={pagedObject.amountPage}
                                        page={pagedObject.page}
                                        onChange={handleChangePage}
                                    />
                                </Box>
                            </>
                        ) : (
                            <Box mt={3} display="flex" justifyContent="center">
                                <Typography variant="body1" gutterBottom color="primary">
                                    Nenhum item encontrado.
                                </Typography>
                            </Box>
                        )}
                    </>
                ) : (
                    <Box mt={3} display="flex" justifyContent="center">
                        <CircularProgress />
                    </Box>
                )}
            </Container>
        </Page>
    );
};

export default MarathonList;