import React, { useState, useMemo } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
    Grid, Card, Box,
    CardMedia, IconButton, Typography,
    Collapse
} from '@mui/material';

import { decode } from "modules/utils/encryption";

import { InputComment } from './index';

const useStyles = makeStyles((theme) => ({
    date: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
        justifyContent: "flex-end"
    },
    messageAlignLeft: {
        display: 'flex',
        justifyContent: 'flex-start'
    },
    messageAlignRight: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    messageHeader: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignContent: 'center'
    },
    messageImgContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    imgStyle: {
        maxWidth: '100%',
        maxHeight: '200px',
        height: 'auto',
        objectFit: 'contain !important'
    },
    boxCard: {
        width: '70%',
        display: 'flex',
        flexDirection: 'column',
    },
    messageCardUser: {
        background: `${theme.palette.message.primary} !important`,
        width: '100%',
        padding: '10px'
    },
    messageCardOtherUser: {
        background: `${theme.palette.message.secondary} !important`,
        width: '100%',
        padding: '10px'
    },
    boxActionsCard: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    actionsCard: {
        display: 'flex',
        alignItems: 'center',
    },
    replyBox: {
        width: '100%',
        borderLeft: '1px solid',
        paddingLeft: '10px',
    },
    replyCard: {
        background: `${theme.palette.message.reply} !important`,
        width: '100%',
        padding: '10px',
        minHeight: '50px',
    },
    replyCardUserMessage: {
        background: `${theme.palette.message.replySecondary} !important`,
        width: '100%',
        padding: '10px',
        minHeight: '50px',
    },
    pointerButton: {
        cursor: 'pointer'
    },
    messageText: {
        whiteSpace: 'pre-line',
    }
}));

function MessageCard({
    message, userMessage, deleteMessage,
    awaitDataMessage, commentValue, setCommentValue,
    sendMessage, idUser, messageReaction, ...rest
}) {
    const classes = useStyles();
    const [selectComment, setSelectComment] = useState(false);
    const [viewComment, setViewComment] = useState(false);

    const userLike = useMemo(() => {
        if (!idUser) return false;

        let arr = message?.tblForumReaction?.filter((reaction) => reaction.idUser == idUser);

        return arr?.length > 0
    }, [message, message?.tblForumReaction?.length])

    function cardContent(message) {
        return (
            <>
                <Grid container spacing={1}>
                    <Grid item md={12} xs={12} className={classes.messageHeader}>
                        <Typography variant="body1" gutterBottom>
                            <b> {message.name} </b>
                        </Typography>
                        <IconButton aria-label="settings" onClick={() => deleteMessage(Number(message.idForumMessage), message.replyTo)} >
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                    {message.messageType == "text" ? (
                        <Grid item md={12} xs={12}>
                            <Typography variant="body1" className={classes.messageText} gutterBottom>
                                {decode(message?.comment)}
                            </Typography>
                        </Grid>
                    ) : (
                        <Grid item md={12} xs={12} className={classes.messageImgContainer}>
                            <CardMedia
                                component="img"
                                className={classes.imgStyle}
                                src={`data:image/webp;base64,${message?.comment}`}
                                alt="img"
                            />
                        </Grid>
                    )}
                </Grid>
            </>
        )
    }

    function replyMessages(arrMessages) {
        return (
            <Box className={classes.replyBox}>
                <Grid container spacing={2}>
                    {arrMessages?.map((data, idx) => (
                        <Grid item md={12} xs={12} key={idx}>
                            <Card className={data.idUser == idUser ? classes.replyCardUserMessage : classes.replyCard} >
                                {cardContent(data)}
                            </Card>
                            <Box className={classes.date}>
                                <Typography variant="body1" gutterBottom>
                                    {moment(data.date).format('DD/MM/YYYY - HH:mm')}
                                </Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        );
    }

    return (
        <Grid
            item md={12} xs={12}
            className={message.idUser == idUser ? classes.messageAlignRight : classes.messageAlignLeft}
        >
            <Box {...rest} className={classes.boxCard}>
                <Card className={userMessage ? classes.messageCardUser : classes.messageCardOtherUser}>
                    {cardContent(message)}
                </Card>

                <Box mt={1} className={classes.boxActionsCard}>
                    <Box display="flex">
                        <Box mr={3}>

                            <Typography
                                onClick={() => messageReaction(userLike, message)}
                                variant="body1"
                                className={classes.pointerButton}
                                color={userLike ? "primary" : ""}

                            >
                                Curtir {userLike ?
                                    `(${message?.tblForumReaction?.length})` :
                                    `${message?.tblForumReaction?.length > 0 ? `(${message.tblForumReaction.length})` : ''}`
                                }
                            </Typography>
                        </Box>
                        <Box
                            mr={3}
                            className={classes.actionsCard}
                            onClick={() => setSelectComment(!selectComment)}
                        >
                            <Typography variant="body1" className={classes.pointerButton}>
                                Comentar
                            </Typography>
                            {!selectComment ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                        </Box>
                        {message?.repliesList?.length > 0 &&
                            <Box
                                mr={3}
                                className={classes.actionsCard}
                                onClick={() => setViewComment(!viewComment)}
                            >
                                <Typography variant="body1" className={classes.pointerButton}>
                                    Ver comentários
                                </Typography>
                                {!viewComment ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                            </Box>
                        }
                    </Box>
                    <Box className={classes.date}>
                        <Typography variant="body1" gutterBottom>
                            {moment(message.date).format('DD/MM/YYYY - HH:mm')}
                        </Typography>
                    </Box>
                </Box>

                <Collapse in={selectComment} timeout="auto">
                    <Box p={1}>
                        <InputComment
                            awaitDataMessage={awaitDataMessage}
                            setCommentValue={setCommentValue}
                            commentValue={commentValue}
                            replyValue={message.idForumMessage}
                            sendMessage={sendMessage}
                        />
                    </Box>
                </Collapse>

                <Collapse in={viewComment} timeout="auto">
                    <Box mt={1} p={2}>
                        {replyMessages(message.repliesList)}
                    </Box>
                </Collapse>
            </Box>
        </Grid>
    )
}

MessageCard.propTypes = {
    message: PropTypes.object.isRequired,
    userMessage: PropTypes.bool.isRequired,
    deleteMessage: PropTypes.func.isRequired,
    awaitDataMessage: PropTypes.bool.isRequired,
    setCommentValue: PropTypes.func.isRequired,
    commentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    sendMessage: PropTypes.func.isRequired,
    idUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    messageReaction: PropTypes.func.isRequired,
}

export default MessageCard;