import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import { useFormik } from 'formik';
import { makeStyles } from '@mui/styles';
import { Container, Grid } from '@mui/material';
import { useParams, useHistory } from 'react-router-dom';

import Page from 'components/Page';
import { VideoForm } from './components';
import useSnackbar from 'hooks/useSnackbar';
import RequestUtils from 'modules/api/RequestUtils';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const validationVideo = Yup.object({
    title: Yup.string()
        .required('Campo obrigatório')
        .max(150, "O título não pode ultrapassar 150 caracteres."),
    url: Yup.string()
        .required('Campo obrigatório')
        .max(250, "A URL não pode ultrapassar 250 caracteres."),
    description: Yup.string()
        .required('Campo obrigatório'),
});

function Video() {
    const classes = useStyles();
    const snackbar = useSnackbar();
    const history = useHistory();
    const { idVideo } = useParams();
    const [awaitData, setAwaitingData] = useState(false);
    const [videoData, setVideoData] = useState();

    const formikVideo = useFormik({
        initialValues: {
            title: '',
            url: '',
            premium: false,
            description: '',
        },
        validationSchema: validationVideo,
        onSubmit: (values) => {
            handleSubmit(values);
        }
    });

    function componentMount() {
        if (!idVideo) return;

        setAwaitingData(true);
        RequestUtils.getVideoById(idVideo).then((res) => {
            if (res?.svStatus && res.data) {
                const video = res.data;
                setVideoData(video);
                formikVideo.setValues({
                    title: video.title || '',
                    url: video.url || '',
                    premium: video.premium || false,
                    description: video.description || '',
                });
            } else {
                snackbar(res.msg || "Não foi possível buscar dados do vídeo.").error();
            }
            setAwaitingData(false);
        }).catch((e) => {
            snackbar("Erro ao buscar dados do vídeo.").error();
            setAwaitingData(false);
        });
    }
    useEffect(componentMount, []);

    function createVideo(data) {

        const newVideoDto = {
            title: data.title,
            url: data.url,
            createAt: moment().format(),
            videoIsActive: true,
            premium: data.premium,
            description: data.description
        }

        setAwaitingData(true);
        RequestUtils.addVideo(newVideoDto).then((res) => {
            if (res?.svStatus && res.data) {
                snackbar("Vídeo cadastrado com sucesso.").success();
                history.push(`/videos/editar/${res.data.idVideo}`);
            } else {
                snackbar(res.msg || "Não foi possível cadastrar vídeo.").error();
            }
            setAwaitingData(false);
        }).catch((e) => {
            snackbar("Erro ao cadastrar vídeo.").error();
            setAwaitingData(false);
        });
    }

    function editVideo(data) {
        if (!idVideo) return;
        if (!videoData) return;

        const editVideoDto = {
            idVideo: Number(idVideo),
            title: data.title,
            url: data.url,
            premium: data.premium,
            description: data.description,
            createAt: videoData?.createAt,
            videoIsActive: videoData?.videoIsActive,
        }

        setAwaitingData(true);
        RequestUtils.updateVideo(editVideoDto).then((res) => {
            if (res?.svStatus && res.data) {
                const video = res.data;
                formikVideo.setValues({
                    title: video.title || '',
                    url: video.url || '',
                    premium: video.premium || false,
                    description: video.description || '',
                });
                setVideoData(video);
                snackbar("Vídeo atualizado com sucesso.").success();
            } else {
                snackbar(res.msg || "Não foi possível atualizar vídeo.").error();
            }
            setAwaitingData(false);
        }).catch((e) => {
            snackbar("Erro ao atualizar vídeo.").error();
            setAwaitingData(false);
        });
    }

    function handleSubmit(data) {
        if (idVideo === undefined) {
            createVideo(data);
        } else {
            editVideo(data);
        }
    }

    return (
        <Page className={classes.root} title={idVideo === undefined ? "Cadastrar vídeo" : "Editar vídeo"}>
            <Container maxWidth="lg">
                <Grid container justifyContent="center" spacing={3}>
                    <Grid item lg={10} md={10} xs={12}>
                        <VideoForm formik={formikVideo} idVideo={idVideo} awaitData={awaitData} videoData={videoData} />
                    </Grid>
                </Grid>
            </Container>
        </Page>
    )
}

export default Video;