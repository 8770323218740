import React from "react";
import PropTypes from "prop-types";
import { TextField } from '@mui/material';
import { applyMask } from "modules/utils/mask";

const propTypes = {
	name: PropTypes.string.isRequired,
	formik: PropTypes.object.isRequired,
	mask: PropTypes.string,
	variant: PropTypes.string
};

const defaultProps = {
	variant: 'outlined',
};

function FormikTextField(props) {
	const { name, formik, mask, ...rest } = props;

	const handleChange = (e) => {
		const value = mask ? applyMask(mask, e.target.value) : e.target.value;
		formik.setFieldValue(name, value);
	}

	return (
		<TextField
			id={name}
			name={name}
			value={formik.values[name]}
			onChange={handleChange}
			error={formik.touched[name] && Boolean(formik.errors[name])}
			helperText={formik.touched[name] && formik.errors[name]}
			{...rest}
		/>
	);
}

FormikTextField.propTypes = propTypes;
FormikTextField.defaultProps = defaultProps;

export default FormikTextField;