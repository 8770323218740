import React, { createElement } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useGlobalState } from '../../providers/global';

function PublicRoute({ component, ...rest }) {
	const { isAuthenticated } = useGlobalState();
	return (
		<Route
			{...rest}
			render={props =>
				isAuthenticated ? (
					<Redirect to="/dashboard" />
				) : (
					createElement(component, props)
				)
			}
		/>
	);
}

PublicRoute.propTypes = {
	component: PropTypes.any.isRequired,
	path: PropTypes.string,
};

export default PublicRoute;