import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import {
    Modal, Box,
    CardContent, Typography,
    CircularProgress,
    IconButton, CardHeader,
    Divider,
    Rating,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    TextField,
    Paper
} from '@mui/material';
import { FormikSelect } from 'components/formElements';
import { SentimentDissatisfied, SentimentSatisfied, SentimentSatisfiedAlt, SentimentVeryDissatisfied, SentimentVerySatisfied } from '@mui/icons-material';
import { useThemeState } from 'providers/theme';

const useStyles = makeStyles(() => ({
    modalContainer: {
        position: 'absolute',
        maxHeight: "85%",
        overflowY: 'scroll',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        boxShadow: 24,
    },
    boxAllData: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    marker: {
        height: '200px',
        width: '200px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '100px',
        background: props => props.color ? `${props.color}aa` : '#434348',
        border: props => props.color ? `10px solid ${props.color}` : `10px solid #000`,
        fontSize: 'xxx-large',
        color: '#fff',
        marginBottom: '20px',
        marginTop: '20px'
    },
    humorIcon: {
        width: "60px!important",
        height: "60px!important"
    }
}));
function DiaryModal({ className, testData, diarySelected, awaitData, statusModal, setStatusModal, formik, ...rest }) {
    const classes = useStyles();

    const darkMode = useThemeState().darkMode;

    const customIcons = {
        1: {
            icon: <SentimentVeryDissatisfied className={classes.humorIcon} />,
            label: 'Muito mau humorado',
        },
        2: {
            icon: <SentimentDissatisfied className={classes.humorIcon} />,
            label: 'Mau humorado',
        },
        3: {
            icon: <SentimentSatisfied className={classes.humorIcon} />,
            label: 'Neutro',
        },
        4: {
            icon: <SentimentSatisfiedAlt className={classes.humorIcon} />,
            label: 'Feliz',
        },
        5: {
            icon: <SentimentVerySatisfied className={classes.humorIcon} />,
            label: 'Muito feliz',
        },
    };

    function IconContainer(props) {
        const { value, ...other } = props;

        return (
            <Box {...other} display="flex" flexDirection="column" pr={4} alignItems="center">
                {customIcons[value].icon}
                <Typography align='center' variant='caption' width='60px' color="grayText"> {customIcons[value].label} </Typography>
            </Box>
        )
    }

    function getDiaryDate() {
        let diaryList = testData;
        let optionObject = [];

        diaryList.forEach((diary) => {
            const dto = {
                label: moment(diary.dateReference).format('DD/MM/YYYY'),
                value: diary.idUserDiary
            }
            optionObject.push(dto);
        })
        return optionObject;
    }

    function getHumorColor() {

        if (darkMode) {
            if (diarySelected.moodHumor == 1) {
                return "#E1110C";
            } else if (diarySelected.moodHumor == 2) {
                return "#FF760F";
            } else if (diarySelected.moodHumor == 3) {
                return "#ECFF11";
            } else if (diarySelected.moodHumor == 4 || diarySelected.moodHumor == 5) {
                return "#05FD00";
            }
        } else {
            if (diarySelected.moodHumor == 1) {
                return "#910A07";
            } else if (diarySelected.moodHumor == 2) {
                return "#F5720F";
            } else if (diarySelected.moodHumor == 3) {
                return "#C5AC11";
            } else if (diarySelected.moodHumor == 4 || diarySelected.moodHumor == 5) {
                return "#03A800";
            }
        }

    }

    function amountWater(amount) {
        if (diarySelected.amountWater === amount) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <Modal
            open={statusModal}
            onClose={() => setStatusModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            {...rest}
        >
            <Paper className={classes.modalContainer}>
                <CardHeader
                    action={
                        <IconButton aria-label="closeModal" onClick={() => setStatusModal(false)}>
                            <CloseIcon />
                        </IconButton>
                    }
                />
                <Divider />
                <CardContent>
                    {!awaitData ? (
                        <>
                            {testData !== undefined && diarySelected !== undefined ? (
                                <Box p={2}>
                                    <Box mb={4}>
                                        <Typography variant="h3" align="center" gutterBottom>
                                            RESULTADO
                                        </Typography>
                                        <Typography variant="h4" align="center" gutterBottom>
                                            DIÁRIO
                                        </Typography>
                                    </Box>
                                    <Box className={classes.boxAllData}>

                                        <FormikSelect fullWidth formik={formik} options={getDiaryDate()} name="diaryDate" label="Data do diário" />

                                        <Typography variant="body1" gutterBottom alignSelf="flex-start" pt={2}>
                                            Como foi seu humor hoje?
                                        </Typography>

                                        <Box alignItems="center" justifyContent="center" fullWidth pt={0.5}>
                                            <Rating
                                                name="humor"
                                                value={diarySelected.moodHumor}
                                                readOnly
                                                IconContainerComponent={IconContainer}
                                                highlightSelectedOnly
                                                style={{ color: getHumorColor() }}
                                            />
                                        </Box>

                                        <Box pt={2} alignItems="center" width="50%" alignSelf="flex-start" justifyContent="space-between">
                                            <Typography variant="body1" gutterBottom>
                                                Quanto você bebeu de água hoje aproximadamente?
                                            </Typography>
                                            <FormControl >
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    name="radio-buttons-group"
                                                >
                                                    <FormControlLabel value="1" control={<Radio />} label="Menos de 1 litro" checked={amountWater("Menos de 1 litro")} />
                                                    <FormControlLabel value="2" control={<Radio />} label="1 litro" checked={amountWater("1 litro")} />
                                                    <FormControlLabel value="3" control={<Radio />} label="1,5 litros" checked={(amountWater("1,5 litros"))} />
                                                    <FormControlLabel value="4" control={<Radio />} label="2 litros" checked={(amountWater("2 litros"))} />
                                                    <FormControlLabel value="5" control={<Radio />} label="Mais de 2 litros" checked={(amountWater("Mais de 2 litros"))} />
                                                </RadioGroup>
                                            </FormControl>
                                        </Box>

                                        <Box pt={2} alignItems="center" width="100%" alignSelf="flex-start" justifyContent="space-between">
                                            <Typography variant="body1" gutterBottom>
                                                Quantos cigarros você fumou hoje?
                                            </Typography>
                                            <TextField
                                                value={diarySelected.cigarettes + " cigarros"}
                                                InputLabelProps={{
                                                    style: {
                                                        fontSize: "18px"
                                                    }
                                                }}

                                                variant="outlined"
                                                fullWidth
                                            />

                                        </Box>

                                        <Box pt={2} alignItems="center" width="50%" alignSelf="flex-start" justifyContent="space-between">
                                            <Typography variant="body1" gutterBottom>
                                                Realizou atividade física?
                                            </Typography>
                                            <FormControl >
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    name="radio-buttons-group"
                                                >
                                                    <FormControlLabel value="1" control={<Radio />} label="Sim" checked={diarySelected.physicalActivity} />
                                                    <FormControlLabel value="2" control={<Radio />} label="Não" checked={!diarySelected.physicalActivity} />
                                                </RadioGroup>
                                            </FormControl>
                                        </Box>

                                        <Box pt={2} alignItems="center" width="100%" alignSelf="flex-start" justifyContent="space-between">
                                            <Typography variant="body1" gutterBottom>
                                                Deseja fazer uma anotação? (Opcional)
                                            </Typography>
                                            <TextField
                                                value={diarySelected.message}
                                                InputLabelProps={{
                                                    style: {
                                                        fontSize: "18px"
                                                    }
                                                }}
                                                variant="outlined"
                                                fullWidth
                                            />
                                        </Box>

                                    </Box>
                                </Box>
                            ) : (
                                <Box mt={3} display="flex" justifyContent="center">
                                    <Typography variant="body1" gutterBottom>
                                        Nenhum dado encontrado.
                                    </Typography>
                                </Box>
                            )}
                        </>
                    ) : (
                        <Box mt={3} display="flex" justifyContent="center">
                            <CircularProgress />
                        </Box>
                    )}
                </CardContent>
            </Paper>
        </Modal>
    );
}

DiaryModal.propTypes = {
    className: PropTypes.string,
    form: PropTypes.string,
    testData: PropTypes.array,
    diarySelected: PropTypes.object,
    awaitData: PropTypes.bool.isRequired,
    statusModal: PropTypes.bool.isRequired,
    setStatusModal: PropTypes.func.isRequired,
}


export default DiaryModal;