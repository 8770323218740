import React from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {
    Box, Card, CardContent,
    Grid, Select, MenuItem, Typography,
    InputLabel, FormControl, CardHeader, Divider
} from '@mui/material';

import { FormikTextField } from 'components/formElements';

const useStyles = makeStyles(() => ({
    statusMarathon: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
    }
}));

function MarathonForm({ className, formik, isPremiumMarathon, setIsPremiumMarathon, awaitData, marathonData, idMarathon, ...rest }) {
    const classes = useStyles();

    return (
        <Card {...rest} className={clsx(classes.root, className)}>
            <CardHeader
                title={"Cadastrar maratona"}
                subheader={idMarathon === undefined ?
                    "Informe todos os dados para cadastrar uma maratona"
                    :
                    "Informe todos os dados para editar uma maratona"
                }
            />
            <Divider />
            <form onSubmit={formik.handleSubmit}>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item md={9} xs={8}>
                            <FormikTextField fullWidth formik={formik} name="title" label="Título" />
                        </Grid>
                        <Grid item md={3} xs={4}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel id="select-marathonIsPremium-label">Tipo de maratona</InputLabel>
                                <Select
                                    labelId="select-marathonIsPremium-label"
                                    id="select-marathonActive"
                                    value={isPremiumMarathon}
                                    onChange={(e) => setIsPremiumMarathon(e.target.value)}
                                    label="Status da maratona"
                                    fullWidth
                                >
                                    <MenuItem value={true}>Premium</MenuItem>
                                    <MenuItem value={false}>Grátis</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <FormikTextField
                                fullWidth
                                formik={formik}
                                name="description"
                                label="Descrição"
                                multiline
                                rows={3}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider />

                <Box display="flex" justifyContent={idMarathon === undefined ? "flex-end" : "space-between"} p={2}>
                    {idMarathon &&
                        <Grid container spacing={2}>
                            <Grid item md={12} xs={12}>
                                <Typography variant="body2" color="text.secondary">
                                    Criado em {marathonData?.createdAt ? moment(marathonData?.createdAt).format('DD/MM/YYYY') : "--/--/----"}
                                </Typography>
                                <div className={classes.statusMarathon}>
                                    <Typography
                                        color="textSecondary"
                                        display="inline"
                                        variant="body2"
                                    >
                                        Status:
                                    </Typography>
                                    <FiberManualRecordIcon
                                        className={classes.statsIcon}
                                        sx={{ color: marathonData?.isActive ? 'green' : 'red' }}
                                    />
                                    <Typography
                                        color="textSecondary"
                                        display="inline"
                                        variant="body2"
                                    >
                                        {marathonData?.isActive ? 'Ativo' : 'Inativo'}
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    }
                    <LoadingButton
                        type="submit"
                        color="primary"
                        variant="contained"
                        loading={awaitData}
                    >
                        {idMarathon === undefined ? "Cadastrar" : "Editar"}
                    </LoadingButton>
                </Box>
            </form>
        </Card>
    );
}

MarathonForm.propTypes = {
    className: PropTypes.string,
    isPremiumMarathon: PropTypes.bool.isRequired,
    setIsPremiumMarathon: PropTypes.func,
    formik: PropTypes.object.isRequired,
    awaitData: PropTypes.bool.isRequired,
}

export default MarathonForm;