import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import TopicIcon from '@mui/icons-material/Topic';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { NavLink as RouterLink } from 'react-router-dom';
import {
    Box, Card,
    CardContent, Divider, Grid,
    Typography, CardHeader,
    IconButton, Menu, MenuItem
} from '@mui/material';

import stringRedutor from 'modules/utils/stringRedutor';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    statsItem: {
        alignItems: 'center',
        display: 'flex'
    },
    statsIcon: {
        marginRight: theme.spacing(1)
    },
    iFrameSize: {
        height: '100%',
        width: '100%',
    }
}));

function NotificationCard({ className, notification, ...rest }) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    function handleClick(currentTarget) {
        setAnchorEl(currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    function getTopicName(topic) {
        if (notification.topic.includes('PREMIUM')) {
            return 'Usuários Premium';
        } else if (notification.topic.includes('FREE')) {
            return 'Usuários Grátis';
        } else {
            return `Usuário ${topic?.split("U_")[1] || '-'}`
        }
    }

    return (
        <Card className={clsx(classes.root, className)} {...rest}>
            <CardHeader
                title={stringRedutor(notification.title, 25)}
                action={
                    <Box>
                        <IconButton aria-label="settings" onClick={(e) => handleClick(e.currentTarget)} >
                            <MoreVertIcon />
                        </IconButton>

                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                        >
                            <MenuItem
                                component={RouterLink}
                                to={`/notificacoes/${notification.idNotification}/duplicar`}
                            >
                                Duplicar notificação
                            </MenuItem>
                        </Menu>
                    </Box>
                }
            />
            <Box flexGrow={1} />
            <Divider />
            <CardContent>
                <Typography
                    align="center"
                    color="textPrimary"
                    variant="body1"
                >
                    {stringRedutor(notification.body, 50)}
                </Typography>
            </CardContent>
            <Box flexGrow={1} />
            <Divider />
            <Box p={2}>
                <Grid
                    container
                    justifyContent="space-between"
                    spacing={2}
                >
                    <Grid
                        className={classes.statsItem}
                        item
                    >
                        <TopicIcon
                            className={classes.statsIcon}
                            color="action"
                        />
                        <Typography
                            color="textSecondary"
                            display="inline"
                            variant="body2"
                        >
                            Enviado a: {getTopicName(notification.topic)}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Card>
    )
}

NotificationCard.propTypes = {
    className: PropTypes.string,
    notification: PropTypes.object.isRequired,
};

export default NotificationCard;