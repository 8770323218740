import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography,
  colors
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import DirectionsRun from '@mui/icons-material/DirectionsRun';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.orange[600],
    height: 56,
    width: 56
  }
}));

function Marathon({ className, amountItens, awaitData, ...rest }) {
  const classes = useStyles();
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item>
            <Typography color="textSecondary" variant="h6" gutterBottom>
              MARATONAS
            </Typography>
            {!awaitData && (
              <Typography color="textPrimary" variant="h3">
                {amountItens?.amountChallengeMarathon}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <DirectionsRun />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

Marathon.propTypes = {
  className: PropTypes.string,
  amountItens: PropTypes.object,
  awaitData: PropTypes.bool
};

export default Marathon;
