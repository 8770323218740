import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Pagination } from '@mui/lab';
import {
	Box, Container, Grid,
	Typography, CircularProgress
} from '@mui/material';

import RequestUtils from 'modules/api/RequestUtils';
import useSnackbar from 'hooks/useSnackbar';
import { GroupCard, Toolbar } from './components';
import Page from 'components/Page';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		minHeight: '100%',
		paddingBottom: theme.spacing(3),
		paddingTop: theme.spacing(3)
	},
	groupCard: {
		height: '100%'
	}
}));

function GroupsList() {
	const classes = useStyles();
	const snackbar = useSnackbar();
	const [awaitData, setAwaitingData] = useState(false);
	const [arrayGroups, setArrayGroups] = useState([]);
	const [inputValue, setInputValue] = useState('');
	const [pagedObject, setPagedObject] = useState({
		searchTerm: '',
		page: 1,
		itensPerPage: 12,
		groupActive: true,
		amountPage: 1
	});

	function componentMount() {
		handlePagedGroups(pagedObject);
	}
	useEffect(componentMount, []);

	async function handlePagedGroups(pagedObject) {
		setAwaitingData(true);
		RequestUtils.getGroupsPaged(
			pagedObject.searchTerm,
			pagedObject.page,
			pagedObject.itensPerPage,
			pagedObject.groupActive
		).then((res) => {
			if (res?.svStatus && res.data) {
				const data = res.data;
				setPagedObject({ ...pagedObject, amountPage: data.pageCount });
				setArrayGroups(data.listGroups);
			} else {
				snackbar(res.msg || "Não foi possível listar grupos.").error();
			}
			setAwaitingData(false);
		}).catch((e) => {
			snackbar("Erro ao listar grupos.").error();
			setAwaitingData(false);
		});
	}

	function onPressButton() {
		const obj = { ...pagedObject, page: 1, searchTerm: inputValue };
		setPagedObject(obj);
		handlePagedGroups(obj);
	}

	async function updateStatusGroup(idGroupUpdate) {
		setAwaitingData(true);
		RequestUtils.updateStatusGroup(idGroupUpdate).then((res) => {
			if (res?.svStatus && res.data) {
				let arr = arrayGroups.filter((group) => {
					if (group?.idGroup != idGroupUpdate) return group;
				});
				setArrayGroups(arr);
				snackbar("Status do grupo atualizado com sucesso.").success();
			} else {
				snackbar(res.msg || "Não foi possível atualizar status do grupo.").error();
			}
			setAwaitingData(false);
		}).catch((e) => {
			snackbar("Erro ao atualizar status do grupo.").error();
			setAwaitingData(false);
		});
	}

	function handleChange(label, value) {
		let obj = pagedObject;

		switch (label) {
			case "searchTerm":
				setInputValue(value);
				if (value === "") obj = { ...obj, page: 1, searchTerm: value };
				break;
			case "groupActive":
				obj = { ...obj, page: 1, groupActive: value };
				break;
			default:
				break;
		}

		setPagedObject(obj);
		if (label !== "searchTerm" || value === "") handlePagedGroups(obj);
	}

	function handleChangePage(event, value) {
		const obj = { ...pagedObject, page: value };
		setPagedObject(obj);
		handlePagedGroups(obj);
	};

	return (
		<Page className={classes.root} title="Grupos">
			<Container maxWidth={false}>
				<Toolbar
					inputValue={inputValue}
					pagedObject={pagedObject}
					onPressButton={onPressButton}
					handleChange={handleChange}
				/>
				{!awaitData ? (
					<>
						{arrayGroups.length > 0 ? (
							<>
								<Box mt={3}>
									<Grid
										container
										spacing={3}
									>
										{arrayGroups?.map((group) => (
											<Grid
												item
												key={group.idGroup}
												lg={4}
												md={6}
												xs={12}
											>
												<GroupCard
													className={classes.groupCard}
													group={group}
													updateStatusGroup={updateStatusGroup}
												/>
											</Grid>
										))}
									</Grid>
								</Box>
								<Box mt={3} display="flex" justifyContent="center">
									<Pagination
										color="primary"
										size="small"
										count={pagedObject.amountPage}
										page={pagedObject.page}
										onChange={handleChangePage}
									/>
								</Box>
							</>
						) : (
							<Box mt={3} display="flex" justifyContent="center">
								<Typography variant="body1" gutterBottom color="primary">
									Nenhum item encontrado.
								</Typography>
							</Box>
						)}
					</>
				) : (
					<Box mt={3} display="flex" justifyContent="center">
						<CircularProgress />
					</Box>
				)}
			</Container>
		</Page>
	);
};

export default GroupsList;