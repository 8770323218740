import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import { Search as SearchIcon } from '@mui/icons-material';
import {
    Card, TextField, InputAdornment,
    Grid, Select, MenuItem,
    InputLabel, FormControl, Modal,
    Pagination, CardContent, Box,
    Typography, Avatar, Paper
} from '@mui/material';

const useStyles = makeStyles(() => ({
    modalContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        boxShadow: 24,
        padding: 10,
    },
    containerAvatar: {
        marginRight: 15
    },
    containerUsers: {
        marginTop: '10px'
    },
    gridItem: {
        display: 'flex',
        alignItems: 'center',
        padding: 5,
        cursor: 'pointer'
    },
}));

function AddChallengeMemberModal({
    className,
    addMember,
    statusModal,
    setStatusModal,
    handleChange,
    pagedObject,
    inputValue,
    onPressButton,
    handleChangePage,
    arrUsers,
    ...rest
}) {
    const classes = useStyles();

    return (
        <Modal
            open={statusModal}
            onClose={() => setStatusModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            {...rest}
        >
            <Card className={classes.modalContainer}>
                <CardContent>
                    <Grid container spacing={1}>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <LoadingButton variant="contained" onClick={onPressButton}>
                                                <SearchIcon fontSize="small" color="white" />
                                            </LoadingButton>
                                        </InputAdornment>
                                    )
                                }}
                                placeholder={"Buscar por usuário"}
                                variant="outlined"
                                value={inputValue}
                                onChange={(e) => handleChange("searchTerm", e.target.value)}
                            />
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel id="select-userPlan-label">Plano do usuário</InputLabel>
                                <Select
                                    labelId="select-userPlan-label"
                                    id="select-userPlan"
                                    value={pagedObject.userPlan}
                                    label="Plano do usuário"
                                    onChange={(e) => handleChange("userPlan", e.target.value)}
                                    fullWidth
                                >
                                    <MenuItem value={0}>Grátis</MenuItem>
                                    <MenuItem value={1}>Premium</MenuItem>
                                    <MenuItem value={2}>Todos</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel id="select-userType-label">Tipo do usuário</InputLabel>
                                <Select
                                    labelId="select-userType-label"
                                    id="select-userType"
                                    value={pagedObject.userType}
                                    label="Tipo do usuário"
                                    onChange={(e) => handleChange("userType", e.target.value)}
                                    fullWidth
                                >
                                    <MenuItem value={0}>Comum</MenuItem>
                                    <MenuItem value={1}>Administrador</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Box mt={2}>
                        <Grid container spacing={1}>
                            {arrUsers.map((user, idx) => (
                                <Grid key={idx} item md={6} xs={12} onClick={() => addMember(user.idUser)}>
                                    <Paper elevation={3} className={classes.gridItem}>
                                        <Avatar className={classes.containerAvatar}>
                                            {user?.name[0]}
                                        </Avatar>
                                        <Typography
                                            color="textSecondary"
                                            display="inline"
                                            variant="body2"
                                        >
                                            <b>{user?.name}</b>
                                        </Typography>
                                    </Paper>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>

                    <Box mt={2} display="flex" justifyContent="center">
                        <Pagination
                            color="primary"
                            size="small"
                            count={pagedObject.amountPage}
                            page={pagedObject.page}
                            onChange={handleChangePage}
                        />
                    </Box>
                </CardContent>
            </Card>
        </Modal>
    )
}

AddChallengeMemberModal.propTypes = {
    className: PropTypes.string,
    addMember: PropTypes.func.isRequired,
    statusModal: PropTypes.bool.isRequired,
    setStatusModal: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    pagedObject: PropTypes.object.isRequired,
    inputValue: PropTypes.string.isRequired,
    onPressButton: PropTypes.func.isRequired,
    handleChangePage: PropTypes.func.isRequired,
    arrUsers: PropTypes.array.isRequired
}

export default AddChallengeMemberModal;