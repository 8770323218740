import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { format as formatDate } from 'date-fns';
import {
	Avatar,
	Box,
	Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { getInitials } from 'modules/utils/mask';
import EnhancedTable from 'components/EnhancedTable';

const useStyles = makeStyles((theme) => ({
	root: {},
	avatar: {
		marginRight: theme.spacing(2)
	}
}));

const Results = ({ className, customers, ...rest }) => {
	const classes = useStyles();
	/* const [selectedCustomerIds, setSelectedCustomerIds] = useState([]); */
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [page, setPage] = useState(0);

	function handleSelectionChange(newSelectedCustomerIds) {
		/* setSelectedCustomerIds(newSelectedCustomerIds); */
	}

	function handleRowsPerPageChange(event) {
		setRowsPerPage(parseInt(event.target.value, 10));
	}

	function handlePageChange(event, newPage) {
		setPage(newPage);
	}

	return (
		<div className={clsx(classes.root, className)} {...rest}>
			<EnhancedTable
				page={page}
				onChangePage={handlePageChange}
				rowsPerPageOptions={[5, 10, 25]}
				rowsPerPage={rowsPerPage}
				onChangeRowsPerPage={handleRowsPerPageChange}
				checkboxSelection
				disableSelectionOnClick={false}
				onSelectionChange={handleSelectionChange}
				rows={customers}
				columns={[
					{
						id: "name",
						width: 250,
						headerName: "Name",
						renderCell: ({ item }) => (
							<Box alignItems="center" display="flex">
								<Avatar
									className={classes.avatar}
									src={item.avatarUrl}
								>
									{getInitials(item.name)}
								</Avatar>
								<Typography color="textPrimary" variant="body1" >
									{item.name}
								</Typography>
							</Box>
						)
					},
					{
						id: 'email',
						headerName: "Email",
						width: 250,
					},
					{
						id: 'address',
						width: 200,
						headerName: "Location",
						renderCell: ({ item }) => `${item.address.city}, ${item.address.state}, ${item.address.country}`
					},
					{
						id: 'phone',
						width: 150,
						headerName: "Phone",
					},
					{
						id: 'createdAt',
						width: 150,
						headerName: "Registration date",
						renderCell: ({ item }) => formatDate(item.createdAt, 'dd/MM/yyyy')
					},
				]}
			/>
		</div>
	);
};

Results.propTypes = {
	className: PropTypes.string,
	customers: PropTypes.array.isRequired
};

export default Results;
