import React from 'react';
import PropTypes from "prop-types";
import { ptBR } from 'date-fns/locale'
import { TextField } from '@mui/material';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

const propTypes = {
	inputFormat: PropTypes.string,
	value: PropTypes.any,
	onChange: PropTypes.func,
	fullWidth: PropTypes.bool,
	disabled: PropTypes.bool,
	variant: PropTypes.string,
	margin: PropTypes.string,
	classes: PropTypes.object,
	color: PropTypes.string,
	size: PropTypes.string,
	sx: PropTypes.object,
};

const defaultProps = {
	inputFormat: "dd/MM/yyyy",
};

function FormikDatepicker(props) {
	const { name, formik, fullWidth, disabled, variant, margin, placeholder, classes, color, size, sx, ...rest } = props;

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
			<DatePicker
				{...rest}
				id={name}
				name={name}
				value={formik.values[name]}
				onChange={(date) => formik.setFieldValue(name, date)}
				renderInput={(params) => (
					<TextField
						{...params}
						fullWidth={fullWidth}
						disabled={disabled}
						variant={variant}
						margin={margin}
						placeholder={placeholder}
						classes={classes}
						color={color}
						size={size}
						sx={sx}
						error={formik.touched[name] && Boolean(formik.errors[name])}
						helperText={formik.touched[name] && formik.errors[name]}
					/>
				)}
			/>
		</LocalizationProvider>
	);
}

FormikDatepicker.propTypes = propTypes;
FormikDatepicker.defaultProps = defaultProps;

export default FormikDatepicker;