import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import {
    Card, Modal, Box,
    CardContent, Typography,
    CircularProgress,
    CardHeader, IconButton, Divider
} from '@mui/material';

const useStyles = makeStyles(() => ({
    modalContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 650,
        boxShadow: 24,
    },
}));

function SmokingLoadModal({ className, testData, awaitData, statusModal, setStatusModal, ...rest }) {
    const classes = useStyles();

    const packPerDay = testData?.cigarettesPerDay / 20;

    let smokingLoad = packPerDay * testData?.smokingAge;
    smokingLoad = !Number.isInteger(smokingLoad) ? smokingLoad.toFixed(1) : smokingLoad;

    const moneyPerMonth = formatReal(`${(packPerDay * testData?.packPrice * 30).toFixed(2)}`);
    const moneyPerYear = formatReal(`${(packPerDay * testData?.packPrice * 30 * 12).toFixed(2)}`);
    const moneyPerLifeValue = packPerDay * testData?.packPrice * 30 * 12 * testData?.smokingAge;
    const moneyPerLife = formatReal(`${(moneyPerLifeValue).toFixed(2)}`);

    let percentageValue = 0;
    let savings = 0;
    for (let i = 1; i < (12 * testData?.smokingAge); i++){
        percentageValue = (percentageValue + (packPerDay * testData?.packPrice * 30)) * 1.01;
        savings = (savings + (packPerDay * testData?.packPrice * 30)) * 1.00650;
    }
    percentageValue += packPerDay * testData?.packPrice * 30;
    savings += packPerDay * testData?.packPrice * 30;    

    const savingsAccount = formatReal(`${savings.toFixed(2)}`);
    const savingsPrecentage = formatReal(`${percentageValue.toFixed(2)}`);

    const cigarettesPerMonth = packPerDay * 20 * 30;
    const cigarettesPerYear = packPerDay * 20 * 30 * 12;
    const cigarettesPerLife = cigarettesPerYear * testData?.smokingAge;

    function formatReal(str) {
        const number = parseInt(str.replace(/[\D]+/g, ''));

        let tmp = number + '';
        tmp = tmp.replace(/([0-9]{2})$/g, ",$1");
        if (tmp.length > 6)
            tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

        return tmp;
    }

    return (
        <Modal
            open={statusModal}
            onClose={() => setStatusModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            {...rest}
        >
            <Card className={classes.modalContainer}>
                <CardHeader
                    action={
                        <IconButton aria-label="closeModal" onClick={() => setStatusModal(false)}>
                            <CloseIcon />
                        </IconButton>
                    }
                />
                <Divider />
                <CardContent>
                    {!awaitData ? (
                        <>
                            {testData !== undefined ? (
                                <Box p={2}>
                                    <Box mb={4}>
                                        <Typography variant="h3" align="center" gutterBottom>
                                            RESULTADO
                                        </Typography>
                                        <Typography variant="h4" align="center" gutterBottom>
                                            TESTE DE CARGA TABÁGICA
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant="body1" gutterBottom>
                                            Carga tabágica: <b> {smokingLoad} maços-ano </b>
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            Você gasta:
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            Por mês:  <b>R$ {moneyPerMonth} </b>
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            Por Ano:  <b>R$ {moneyPerYear} </b>
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            Você já gastou o total de: <b>R$ {moneyPerLife} </b>
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            Quantidade de cigarros que você já fumou:
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            Por mês:  <b>{cigarettesPerMonth} cigarros</b>
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            Por Ano:  <b>{cigarettesPerYear} cigarros</b>
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            Durante toda sua vida: <b>{cigarettesPerLife} cigarros</b>
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            Se tivesse aplicado na poupança, você teria: <b>R$ {savingsAccount}</b>
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            Se tivesse aplicado com rendimentos de 1% ao mês, você teria: <b>R$ {savingsPrecentage}</b>
                                        </Typography>
                                    </Box>
                                </Box>
                            ) : (
                                <Box mt={3} display="flex" justifyContent="center">
                                    <Typography variant="body1" gutterBottom>
                                        Nenhum dado encontrado.
                                    </Typography>
                                </Box>
                            )}
                        </>
                    ) : (
                        <Box mt={3} display="flex" justifyContent="center">
                            <CircularProgress />
                        </Box>
                    )}
                </CardContent>
            </Card>
        </Modal>
    )
}

SmokingLoadModal.propTypes = {
    className: PropTypes.string,
    testData: PropTypes.object,
    awaitData: PropTypes.bool.isRequired,
    statusModal: PropTypes.bool.isRequired,
    setStatusModal: PropTypes.func.isRequired,
}

export default SmokingLoadModal;