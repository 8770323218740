import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import {
    Box, Container, Grid,
    Pagination, Typography,
    CircularProgress
} from '@mui/material';

import RequestUtils from 'modules/api/RequestUtils';
import useSnackbar from 'hooks/useSnackbar';
import Page from 'components/Page';
import { UserCard, Toolbar, UserDeleteModal } from './components';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    userCard: {
        height: '100%',
    }
}));

function ListUsers() {
    const classes = useStyles();
    const snackbar = useSnackbar();
    const [awaitData, setAwaitingData] = useState(false);
    const [arrayUsers, setArrayUsers] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [userDelete, setUserDelete] = useState({idUser: 0, active: false});
    const [pagedObject, setPagedObject] = useState({
        searchTerm: '',
        page: 1,
        itensPerPage: 12,
        userPlan: 2,
        userType: 0,
        userActive: true,
        amountPage: 1
    });

    function componentMount() {
        handlePagedUsers(pagedObject);
    }
    useEffect(componentMount, []);

    async function handlePagedUsers(pagedObject) {
        setAwaitingData(true);

        const plan = pagedObject.userPlan == 2 ? null : !!pagedObject.userPlan;

        RequestUtils.getUsersPaged(
            pagedObject.searchTerm,
            pagedObject.page,
            pagedObject.itensPerPage,
            pagedObject.userType,
            pagedObject.userActive,
            plan,
        ).then((res) => {
            if (res?.svStatus && res.data) {
                const data = res.data;
                setPagedObject({ ...pagedObject, amountPage: data.pageCount });
                setArrayUsers(data.listUser);
            } else {
                snackbar(res.msg || "Não foi possível listar usuários.").error();
            }
            setAwaitingData(false);
        }).catch((e) => {
            snackbar("Erro ao listar usuários.").error();
            setAwaitingData(false);
        });
    }

    function onPressButton() {
        const obj = { ...pagedObject, page: 1, searchTerm: inputValue };
        setPagedObject(obj);
        handlePagedUsers(obj);
    }

    async function updateStatusUser(idUserUpdate) {
        setAwaitingData(true);
        RequestUtils.updateStatusUser(idUserUpdate).then((res) => {
            if (res?.svStatus && res.data) {
                let arr = arrayUsers.filter((user) => {
                    if (user?.idUser != idUserUpdate) return user;
                });
                setArrayUsers(arr);
                snackbar("Status do usuário atualizado com sucesso.").success();
            } else {
                snackbar(res.msg || "Não foi possível atualizar status do usuário.").error();
            }
            setAwaitingData(false);
        }).catch((e) => {
            snackbar("Erro ao atualizar status do usuário.").error();
            setAwaitingData(false);
        });
    }
    
    function deleteUser(idUser){
        setAwaitingData(true);
        RequestUtils.logicalDeleteUser(idUser).then((res) => {
            if (res?.svStatus && res.data) {
                let arr = arrayUsers.filter((user) => {
                    if (user?.idUser != idUser) return user;
                });
                setArrayUsers(arr);
                snackbar("Usuário deletado com sucesso.").success();
            } else {
                snackbar(res.msg || "Erro ao deletar usuário.").error();
            }
            setAwaitingData(false);
        }).catch((e) => {
            snackbar("Erro ao deletar usuário.").error();
            setAwaitingData(false);
        })
    }

    function handleChange(label, value) {
        let obj = pagedObject;

        switch (label) {
            case "searchTerm":
                setInputValue(value);
                if (value === "") obj = { ...obj, page: 1, searchTerm: value };
                break;
            case "userActive":
                obj = { ...obj, page: 1, userActive: value };
                break;
            case "userType":
                obj = { ...obj, page: 1, userType: value };
                break;
            case "userPlan":
                obj = { ...obj, page: 1, userPlan: value };
                break;
            default:
                break;
        }

        setPagedObject(obj);
        if (label !== "searchTerm" || value === "") handlePagedUsers(obj);
    }

    function handleChangePage(event, value) {
        const obj = { ...pagedObject, page: value };
        setPagedObject(obj);
        handlePagedUsers(obj);
    };

    return (
        <Page className={classes.root} title="Usuários">
            <Container maxWidth={false}>
                <Toolbar
                    inputValue={inputValue}
                    pagedObject={pagedObject}
                    onPressButton={onPressButton}
                    handleChange={handleChange}
                />
                
                <Grid item lg={10} md={10} xs={12}>
                    <UserDeleteModal
                        statusModal={userDelete.active}
                        idToDelete={userDelete.idUser}
                        setStatusModal={setUserDelete}
                        deleteUser={deleteUser}
                    />
                </Grid>
                {!awaitData ? (
                    <>
                        {arrayUsers.length > 0 ? (
                            <>
                                <Box mt={3}>
                                    <Grid container spacing={3}>
                                        {arrayUsers.map((user) => (
                                            <Grid
                                                item key={user.idUser}
                                                xs={12} md={6} lg={4}
                                            >
                                                <UserCard
                                                    className={classes.userCard}
                                                    user={user}
                                                    setUserDelete={setUserDelete}
                                                    updateStatusUser={updateStatusUser}
                                                    deleteUser={deleteUser}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>
                                <Box mt={3} display="flex" justifyContent="center">
                                    <Pagination
                                        color="primary"
                                        size="small"
                                        count={pagedObject.amountPage}
                                        page={pagedObject.page}
                                        onChange={handleChangePage}
                                    />
                                </Box>
                            </>
                        ) : (
                            <Box mt={3} display="flex" justifyContent="center">
                                <Typography variant="body1" gutterBottom color="primary">
                                    Nenhum item encontrado.
                                </Typography>
                            </Box>
                        )}
                    </>
                ) : (
                    <Box mt={3} display="flex" justifyContent="center">
                        <CircularProgress />
                    </Box>
                )}
            </Container>
        </Page>
    );
}

export default ListUsers;