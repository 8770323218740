import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';
import { Container, Grid } from '@mui/material';

import useArray from 'hooks/useArray';
import useSnackbar from 'hooks/useSnackbar';
import useLocalStorage from 'hooks/useLocalStorage';
import RequestUtils from 'modules/api/RequestUtils';

import Page from 'components/Page';
import { ChatRender } from './components';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

function ForumChats() {
    const classes = useStyles();
    const snackbar = useSnackbar();
    const { idForum } = useParams();
    const arrMessages = useArray([]);
    const [currentUser] = useLocalStorage('currentUser');
    const [forumData, setForumData] = useState();
    const [awaitData, setAwaitingData] = useState(false);
    const [awaitDataMessage, setAwaitingDataMessage] = useState(false);
    const [awaitDataAddMessage, setAwaitingDataAddMessage] = useState(false);
    const [pagedObject, setPagedObject] = useState({
        page: 1,
        itensPerPage: 10,
        amountPage: 1
    });
    const scrollToEnd = useRef();

    function componentMount() {
        if (!idForum) return;
        arrMessages.setValue([]);
        getForumData();
        getForumMessageById(pagedObject.page, pagedObject.itensPerPage);
    }
    useEffect(componentMount, []);

    function getForumData() {
        setAwaitingData(true);
        RequestUtils.getForumById(idForum).then((res) => {
            if (res?.svStatus && res.data) {
                setForumData(res.data);
            } else {
                snackbar(res.msg || "Não foi possível buscar dados do fórum.").error();
            }
            setAwaitingData(false);
        }).catch((e) => {
            snackbar("Erro ao buscar dados do fórum.").error();
            setAwaitingData(false);
        });
    }

    function getNewMessages() {
        setAwaitingDataAddMessage(true);
        RequestUtils.getMessageByIdForum(idForum, '', 1, 10).then((res) => {
            if (res?.svStatus && res.data) {
                const listMessages = res.data?.listForumMessages || [];
                arrMessages.setValue([...listMessages.reverse()]);
                setPagedObject({ ...pagedObject, page: res.data.page, amountPage: res.data.pageCount });
            } else {
                snackbar(res.msg || "Não foi possível buscar as mensagens do fórum.").error();
            }
            setAwaitingDataAddMessage(false);
        }).catch((e) => {
            snackbar("Erro ao buscar as mensagens do fórum.").error();
            setAwaitingDataAddMessage(false);
        });
    }

    function getForumMessageById(page, itensPerPage) {
        setAwaitingDataAddMessage(true);
        RequestUtils.getMessageByIdForum(idForum, '', page, itensPerPage).then((res) => {
            if (res?.svStatus && res.data) {
                const listMessages = res.data?.listForumMessages || [];
                arrMessages.setValue([...listMessages.reverse(), ...arrMessages.value]);
                setPagedObject({ ...pagedObject, page: res.data.page, amountPage: res.data.pageCount });
                setTimeout(() => {
                    scrollToEnd.current.scrollIntoView({ behavior: "smooth" });
                }, 100);    
            } else {
                snackbar(res.msg || "Não foi possível buscar as mensagens do fórum.").error();
            }
            setAwaitingDataAddMessage(false);
        }).catch((e) => {
            snackbar("Erro ao buscar as mensagens do fórum.").error();
            setAwaitingDataAddMessage(false);
        });
    }

    function createReaction(messageData) {
        if (!currentUser.idUser) return;

        const dto = {
            idForumMessage: Number(messageData.idForumMessage),
            idUser: Number(currentUser.idUser),
            type: 1,
        }

        RequestUtils.addForumReaction(dto).then((res) => {
            if (res?.svStatus && res.data) {
                let arr = arrMessages.value;
                const index = arr?.findIndex((message) => message.idForumMessage == messageData.idForumMessage);
                if (index >= 0) {
                    if (arr[index]?.tblForumReaction) {
                        arr[index]?.tblForumReaction?.push(res.data);
                    } else {
                        arr[index].tblForumReaction = [res.data]
                    }
                }
                arrMessages.setValue([...arr]);
            } else {
                snackbar(res.msg || "Não foi possível curtir a mensagem do fórum.").error();
            }
        }).catch((e) => {
            snackbar("Erro ao curtir a mensagem do fórum.").error();
        });
    }

    function deleteReaction(messageData) {
        if (!currentUser.idUser) return;
        if (messageData.tblForumReaction.length == 0) return;

        const reactionObj = messageData.tblForumReaction.find((reaction) => reaction.idUser == currentUser.idUser);

        if (!reactionObj) return;

        RequestUtils.deleteForumReaction(reactionObj.idForumReaction).then((res) => {
            if (res?.svStatus && res.data) {
                let arr = arrMessages.value;
                const index = arr.findIndex((message) => message.idForumMessage == messageData.idForumMessage);
                if (index >= 0) {
                    arr[index].tblForumReaction = arr[index].tblForumReaction.filter((reaction) => reaction.idForumReaction != reactionObj.idForumReaction);
                }
                arrMessages.setValue([...arr]);
            } else {
                snackbar(res.msg || "Não foi possível remover o curtir da mensagem do fórum.").error();
            }
        }).catch((e) => {
            snackbar("Erro ao remover o curtir da mensagem do fórum.").error();
        });
    }

    function messageReaction(isLike, message) {
        if (isLike) {
            deleteReaction(message);
        } else {
            createReaction(message);
        }
    }

    function getPagedMessages() {
        const sumPage = pagedObject.page + 1;
        if (!(sumPage > pagedObject.amountPage)) {
            getForumMessageById(sumPage, pagedObject.itensPerPage);
        }
    }

    function sendMessage(comment, messageType, reply) {
        if (!currentUser) return;
        if (!idForum) return;
        if (!comment) return;

        const newDtoMessage = {
            replyTo: reply,
            idUser: Number(currentUser.idUser),
            messageType: messageType,
            date: moment().format(),
            comment: comment,
            idForum: Number(idForum),
            repliesList: [],
        }

        setAwaitingDataMessage(true);
        RequestUtils.addForumMessage(newDtoMessage).then((res) => {
            if (res?.svStatus && res.data) {
                res.data.name = currentUser.name;
                if (reply != null) {
                    let arr = arrMessages.value;
                    const index = arr.findIndex((item) => item.idForumMessage == reply);
                    if (arr[index].repliesList) {
                        arr[index].repliesList.push(res.data);
                    } else {
                        arr[index].repliesList = [res.data];
                    }
                    arrMessages.setValue(arr);
                } else {
                    arrMessages.push(res.data);
                    setTimeout(() => {
                        scrollToEnd.current.scrollIntoView({ behavior: "smooth" });
                    }, 100);
                }
            } else {
                snackbar(res.msg || "Não foi possível enviar a novas mensagens.").error();
            }
            setAwaitingDataMessage(false);
        }).catch((e) => {
            snackbar("Erro ao enviar a novas mensagens.").error();
            setAwaitingDataMessage(false);
        });
    }

    function deleteMessage(idForumMessage, reply) {
        setAwaitingDataMessage(true);
        RequestUtils.deleteForumMessage(idForumMessage).then((res) => {
            if (res?.svStatus && res.data) {
                if (reply != null) {
                    let arr = arrMessages.value;
                    const index = arr.findIndex((item) => item.idForumMessage == reply);
                    let arrReplys = arr[index].repliesList.filter((message) => message.idForumMessage != idForumMessage);
                    arr[index].repliesList = arrReplys;
                    arrMessages.setValue(arr);
                } else {
                    let arr = arrMessages.value.filter((message) => message.idForumMessage != idForumMessage);
                    arrMessages.setValue(arr);
                }
                snackbar("Mensagem deletada com sucesso do fórum.").success();
            } else {
                snackbar(res.msg || "Não foi possível deletar a mensagens do fórum.").error();
            }
            setAwaitingDataMessage(false);
        }).catch((e) => {
            snackbar("Erro ao deletar a mensagens do fórum.").error();
            setAwaitingDataMessage(false);
        });
    }

    return (
        <Page className={classes.root} title={"Mensagens do fórum"}>
            <Container maxWidth="lg">
                <Grid container justifyContent="center" spacing={3}>
                    <Grid item lg={12} md={12} xs={12}>
                        <ChatRender
                            messages={arrMessages.value}
                            awaitData={awaitData || awaitDataAddMessage}
                            idUser={currentUser.idUser}
                            awaitDataMessage={awaitDataMessage}
                            sendMessage={sendMessage}
                            forumData={forumData}
                            deleteMessage={deleteMessage}
                            getPagedMessages={getPagedMessages}
                            awaitDataAddMessage={awaitDataAddMessage}
                            pagedObject={pagedObject}
                            messageReaction={messageReaction}
                            refresh={getNewMessages}
                            scrollToEnd={scrollToEnd}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}

export default ForumChats;