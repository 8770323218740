import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';
import { Container, Grid } from '@mui/material';

import Page from 'components/Page';
import useArray from 'hooks/useArray';
import { ChatRender } from './components';
import useSnackbar from 'hooks/useSnackbar';
import useLocalStorage from 'hooks/useLocalStorage';
import RequestUtils from 'modules/api/RequestUtils';
import ChatHub from 'modules/utils/chatHub';
import { encode } from 'modules/utils/encryption';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

function GroupChat() {
    const classes = useStyles();
    const snackbar = useSnackbar();
    const { idGroup } = useParams();
    const arrMessages = useArray([]);
    const [currentUser] = useLocalStorage('currentUser');
    const [groupData, setGroupData] = useState();
    const [closeChat, setCloseChat] = useState(false);
    const [messageListener, setMessageListener] = useState();
    const [deleteMessageListener, setDeleteMessageListener] = useState();
    const [awaitData, setAwaitingData] = useState(false);
    const [awaitDataGroup, setAwaitingDataGroup] = useState(false);
    const [text, setText] = useState("");
    const [addMemberLoading, setAddMemberLoading] = useState(false);
    const [groupMember, setGroupMember] = useState(false);
    const [awaitDataMessage, setAwaitingDataMessage] = useState(false);
    const [awaitDataAddMessage, setAwaitingDataAddMessage] = useState(false);
    const [pagedObject, setPagedObject] = useState({
        page: 1,
        itensPerPage: 10,
        searchTerm: '',
        amountPage: 1
    });
    const scrollToEnd = useRef();

    useEffect(() => {
        componentMount();
        return () => {
            setCloseChat(true);
            ChatHub.unsubscribe(ChatHub.events.onGroupEvent);
            ChatHub.unsubscribe(ChatHub.events.onDeleteMessage);
            ChatHub.stop();
        };
    }, [])

    useEffect(() => {
        if (!messageListener) return;
        receivedMessage(messageListener);
    }, [messageListener])

    useEffect(() => {
        if (!deleteMessageListener) return;
        arrMessages.value.forEach((com, index) => {
            if (com.idGroupMessage === deleteMessageListener.idGroupMessage) {
                arrMessages.remove(index);
            }
        })

    }, [deleteMessageListener])

    useEffect(() => {
        if (!groupMember) return;
        hubStart(currentUser.idUser);
    }, [groupMember])

    function componentMount() {
        getGroupData(Number(idGroup));
        getGroupMessagePaged(pagedObject)
        verifyGroupMember(currentUser.idUser, Number(idGroup));
    }

    function verifyGroupMember(idUser, idGroup) {
        RequestUtils.verifyGroupMember(idUser, idGroup).then((res) => {
            if (res?.svStatus && res.data) {
                setGroupMember(true);
            } else {
                setGroupMember(false);
                snackbar(res.msg || "Não possível validar se o usuário é membro do grupo.").error();
            }
        }).catch((error) => {
            snackbar("Erro ao validar se o usuário é membro do grupo.").error();
        })
    }

    function getGroupData(idGroup) {
        setAwaitingDataGroup(true);
        RequestUtils.getGroupById(idGroup).then((res) => {
            if (res?.svStatus && res.data) {
                setGroupData(res.data);
            } else {
                snackbar(res.msg || "Não foi possível buscar dados do grupo.").error();
            }
            setAwaitingDataGroup(false);
        }).catch((e) => {
            snackbar("Erro ao buscar dados do grupo.").error();
            setAwaitingDataGroup(false);
        });
    }

    function hubStart(idUser) {
        return ChatHub.start().then((res) => {

            ChatHub.subscribe(ChatHub.events.onGroupEvent, (group, message) => {
                setMessageListener(message);
            });

            ChatHub.subscribe(ChatHub.events.onDeleteMessage, (group, message) => {
                setDeleteMessageListener(message);
            });

            ChatHub.invoke(ChatHub.call.sendListener, idUser, true)
                .then((res) => null)
                .catch(ChatHub.stop);

            ChatHub.onClose((e) => {
                !closeChat && hubStart(currentUser.idUser);
            });
        });
    }

    function receivedMessage(message) {
        arrMessages.push(message);
        setTimeout(() => {
            scrollToEnd.current.scrollIntoView({ behavior: "smooth" });
        }, 100);
        setAwaitingDataMessage(false);
    }

    function getGroupMessagePaged(pagedObject) {
        setAwaitingData(true);
        RequestUtils.getMessagePaged(
            Number(idGroup),
            pagedObject.searchTerm,
            pagedObject.page,
            pagedObject.itensPerPage
        ).then((res) => {
            if (res?.svStatus && res.data) {
                let messages = res.data.listGroupMessages;

                messages.reverse();

                arrMessages.setValue(messages);
                setPagedObject({ ...pagedObject, amountPage: res.data.pageCount });
                setTimeout(() => {
                    scrollToEnd.current.scrollIntoView({ behavior: "smooth" });
                }, 100);
            } else {
                snackbar(res.msg || "Não foi possível buscar as mensagens do grupo.").error();
            }
            setAwaitingData(false);
        }).catch((e) => {
            snackbar("Erro ao buscar as mensagens do grupo.").error();
            setAwaitingData(false);
        });
    }

    function getGroupMessage(page, itensPerPage) {
        setAwaitingDataAddMessage(true);
        RequestUtils.getMessagePaged(idGroup, '', page, itensPerPage).then((res) => {
            if (res?.svStatus && res.data) {
                const listMessages = res.data?.listGroupMessages || [];

                arrMessages.setValue([...listMessages.reverse(), ...arrMessages.value]);

                setPagedObject({ ...pagedObject, page: res.data.page, amountPage: res.data.pageCount });
            } else {
                snackbar(res.msg || "Não foi possível buscar as mensagens do grupo.").error();
            }
            setAwaitingDataAddMessage(false);
        }).catch((e) => {
            snackbar("Erro ao buscar as mensagens do grupo.").error();
            setAwaitingDataAddMessage(false);
        });
    }

    function getPagedMessages() {
        const sumPage = pagedObject.page + 1;
        if (!(sumPage > pagedObject.amountPage)) {
            getGroupMessage(sumPage, pagedObject.itensPerPage);
        }
    }

    function addGroupMember() {

        const addGroupMemberDTO = {
            idGroup: Number(idGroup),
            idUser: currentUser.idUser,
            idUserInserted: currentUser.idUser
        }

        setAddMemberLoading(true);
        RequestUtils.addGroupMember(addGroupMemberDTO).then((res) => {
            if (res.data && res.svStatus) {
                setGroupMember(true);
                snackbar("Usuário adicionado com sucesso.").success();
            } else {
                snackbar(res.msg || "Erro ao buscar dados do usuário do grupo.").error();
            }
            setAddMemberLoading(false);
        }).catch((e) => {
            setAddMemberLoading(false);
            snackbar("Erro ao adicionar usuário no grupo.").error();
        })
    }

    function sendMessage(msg, messageType) {
        if (msg) {
            setAwaitingDataMessage(true);

            let formatedMsg = msg;

            if (messageType === "img") {
                formatedMsg = msg.split("base64,")[1];
            } else {
                formatedMsg = encode(formatedMsg);
            }

            const message = {
                IdGroup: Number(idGroup),
                IdUser: currentUser.idUser,
                MessageType: messageType,
                Message: formatedMsg
            }

            setText("");

            ChatHub.invoke(ChatHub.call.sendGroupMessage, groupData, message)
                .then((res) => {

                    setAwaitingDataMessage(false);
                })
                .catch((error) => {
                    ChatHub.stop();
                    setAwaitingDataMessage(false);
                })
        }
    }

    function deleteMessage(message) {
        if (message) {
            setAwaitingDataMessage(true);

            ChatHub.invoke(ChatHub.call.deleteGroupMessage, groupData, message)
                .then((res) => {
                    setAwaitingDataMessage(false);
                })
                .catch((error) => {
                    ChatHub.stop();
                    setAwaitingDataMessage(false);
                })
        }
    }

    return (
        <Page className={classes.root} title={"Mensagens do grupo"}>
            <Container maxWidth="lg">
                <Grid container justifyContent="center" spacing={3}>
                    <Grid item lg={12} md={12} xs={12}>
                        <ChatRender
                            messages={arrMessages.value}
                            awaitData={awaitData || awaitDataGroup}
                            idUser={currentUser.idUser}
                            awaitDataMessage={awaitDataMessage}
                            awaitDataAddMessage={awaitDataAddMessage}
                            pagedObject={pagedObject}
                            commentValue={text}
                            addMemberLoading={addMemberLoading}
                            groupData={groupData}
                            scrollToEnd={scrollToEnd}
                            groupMember={groupMember}
                            getPagedMessages={getPagedMessages}
                            setCommentValue={setText}
                            sendMessage={sendMessage}
                            addGroupMember={addGroupMember}
                            deleteMessage={deleteMessage}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}

export default GroupChat;