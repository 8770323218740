import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Card, CardHeader, Box,
    IconButton, CardContent,
    CircularProgress, Typography,
    Grid, Paper, Avatar, Divider
} from '@mui/material'

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    containerAvatar: {
        marginRight: 15
    },
    gridItem: {
        display: 'flex',
        alignItems: 'center',
    },
    buttonRemove: {
        display: 'flex',
        justifyContent: 'end'
    },
    paperContainer: {
        padding: 5,
    }
}))

function ChallengeMemberList({className, arrMembers, removeMember, setMembersModal, awaitData, ...rest}) {
    const classes = useStyles();

    return (
        <Card {...rest} className={clsx(classes.root, className)}>
            <CardHeader
                action={
                    <IconButton aria-label="settings" onClick={() => setMembersModal(true)}>
                        <AddIcon />
                    </IconButton>
                }
                title="Participantes"
                subheader="Todos os participantes do desafio atual"
            />
            <Divider />
            <CardContent>
                {!awaitData ? (
                    <>
                    {arrMembers.length > 0 ? (
                        <Grid container justifyContent="center" justifyItems="center" alignItems="center" spacing={1}>
                        {arrMembers.map((member, idx) => (
                            <Grid key={idx} item md={12} xs={12}>
                                <Paper elevation={3} className={classes.paperContainer}>
                                    <Grid container justifyContent="center" justifyItems="center" alignItems="center">
                                        <Grid item md={4} sm={4} xs={12} className={classes.gridItem}>
                                            <Avatar className={classes.containerAvatar}>
                                                {member?.name[0]}
                                            </Avatar>
                                            <Typography
                                                color="textSecondary"
                                                display="inline"
                                                variant="body2"
                                            >
                                                <b>{member?.name}</b>
                                            </Typography>
                                        </Grid>
                                        <Grid item md={4} sm={4} xs={12} className={classes.gridItem}>
                                            <Typography
                                                color="textSecondary"
                                                display="inline"
                                                variant="body2"
                                            >
                                                <b>E-mail:</b> {member?.email}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={4} sm={4} xs={12} className={clsx(classes.gridItem, classes.buttonRemove)}>
                                            <IconButton aria-label="settings" onClick={() => removeMember(member?.idChallengeUser)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                    ) : (
                        <Box mt={3} display="flex" justifyContent="center">
                            <Typography variant="body1" gutterBottom>
                                Nenhum item encontrado.
                            </Typography>
                        </Box>
                    )}
                </>
                ) : (
                    <Box mt={10}  mb={10} display="flex" justifyContent="center">
                        <CircularProgress />
                    </Box>
                )}
            </CardContent>
        </Card>
    )
}

ChallengeMemberList.propTypes = {
    className: PropTypes.string,
    arrMembers: PropTypes.array.isRequired,
    removeMember: PropTypes.func.isRequired,
    setMembersModal: PropTypes.func.isRequired,
    awaitData: PropTypes.bool.isRequired,
}

export default ChallengeMemberList;