import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import {
    Card, Modal, Box,
    CardContent, Typography,
    CircularProgress,
    IconButton, CardHeader,
    Divider, Grid,
    Paper, Avatar
} from '@mui/material';

const useStyles = makeStyles((theme) => ({
    modalContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        boxShadow: 24,
    },
    boxAllData: {
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: theme.palette.inverse.main,
        borderRadius: "4px"
    },
    paperContainer: {
        padding: 5,
    },
    breakText: {
        wordBreak: 'break-word'
    }
}));

function StepsModal({ className, arrSteps, awaitData, statusModal, setStatusModal, ...rest }) {
    const classes = useStyles();

    function getTimeStep(step) {
        const end = `${step?.challengeUserStepStart ? moment(step?.challengeUserStepStart).format('DD/MM/YYYY') : 'ainda não foi completado'}`

        return `Data execução: ${end}`
    }

    return (
        <Modal
            open={statusModal}
            onClose={() => setStatusModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            {...rest}
        >
            <Card className={classes.modalContainer}>
                <CardHeader
                    action={
                        <IconButton aria-label="closeModal" onClick={() => setStatusModal(false)}>
                            <CloseIcon />
                        </IconButton>
                    }
                />
                <Divider />
                <CardContent>
                    {!awaitData ? (
                        <>
                            <Box mb={4}>
                                <Typography variant="h3" align="center" gutterBottom>
                                    Etapas
                                </Typography>
                                <Typography variant="h4" align="center" gutterBottom>
                                    Progresso do usuário nas etapas do desafio
                                </Typography>
                            </Box>
                            {arrSteps?.length > 0 ? (
                                <Grid container justifyContent="center" justifyItems="center" alignItems="center" spacing={1}>
                                    {arrSteps.map((steps, idx) => (
                                        <Grid key={idx} item lg={12} md={12} sm={12} xs={12}>
                                            <Paper elevation={3} className={classes.paperContainer}>
                                                <CardHeader
                                                    avatar={
                                                        <Avatar aria-label="recipe">
                                                            {steps?.tblChallengeUserStep?.challengeUserStepEnd ? (
                                                                <CheckIcon />
                                                            ) : (
                                                                <HourglassEmptyIcon />
                                                            )}
                                                        </Avatar>
                                                    }
                                                    title={
                                                        <a className={classes.breakText}>
                                                            {steps?.challengeStepTitle}
                                                        </a>
                                                    }
                                                    subheader={getTimeStep(steps.tblChallengeUserStep)}
                                                />
                                            </Paper>
                                        </Grid>
                                    ))}
                                </Grid>
                            ) : (
                                <Box mt={3} display="flex" justifyContent="center">
                                    <Typography variant="body1" gutterBottom>
                                        Nenhum item encontrado.
                                    </Typography>
                                </Box>
                            )}
                        </>
                    ) : (
                        <Box mt={3} display="flex" justifyContent="center">
                            <CircularProgress />
                        </Box>
                    )}
                </CardContent>
            </Card>
        </Modal>
    );
}

StepsModal.propTypes = {
    className: PropTypes.string,
    arrSteps: PropTypes.array.isRequired,
    awaitData: PropTypes.bool.isRequired,
    statusModal: PropTypes.bool.isRequired,
    setStatusModal: PropTypes.func.isRequired,
}

export default StepsModal;