import React from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Search as SearchIcon } from '@mui/icons-material';
import {
    Box, Button,
    Card, CardContent,
    TextField,
    InputAdornment,
} from '@mui/material';

const useStyles = makeStyles((theme) => ({
    root: {},
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    }
}));

function Toolbar({
    className,
    filter,
    inputValue,
    inputLabel = '',
    inputPlaceholder = '',
    buttonPath,
    buttonText = 'add',
    importButtonVisible = false,
    importButtonAction,
    importButtonText = 'import',
    exportButtonVisible = false,
    exportButtonAction,
    exportButtonText = 'export',
    ...rest
}) {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, className)} {...rest}>
            <Box display="flex" justifyContent="flex-end">
                {importButtonVisible &&
                    <Button className={classes.importButton} onClick={importButtonAction}>
                        {importButtonText}
                    </Button>
                }
                {exportButtonVisible &&
                    <Button className={classes.exportButton} onClick={exportButtonAction}>
                        {exportButtonText}
                    </Button>
                }
                <Button
                    color="primary"
                    variant="contained"
                    component={RouterLink}
                    to={buttonPath}
                >
                    {buttonText}
                </Button>
            </Box>
            <Box mt={3}>
                <Card>
                    <CardContent>
                        <Box maxWidth={500}>
                            <TextField
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon fontSize="small" color="action" />
                                        </InputAdornment>
                                    )
                                }}
                                placeholder={inputPlaceholder}
                                variant="outlined"
                                label={inputLabel}
                                value={inputValue}
                                onChange={(e) => filter(e.target.value)}
                            />
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </div>
    );
};

Toolbar.propTypes = {
    className: PropTypes.string,
    filter: PropTypes.func.isRequired,
    inputValue: PropTypes.string.isRequired,
    inputLabel: PropTypes.string,
    inputPlaceholder: PropTypes.string.isRequired,
    buttonPath: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    importButtonVisible: PropTypes.bool,
    importButtonAction: PropTypes.func,
    importButtonText: PropTypes.string,
    exportButtonVisible: PropTypes.bool,
    exportButtonAction: PropTypes.func,
    exportButtonText: PropTypes.string,
};

export default Toolbar;