import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import DisabledByDefault from '@mui/icons-material/DisabledByDefault';

import {
    Grid, Divider, Card,
    CardContent, CardHeader, Typography,
    IconButton, Paper, Box,
    CircularProgress
} from '@mui/material';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    containerAvatar: {
        marginRight: 15
    },
    gridItem: {
        display: 'flex',
        alignItems: 'center'
    },
    buttonRemove: {
        display: 'flex',
        justifyContent: 'end'
    },
    paperContainer: {
        padding: 5
    }
}));

function MarathonChallengeList({ className, arrChallenges, removeChallenge, awaitData, ...rest }) {
    const classes = useStyles();

    return (
        <Card {...rest} className={clsx(classes.root, className)}>
            <CardHeader
                title="Desafios"
                subheader="Todos os desafios da maratona"
            />
            <Divider />
            <CardContent>
                {!awaitData ? (
                    <>
                        {arrChallenges.length > 0 ? (
                            <Grid container justifyContent="center" justifyItems="center" alignItems="center" spacing={1}>
                                {arrChallenges.map((challenge, idx) => (
                                    <Grid key={idx} item md={12} xs={12}>
                                        <Paper elevation={3} className={classes.paperContainer}>
                                            <Grid container justifyContent="space-between" alignItems="center">
                                                <Grid item md={3} sm={3} xs={12} className={classes.gridItem}>
                                                    <Typography
                                                        color="textSecondary"
                                                        display="inline"
                                                        variant="body2"
                                                    >
                                                        <b>{challenge?.challengeTitle}</b>
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={3} sm={3} xs={12} className={classes.gridItem}>
                                                    <Typography
                                                        color="textSecondary"
                                                        display="inline"
                                                        variant="body2"
                                                    >
                                                        <b>Descrição: </b> {challenge?.challengeDescription}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={3} sm={3} xs={10} alignItems="center" display="flex">
                                                    <FiberManualRecordIcon
                                                        className={classes.statsIcon}
                                                        sx={{ color: challenge?.challengeIsActive ? 'green' : 'red' }}
                                                    />
                                                    <Typography
                                                        color="textSecondary"
                                                        display="inline"
                                                        variant="body2"
                                                        pr={0.5}
                                                    >
                                                        <b>Status: </b> {challenge?.challengeIsActive ? "Ativo" : "Inativo"}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={1} sm={1} xs={2} className={clsx(classes.gridItem, classes.buttonRemove)}>
                                                    <IconButton aria-label="settings" onClick={() => removeChallenge(challenge?.idChallenge)}>
                                                        <DisabledByDefault />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                ))}
                            </Grid>
                        ) : (
                            <Box mt={3} display="flex" justifyContent="center">
                                <Typography variant="body1" gutterBottom>
                                    Nenhum item encontrado.
                                </Typography>
                            </Box>
                        )}
                    </>
                ) : (
                    <Box mt={10} mb={10} display="flex" justifyContent="center">
                        <CircularProgress />
                    </Box>
                )}
            </CardContent>
        </Card>
    )
}

MarathonChallengeList.propTypes = {
    className: PropTypes.string,
    arrChallenge: PropTypes.array,
    removeChallenge: PropTypes.func.isRequired,
    awaitData: PropTypes.bool.isRequired,
}

export default MarathonChallengeList;