import { useState, useMemo } from "react";

export default function useArray(initialValue) {
    const [value, setValue] = useState(initialValue);
    const length = useMemo(() => value.length, [value]);

    function push(...elements) {
        setValue(a => [...a, ...elements]);
    }

    function unshift(...elements) {
        setValue(a => [...elements, ...a]);
    }

    function filter(callback) {
        setValue(a => a.filter(callback));
    }

    function findById(id, idLabel = 'id') {
        return value.find((e) => e?.[idLabel] === id);
    }

    function update(index, newElement) {
        setValue(a => [
            ...a.slice(0, index),
            newElement,
            ...a.slice(index + 1, a.length),
        ]);
    }

    function updateById(id, newElement, idLabel = 'id') {
        const index = value.findIndex((e) => e[idLabel] === id);
        if (index > -1) update(index, newElement);
    }

    function remove(index) {
        setValue(a => [...a.slice(0, index), ...a.slice(index + 1, a.length)]);
    }

    function removeById(id, idLabel = 'id') {
        filter((e) => {
            if (e?.[idLabel]) return e[idLabel] !== id;
            return true;
        });
    }

    function clear() {
        setValue([]);
    }

    return { value, setValue, push, unshift, filter, findById, update, updateById, remove, removeById, clear, length };
}