import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import { Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useParams, useHistory } from 'react-router-dom';

import useArray from 'hooks/useArray';
import useSnackbar from 'hooks/useSnackbar';
import useLocalStorage from 'hooks/useLocalStorage';
import RequestUtils from 'modules/api/RequestUtils';

import Page from 'components/Page';
import {
    MarathonChallengeList, MarathonForm, MarathonTree
} from './components';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const validationSchema = Yup.object({
    title: Yup.string()
        .required('Campo obrigatório')
        .max(100, "O título não pode ultrapassar 100 caracteres."),
    description: Yup.string()
        .required('Campo obrigatório')
        .max(200, "A descrição não pode ultrapassar 200 caracteres."),
});

function Marathon() {
    const classes = useStyles();
    const history = useHistory();
    const snackbar = useSnackbar();
    const { idMarathon } = useParams();
    const arrChallenges = useArray([]);
    const arrChallengesOrder = useArray([]);
    const [awaitData, setAwaitingData] = useState(false);
    const [awaitDataChallengeAndNext, setAwaitingDataChallengeAndNext] = useState(true);
    const [awaitDataChallenge, setAwaitingDataChallenge] = useState(false);
    const [marathonData, setMarathonData] = useState();
    const [isPremiumMarathon, setIsPremiumMarathon] = useState(true);

    const formik = useFormik({
        initialValues: {
            title: '',
            description: ''
        },
        validationSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        }
    });

    function componentMount() {
        if (!idMarathon) return;

        getMarathonData(idMarathon);
        getChallenges(idMarathon);
        getChallengesAndNextChallenges(idMarathon);
    }
    useEffect(componentMount, [])

    function getMarathonData(idMarathon) {
        setAwaitingData(true);
        RequestUtils.getMarathonById(idMarathon).then((res) => {
            if (res?.svStatus && res.data) {
                const marathon = res.data;
                setMarathonData(marathon);
                formik.setValues({
                    title: marathon.title,
                    description: marathon.description,
                });
                setIsPremiumMarathon(marathon.isPremium);
            } else {
                snackbar(res.msg || "Não foi possível buscar dados da maratona.").error();
            }
            setAwaitingData(false);
        }).catch((e) => {
            snackbar("Erro ao buscar dados da maratona.").error();
            setAwaitingData(false);
        });
    }

    function getChallenges(idMarathon) {
        setAwaitingDataChallenge(true);
        RequestUtils.getChallengeByIdMarathon(idMarathon).then((res) => {
            if (res?.svStatus && res.data) {
                const challenges = res.data;
                arrChallenges.setValue(challenges);
            } else {
                snackbar(res.msg || "Não foi possível listar os desafios da maratona.").error();
            }
            setAwaitingDataChallenge(false);
        }).catch((e) => {
            snackbar("Erro ao listar os desafios da maratona.").error();
            setAwaitingDataChallenge(false);
        });
    }

    function getChallengesAndNextChallenges(idMarathon) {
        setAwaitingDataChallengeAndNext(true);
        RequestUtils.getChallengeNextChallengeInfo(idMarathon).then((res) => {
            if (res?.svStatus && res.data) {
                const challenges = res.data;

                arrChallengesOrder.setValue(orderChallenges(challenges));
            } else {
                snackbar(res.msg || "Não foi possível listar os desafios da maratona.").error();
            }
            setAwaitingDataChallengeAndNext(false);
        }).catch((e) => {
            snackbar("Erro ao listar os desafios da maratona.").error();
            setAwaitingDataChallengeAndNext(false);
        });

    }

    function orderChallenges(challengeList) {
        let arr = [];

        let initialItem = challengeList.find((challenge) => challenge.isInitial);

        if (initialItem) {
            arr.push(initialItem);
            challengeList.forEach((challenge) => {
                if (initialItem?.idNextChallenge) {
                    initialItem = challengeList.find((item) => item.idChallenge == initialItem?.idNextChallenge);
                    arr.push(initialItem);
                }
            });
        }

        const arrDifference = _.difference(challengeList, arr);

        return [arr, arrDifference];
    }

    function createMarathon(data) {

        const newMarathon = {
            title: data.title,
            description: data.description,
            isActive: true,
            isPremium: isPremiumMarathon
        }

        setAwaitingData(true);
        RequestUtils.addMarathon(newMarathon).then((res) => {
            if (res?.svStatus && res.data) {
                snackbar("Maratona cadastrada com sucesso.").success();
                history.push(`/desafio/editar/${res.data.idChallengeMarathon}`);
            } else {
                snackbar("Não foi possível cadastrar maratona.").error();
            }
            setAwaitingData(false);
        }).catch((e) => {
            snackbar("Erro ao cadastrar maratona.").error();
            setAwaitingData(false);
        });
    }

    function editMarathon(data) {
        const editMarathon = {
            idChallengeMarathon: Number(marathonData.idChallengeMarathon) || Number(idMarathon),
            title: data.title,
            isActive: true,
            description: data.description,
            isPremium: isPremiumMarathon
        }

        setAwaitingData(true);
        RequestUtils.updateMarathon(editMarathon).then((res) => {
            if (res?.svStatus && res.data) {
                const marathon = res.data;
                formik.setValues({
                    title: marathon.title,
                    description: marathon.description
                });
                setMarathonData(marathon);
                snackbar("Maratona atualizada com sucesso.").success();
            } else {
                snackbar(res.msg || "Não foi possível atualizar maratona.").error();
            }
            setAwaitingData(false);
        }).catch((e) => {
            snackbar("Erro ao atualizar maratona.").error();
            setAwaitingData(false);
        });
    }

    function handleSubmit(data) {
        if (idMarathon === undefined) {
            createMarathon(data);
        } else {
            editMarathon(data);
        }
    }

    function removeChallenge(idChallenge) {
        setAwaitingDataChallenge(true);
        RequestUtils.unlinkMarathon(idChallenge).then((res) => {
            if (res?.svStatus && res.data) {
                const arr = arrChallenges.value.filter((challenge) => challenge.idChallenge != idChallenge);
                arrChallenges.setValue(arr);
                snackbar("Desafio desvinculado com sucesso.").success();
            } else {
                snackbar(res.msg || "Não foi possível desvincular desafio da maratona.").error();
            }
            setAwaitingDataChallenge(false);
        }).catch((e) => {
            snackbar("Erro ao desvincular desafio da maratona.").error();
            setAwaitingDataChallenge(false);
        });
    }

    return (
        <Page className={classes.root} title={idMarathon === undefined ? "Cadastrar maratona" : "Editar maratona"}>
            <Container maxWidth="lg">
                <Grid container justifyContent="center" spacing={3}>
                    <Grid item lg={10} md={10} xs={12}>
                        <MarathonForm
                            formik={formik}
                            idMarathon={idMarathon}
                            awaitData={awaitData}
                            marathonData={marathonData}
                            isPremiumMarathon={isPremiumMarathon}
                            setIsPremiumMarathon={setIsPremiumMarathon}
                        />
                    </Grid>
                    {idMarathon &&
                        <>
                            <Grid item lg={10} md={10} xs={12}>
                                <MarathonTree
                                    arrChallenge={arrChallengesOrder.value}
                                    awaitData={awaitDataChallengeAndNext}
                                />
                            </Grid>
                            <Grid item lg={10} md={10} xs={12}>
                                <MarathonChallengeList
                                    arrChallenges={arrChallenges.value}
                                    removeChallenge={removeChallenge}
                                    awaitData={awaitDataChallenge}
                                />
                            </Grid>
                        </>
                    }
                </Grid>
            </Container>
        </Page>
    );
}

export default Marathon;