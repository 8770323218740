import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import CreateIcon from '@mui/icons-material/Create';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { NavLink as RouterLink } from 'react-router-dom';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {
	Box, Card,
	CardContent, Divider, Grid,
	Typography, CardHeader,
	IconButton, Menu, MenuItem
} from '@mui/material';

import stringRedutor from 'modules/utils/stringRedutor';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
	},
	statsItem: {
		alignItems: 'center',
		display: 'flex'
	},
	statsIcon: {
		marginRight: theme.spacing(1)
	},
	iFrameSize: {
		height: '100%',
		width: '100%',
	}
}));

function VideoCard({ className, video, updateStatusVideo, ...rest }) {
	const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    function handleClick(currentTarget) {
        setAnchorEl(currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

	return (
		<Card className={clsx(classes.root, className)} {...rest}>
			<CardHeader
				title={stringRedutor(video.title, 25)}
				action={
					<Box>
						<IconButton
							aria-label="edit"
							component={RouterLink}
							to={`/videos/editar/${video.idVideo}`}
						>
							<CreateIcon />
						</IconButton>
                        <IconButton aria-label="settings" onClick={(e) => handleClick(e.currentTarget)} >
                            <MoreVertIcon />
                        </IconButton>

                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={(e) => {
                                updateStatusVideo(video.idVideo)
                                handleClick(e.currentTarget)
                            }}>
                                {video.videoIsActive ? "Desativar vídeo" : "Ativar vídeo"}
                            </MenuItem>
                        </Menu>
					</Box>
				}
			/>
			<Box flexGrow={1} />
			<Divider />
			<CardContent>
				<iframe
					className={classes.iFrameSize}
					src={video.url}
					title="YouTube video player"
					frameBorder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen>
				</iframe>
			</CardContent>
			<Box flexGrow={1} />
			<Divider />
			<Box p={2}>
				<Grid
					container
					justifyContent="space-between"
					spacing={2}
				>
					<Grid
						className={classes.statsItem}
						item
					>
						<AccessTimeIcon
							className={classes.statsIcon}
							color="action"
						/>
						<Typography
							color="textSecondary"
							display="inline"
							variant="body2"
						>
							{video?.createAt ? moment(video?.createAt).format('DD/MM/YYYY') : "--/--/----"}
						</Typography>
					</Grid>
					<Grid
						className={classes.statsItem}
						item
					>
						<FiberManualRecordIcon
							className={classes.statsIcon}
							sx={{ color: video.videoIsActive ? 'green' : 'red' }}
						/>
						<Typography
							color="textSecondary"
							display="inline"
							variant="body2"
						>
							{video.videoIsActive ? 'Ativo' : 'Inativo'}
						</Typography>
					</Grid>
				</Grid>
			</Box>
		</Card>
	);
};

VideoCard.propTypes = {
	className: PropTypes.string,
	video: PropTypes.object.isRequired,
	updateStatusVideo: PropTypes.func.isRequired
};

export default VideoCard;