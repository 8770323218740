import { Switch, Route, Redirect } from "react-router-dom";

// layouts
import { /* Main as MainLayout, */ Dashboard as DashboardLayout } from "../layouts";

// pages
import {
	Dashboard,
	Account,
	CustomerList,
	Map,
	Products,
	RegisterView,
	Settings,
	Errors,
	GroupsList,
	GroupChat,
	User,
	UsersList,
	Challenge,
	ChallengersList,
	Group,
	Marathon,
	MarathonList,
	Video,
	VideoList,
	Notification,
	NotificationsList,
	Forum,
	ForunsList,
	ForumChats,
	Link,
	RequestHelpList
} from "../pages/";

// components
import { RouteWithLayout } from "./components";

const routes = [{
	path: "/dashboard",
	layout: DashboardLayout,
	component: Dashboard
}, {
	path: "/account",
	layout: DashboardLayout,
	component: Account
}, {
	path: "/customers",
	layout: DashboardLayout,
	component: CustomerList
}, {
	path: "/map",
	layout: DashboardLayout,
	component: Map
}, {
	path: "/products",
	layout: DashboardLayout,
	component: Products
}, {
	path: "/register",
	layout: DashboardLayout,
	component: RegisterView
}, {
	path: "/settings",
	layout: DashboardLayout,
	component: Settings
}, {
	path: "/usuarios",
	layout: DashboardLayout,
	component: UsersList,
	exact: true
}, {
	path: "/usuarios/cadastrar",
	layout: DashboardLayout,
	component: User,
	exact: true
}, {
	path: "/usuarios/editar/:idUser",
	layout: DashboardLayout,
	component: User,
	exact: true
}, {
	path: "/passos-desafio",
	layout: DashboardLayout,
	component: ChallengersList,
	exact: true
}, {
	path: "/passos-desafio/cadastrar",
	layout: DashboardLayout,
	component: Challenge,
	exact: true
}, {
	path: "/passos-desafio/editar/:idChallenge",
	layout: DashboardLayout,
	component: Challenge,
	exact: true
}, {
	path: "/grupos/cadastrar",
	layout: DashboardLayout,
	component: Group,
	exact: true
}, {
	path: "/grupos/editar/:idGroup",
	layout: DashboardLayout,
	component: Group,
	exact: true
},
{
	path: "/grupos/chat/:idGroup",
	layout: DashboardLayout,
	component: GroupChat,
	exact: true
}, {
	path: "/grupos",
	layout: DashboardLayout,
	component: GroupsList
}, {
	path: "/desafio",
	layout: DashboardLayout,
	component: MarathonList,
	exact: true
}, , {
	path: "/desafio/cadastrar",
	layout: DashboardLayout,
	component: Marathon,
	exact: true
},{
	path: "/desafio/editar/:idMarathon",
	layout: DashboardLayout,
	component: Marathon,
	exact: true
},{
	path: "/videos/cadastrar",
	layout: DashboardLayout,
	component: Video,
	exact: true
}, {
	path: "/videos/editar/:idVideo",
	layout: DashboardLayout,
	component: Video,
	exact: true
}, {
	path: "/videos",
	layout: DashboardLayout,
	component: VideoList
}, {
	path: "/404",
	layout: DashboardLayout,
	component: Errors
},  {
	path: "/notificacoes/cadastrar",
	layout: DashboardLayout,
	component: Notification,
	exact: true
},  {
	path: "/notificacoes/:idNotification/duplicar",
	layout: DashboardLayout,
	component: Notification,
	exact: true
}, {
	path: "/notificacoes",
	layout: DashboardLayout,
	component: NotificationsList
}, {
	path: "/foruns/cadastrar",
	layout: DashboardLayout,
	component: Forum,
	exact: true
}, {
	path: "/foruns/editar/:idForum",
	layout: DashboardLayout,
	component: Forum,
	exact: true
}, {
	path: "/foruns/mensagens/:idForum",
	layout: DashboardLayout,
	component: ForumChats,
	exact: true
}, {
	path: "/foruns",
	layout: DashboardLayout,
	component: ForunsList
}, {
	path: "/link",
	layout: DashboardLayout,
	component: Link,
	exact: true
}, {
	path: "/solicitacoes-de-ajuda",
	layout: DashboardLayout,
	component: RequestHelpList
}];

function Routes() {
	return (
		<Switch>
			{routes.map((route, idx) => <RouteWithLayout key={idx} {...route} />)}
			<Route exact path="/" render={() => <Redirect to="/dashboard" />} />
		</Switch>
	);
};

export default Routes;