import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Box } from '@mui/material/';
import ChartView from "./Chart.view";

const propTypes = {
	type: PropTypes.oneOf(['line', 'bar', 'radar', 'pie', 'doughnut', 'polarArea', 'bubble']).isRequired,
	data: PropTypes.shape({
		datasets: PropTypes.arrayOf(PropTypes.object).isRequired,
		labels: PropTypes.arrayOf(PropTypes.string)
	}).isRequired,
	options: PropTypes.object,
	height: PropTypes.string,
	width: PropTypes.string
};

const defaultProps = {
	height: "auto",
	width: "auto"
};

function Chart(props) {
	const { type, data, options, ...other } = props;
	const canvasRef = useRef(null);
	const chartRef = useRef(null);

	const init = () => {
		chartRef.current = new ChartView(canvasRef.current, { type, data, options });
		chartRef.current.render();
	}

	useEffect(init, [type, data, options]);

	useEffect(() => {
		if (!chartRef.current || !data) return;
		chartRef.current.data = data;
		chartRef.current.update();
	}, [data]);

	useEffect(() => {
		if (!chartRef.current || !options) return;
		chartRef.current.options = options;
		chartRef.current.update();
	}, [options]);

	return (
		<Box {...other}>
			<canvas ref={canvasRef} />
		</Box>
	);
}

Chart.propTypes = propTypes;
Chart.defaultProps = defaultProps;

export default Chart;