import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Grid, Divider, Card, CardContent, CardHeader } from '@mui/material';

import { FormikTextField } from 'components/formElements';

const useStyles = makeStyles(() => ({
    statusForum: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
    }
}));

function LinkForm({ className, formik, awaitData, ...rest }) {
    const classes = useStyles();

    return (
        <Card {...rest} className={clsx(classes.root, className)}>
            <CardHeader
                title={"Editar link premium"}
                subheader={"Informe uma URL para o link"}
            />
            <Divider />
            <form onSubmit={formik.handleSubmit}>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                            <FormikTextField fullWidth formik={formik} name="link" label="URL" />
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider />

                <Box display="flex" justifyContent={"flex-end"} p={2}>
                    <LoadingButton
                        type="submit"
                        color="primary"
                        variant="contained"
                        loading={awaitData}
                    >
                        Editar
                    </LoadingButton>
                </Box>
            </form>
        </Card>
    )
}

LinkForm.propTypes = {
    className: PropTypes.string,
    formik: PropTypes.object.isRequired,
    awaitData: PropTypes.bool.isRequired,
}

export default LinkForm;