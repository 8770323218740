import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    colors,
    useTheme,
    CircularProgress,
    Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ForumIcon from '@mui/icons-material/Forum';

import Chart from 'components/Chart';

const useStyles = makeStyles(() => ({
    root: {
        height: '100%'
    }
}));

const colorOptions = [
    colors.indigo[500],
    colors.red[600],
    colors.orange[600],
    colors.blue[600],
    colors.blueGrey[600],
    colors.brown[600],
    colors.cyan[600],
    colors.deepOrange[600],
    colors.amber[600],
    colors.deepPurple[600],
    colors.green[600],
    colors.grey[600],
    colors.lightBlue[600],
    colors.lime[600],
    colors.pink[600],
    colors.purple[600],
    colors.teal[600],
    colors.yellow[600],
]

function ForumMessagesByForum({ className, amountItens, awaitData, ...rest }) {
    const classes = useStyles();
    const theme = useTheme();

    const [dataGraph, setDataGraph] = useState([]);
    const [noValues, setNoValues] = useState(false);
    const [dataForuns, setDataForuns] = useState({ forumName: [], color: [] });

    const data = {
        datasets: [
            {
                data: dataGraph,
                backgroundColor: dataForuns.color,
                borderWidth: 4,
                borderColor: colors.common.white,
                hoverBorderColor: colors.common.white
            }
        ],
        labels: dataForuns.forumName
    };

    const options = {
        animation: false,
        cutoutPercentage: 0,
        layout: { padding: 0 },
        legend: {
            display: false,
            maxHeight: 1,
            position: 'bottom',
            labels: {
                textAlign: 'left',
                padding: 20,
                boxHeight: 10,
            }
        },
        maintainAspectRatio: false,
        responsive: true,
        tooltips: {
            backgroundColor: theme.palette.background.default,
            bodyFontColor: theme.palette.text.secondary,
            borderColor: theme.palette.divider,
            borderWidth: 1,
            enabled: true,
            footerFontColor: theme.palette.text.secondary,
            intersect: false,
            mode: 'index',
            titleFontColor: theme.palette.text.primary
        }
    };

    useEffect(() => {
        if (!amountItens) return;
        let data = [];
        let amountForuns = [];
        let dataForum = { forumName: [], color: [] };

        amountItens.forEach((amount, index) => {
            if (amount.amountMessage > 0) {
                data.push(amount.amountMessage);
                dataForum.forumName.push(amount.forumTitle);
                dataForum.color.push(colorOptions[index]);
            }

            const dto = {
                title: amount.forumTitle,
                value: amount.amountMessage,
                icon: ForumIcon,
                color: colorOptions[index]
            }

            amountForuns.push(dto);
            
        })
        setDataForuns(dataForum);

        const sum = data.reduce((partialSum, a) => partialSum + a, 0);

        if (sum === 0) {
            setNoValues(true);
        } else {
            setNoValues(false);
        }

        setDataGraph(data);
    }, [amountItens])

    return (
        <Card className={clsx(classes.root, className)} {...rest}>
            <CardHeader title="QUANTIDADE DE MENSAGENS POR FORUM" />
            <Divider />
            <CardContent>
                {!awaitData ? (
                    !noValues ?
                        <Chart
                            height={'400px'}
                            type="doughnut"
                            data={data}
                            options={options}
                        />
                        :
                        <Box mt={3} mb={3} display="flex" justifyContent="center" alignItems='center' style={{ height: 300 }}>
                            <Typography variant="h6">
                                Não há mensagens
                            </Typography>
                        </Box>
                ) : (
                    <Box mt={3} display="flex" justifyContent="center">
                        <CircularProgress />
                    </Box>
                )}
            </CardContent>
        </Card>
    );
};

ForumMessagesByForum.propTypes = {
    className: PropTypes.string,
    amountItens: PropTypes.array,
    awaitData: PropTypes.bool
};

export default ForumMessagesByForum;
