import React, { useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Grid, Divider, Card,
    CardContent, CardHeader, Typography,
    IconButton, Paper, Avatar,
    Box, CircularProgress, Collapse
} from '@mui/material';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    paperContainer: {
        padding: 5,
    },
    breakText: {
        wordBreak: 'break-word'
    }
}));

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

function StepsList({ className, awaitData, arrSteps, removeStep, setStatusModal, ...rest }) {
    const classes = useStyles();
    const [idCard, setIdCard] = useState('');
    const handleExpandClick = (idCardOpen) => {
        if (idCardOpen == idCard) {
            setIdCard('');
        } else {
            setIdCard(idCardOpen);
        }
    };

    return (
        <Card {...rest} className={clsx(classes.root, className)}>
            <CardHeader
                action={
                    <IconButton aria-label="settings" onClick={() => setStatusModal(true)}>
                        <AddIcon />
                    </IconButton>
                }
                title="Etapas do desafio"
                subheader="Todas as etapas do desafio atual"
            />
            <Divider />
            <CardContent>
                {!awaitData ? (
                    <>
                        {arrSteps.length > 0 ? (
                            <Grid container justifyContent="center" justifyItems="center" alignItems="center" spacing={1}>
                                {arrSteps.map((step, idx) => (
                                    <Grid key={idx} item lg={12} md={12} sm={12} xs={12}>
                                        <Paper elevation={3} className={classes.paperContainer}>
                                            <CardHeader
                                                avatar={
                                                    <Avatar aria-label="recipe">
                                                        {step.challengeStepSort}
                                                    </Avatar>
                                                }
                                                action={
                                                    <>
                                                        <IconButton aria-label="remove" onClick={() => removeStep(step.idChallengeStep)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                        <ExpandMore
                                                            expand={idCard == step.idChallengeStep}
                                                            onClick={() => handleExpandClick(step.idChallengeStep)}
                                                            aria-expanded={idCard == step.idChallengeStep}
                                                            aria-label="show more"
                                                        >
                                                            <ExpandMoreIcon />
                                                        </ExpandMore>
                                                    </>
                                                }
                                                title={
                                                    <a className={classes.breakText}>
                                                        {step.challengeStepTitle}
                                                    </a>
                                                }
                                                subheader={moment(step.challengeStepCreateAt).format('DD/MM/YYYY')}
                                            />
                                            <Collapse in={idCard == step.idChallengeStep} timeout="auto" unmountOnExit>
                                                <CardContent>
                                                    <Typography variant="body1" className={classes.breakText}>
                                                        {step.challengeStepDescription}
                                                    </Typography>
                                                </CardContent>
                                            </Collapse>
                                        </Paper>
                                    </Grid>
                                ))}
                            </Grid>
                        ) : (
                            <Box mt={3} display="flex" justifyContent="center">
                                <Typography variant="body1" gutterBottom>
                                    Nenhum item encontrado.
                                </Typography>
                            </Box>
                        )}
                    </>
                ) : (
                    <Box mt={3} display="flex" justifyContent="center">
                        <CircularProgress />
                    </Box>
                )}
            </CardContent>
        </Card>
    )
}

StepsList.propTypes = {
    className: PropTypes.string,
    awaitData: PropTypes.bool.isRequired,
    arrSteps: PropTypes.array.isRequired,
    removeStep: PropTypes.func.isRequired,
    setStatusModal: PropTypes.func.isRequired,
}

export default StepsList;