import React, { useEffect, useState } from "react";
import { Container, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import useSnackbar from "hooks/useSnackbar";
import Page from "components/Page";

import {
	Users,
	Marathon,
	Forum,
	Group,
	Videos,
	Notification,
	Challenge,
	AmountSmokingLoad,
	AmountFargestromTest,
	ForumMessagesByForum,
	ChallengesDoneLast24h,
	MessagesSentLast24h,
	LastForumMessages,
	UsersByResult,
	UsersByType,
	LastGroupMessages,
} from "./components";
import { RequestUtils } from "modules/api";
import PanicTriggersLast24Hours from "./components/PanicTriggersLast24Hours";
import PanicTriggersLast7Days from "./components/PanicTriggersLast7Days";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		minHeight: "100%",
		paddingBottom: theme.spacing(3),
		paddingTop: theme.spacing(3),
	},
}));

function Dashboard() {
	const snackbar = useSnackbar();
	const classes = useStyles();

	const [amountItens, setAmountItens] = useState();
	const [amountSmokingLoad, setAmountSmokingLoad] = useState(0);
	const [amountFargestromTest, setAmountFargestromTest] = useState(0);
	const [forumMessages, setForumMessages] = useState();
	const [challenges24h, setChallenges24h] = useState();
	const [messages24h, setMessages24h] = useState();
	const [panic24h, setPanic24h] = useState();
	const [messagesTop10, setMessagesTop10] = useState();
	const [usersPerType, setUsersPerType] = useState();
	const [usersPerResult, setUsersPerResult] = useState();
	const [panicTriggers, setPanicTriggers] = useState();

	const [awaitDataUserTypes, setAwaitingDataUserTypes] = useState(true);
	const [awaitDataResult, setAwaitingDataResult] = useState(true);
	const [awaitData, setAwaitingData] = useState(true);
	const [awaitSmokingLoad, setAwaitSmokingLoad] = useState(true);
	const [awaitFargestromTest, setAwaitFargestromTest] = useState(true);
	const [awaitForumMessages, setAwaitForumMessages] = useState(true);
	const [awaitChallenges24h, setAwaitChallenges24h] = useState(true);
	const [awaitPanic24h, setAwaitPanic24h] = useState();
	const [awaitMessages24h, setAwaitMessages24h] = useState(true);
	const [awaitMessagesTop10, setAwaitMessagesTop10] = useState(true);
	const [awaitPanicTriggers, setAwaitPanicTriggers] = useState(true);

	useEffect(componentMount, [])

	function componentMount() {
		getData();
		amountTestSmokingLoading();
		getUsersAmountByType();
		getAmountUserByResultTest();
		getPanicTriggersLast24h();
		amountTestFagerstrom();
		amountForumMessageByForum();
		challengesDone24h();
		messagesSentLast24h();
		getTop10Messages();
		getAmountPanicTriggersLast7Days();
	}

	function getData() {
		setAwaitingData(true);
		RequestUtils.getAmountItemsDashboard().then((res) => {
			if (res?.svStatus && (res.data || res.data >= 0)) {
				setAmountItens(res.data);
			} else {
				snackbar(res.msg || "Não foi possível buscar dados do dashboard.").error();
			}
			setAwaitingData(false);
		}).catch((e) => {
			snackbar("Erro ao buscar dados do dashboard.").error();
			setAwaitingData(false);
		});
	}

	function getUsersAmountByType() {
		setAwaitingDataUserTypes(true);
		RequestUtils.getAmountUsersByType().then((res) => {
			if (res?.svStatus && (res.data || res.data >= 0)) {
				setUsersPerType(res.data);
			} else {
				snackbar(res.msg || "Não foi possível buscar a quantidade de usuário por tipo.").error();
			}
			setAwaitingDataUserTypes(false);
		}).catch((e) => {
			snackbar("Erro ao buscar a quantidade de usuário por tipo.").error();
			setAwaitingDataUserTypes(false);
		});

	}

	function getAmountUserByResultTest() {
		RequestUtils.getAmountUserByResultTest().then((res) => {
			if (res?.svStatus && (res.data || res.data >= 0)) {
				setUsersPerResult(res.data);
				setAwaitingDataResult(false);
			} else {
				snackbar(res.msg || "Não foi possível buscar a quantiade de usuários por resultado dos testes.").error();
				setAwaitingDataResult(false);
			}
		}).catch((e) => {
			snackbar("Erro ao buscar a quantiade de usuários por resultado dos testes.").error();
			setAwaitingDataResult(false);
		});
	}

	function amountTestSmokingLoading() {
		setAwaitSmokingLoad(true);
		RequestUtils.getAmountTestSmokingLoading().then((res) => {
			if (res?.svStatus && (res.data || res.data >= 0)) {
				const testSmoking = res.data;
				setAmountSmokingLoad(testSmoking);
			} else {
				snackbar(res.msg || "Não foi possível buscar dados do teste de Carga Tabágica.").error();
			}
			setAwaitSmokingLoad(false);
		}).catch((error) => {
			snackbar("Erro ao buscar dados do teste de Carga Tabágica.").error();
			setAwaitSmokingLoad(false);
		})
	}

	function amountTestFagerstrom() {
		setAwaitFargestromTest(true);
		RequestUtils.getAmountTestFagerstrom().then((res) => {
			if (res?.svStatus && (res.data || res.data >= 0)) {
				const testFargestrom = res.data;
				setAmountFargestromTest(testFargestrom);
			} else {
				snackbar(res.msg || "Não foi possível buscar dados do teste de Fagerstrom.").error();
			}
			setAwaitFargestromTest(false);
		}).catch((error) => {
			snackbar("Erro ao buscar dados do teste de Fagerstrom.").error();
			setAwaitFargestromTest(false);
		})
	}

	function amountForumMessageByForum() {
		setAwaitForumMessages(true);
		RequestUtils.getAmountForumMessageByForum().then((res) => {
			if (res?.svStatus && (res.data || res.data >= 0)) {
				const forumMessageByForum = res.data;
				setForumMessages(forumMessageByForum);
			} else {
				snackbar(res.msg || "Não foi possível buscar dados do mensagens do forum.").error();
			}
			setAwaitForumMessages(false);
		}).catch((error) => {
			snackbar("Erro ao buscar dados do mensagens do forum.").error();
			setAwaitForumMessages(false);
		})
	}

	function challengesDone24h() {
		setAwaitChallenges24h(true);
		RequestUtils.getAmountChallengesCompletedLast24Hours().then((res) => {
			if (res?.svStatus && (res.data || res.data >= 0)) {
				const challenges = res.data;
				setChallenges24h(challenges);
			} else {
				snackbar(res.msg || "Não foi possível buscar os desafios feitos nas ultimas 24 horas.").error();
			}
			setAwaitChallenges24h(false);
		}).catch((error) => {
			snackbar("Erro ao buscar os desafios feitos nas ultimas 24 horas.").error();
			setAwaitChallenges24h(false);
		})
	}

	function messagesSentLast24h() {
		setAwaitMessages24h(true);
		RequestUtils.getAmountMessageForumLast24Hours().then((res) => {
			if (res?.svStatus && res.data) {
				const messages = res.data;
				setMessages24h(messages);
			} else {
				snackbar(res.msg || "Não foi possível buscar as mensagens das ultimas 24 horas.").error();
			}
			setAwaitMessages24h(false);
		}).catch((error) => {
			snackbar("Erro ao buscar as mensagens das ultimas 24 horas.").error();
			setAwaitMessages24h(false);
		})
	}

	function getPanicTriggersLast24h() {
		setAwaitPanic24h(true);
		RequestUtils.getPanicTriggersLast24Hours().then((res) => {
			if (res?.svStatus && res.data) {
				const panicArray = res.data;
				setPanic24h(panicArray);
			} else {
				snackbar(res.msg || "Não foi possível buscar os acionamentos do botão de pânico nas 24 horas.").error();
			}
			setAwaitPanic24h(false);
		}).catch((error) => {
			snackbar("Erro ao buscar os acionamentos do botão de pânico nas 24 horas.").error();
			setAwaitPanic24h(false);
		})
	}

	function getTop10Messages() {
		setAwaitMessagesTop10(true);
		RequestUtils.getTop10Messages().then((res) => {
			if (res?.svStatus && res.data) {
				const top10 = res.data;
				setMessagesTop10(top10);
			} else {
				snackbar(res.msg || "Não foi possível buscar as 10 ultimas mensagens dos foruns.").error();
			}
			setAwaitMessagesTop10(false);
		}).catch((error) => {
			snackbar("Erro ao buscar as 10 ultimas mensagens dos foruns.").error();
			setAwaitMessagesTop10(false);
		})
	}

	function getAmountPanicTriggersLast7Days() {
		setAwaitPanicTriggers(true);
		RequestUtils.getAmountPanicTriggersLast7Days().then((res) => {
			if((res?.svStatus && res.data) || (res?.svStatus && res.data === 0)){
				const last7 = res.data;
				setPanicTriggers(last7);
			} else {
				snackbar(res.msg || "Erro ao buscar a quantidade de acionamentos do botão de pânico nos últimos 7 dias.").error();
			}
			setAwaitPanicTriggers(false);
		}).catch((error) => {
			snackbar("Erro ao buscar a quantidade de acionamentos do botão de pânico nos últimos 7 dias.").error();
			setAwaitPanicTriggers(false);
		})
	}

	return (
		<Page className={classes.root} title="Dashboard">
			<Container maxWidth={false}>
				<Grid container spacing={3}>
					<Grid item lg={4} sm={6} xl={4} xs={12}>
						<Users amountItens={amountItens} awaitData={awaitData} />
					</Grid>
					<Grid item lg={4} sm={6} xl={4} xs={12}>
						<Videos amountItens={amountItens} awaitData={awaitData} />
					</Grid>
					<Grid item lg={4} sm={6} xl={4} xs={12}>
						<Group amountItens={amountItens} awaitData={awaitData} />
					</Grid>
					<Grid item lg={4} sm={6} xl={4} xs={12}>
						<Notification amountItens={amountItens} awaitData={awaitData} />
					</Grid>
					<Grid item lg={4} sm={6} xl={4} xs={12}>
						<Challenge amountItens={amountItens} awaitData={awaitData} />
					</Grid>
					<Grid item lg={4} sm={6} xl={4} xs={12}>
						<Forum amountItens={amountItens} awaitData={awaitData} />
					</Grid>
					<Grid item lg={6} sm={6} xl={6} xs={12}>
						<PanicTriggersLast7Days amountItens={panicTriggers} awaitData={awaitPanicTriggers} />
					</Grid>
					<Grid item lg={6} sm={6} xl={6} xs={12}>
						<Marathon amountItens={amountItens} awaitData={awaitData} />
					</Grid>
					<Grid item lg={6} sm={6} xl={6} xs={12}>
						<AmountSmokingLoad amountItens={amountSmokingLoad} awaitData={awaitSmokingLoad} />
					</Grid>
					<Grid item lg={6} sm={6} xl={6} xs={12}>
						<AmountFargestromTest amountItens={amountFargestromTest} awaitData={awaitFargestromTest} />
					</Grid>
					<Grid item lg={8} md={12} xl={9} xs={12}>
						<UsersByResult amountUsers={usersPerResult} awaitData={awaitDataResult} />
					</Grid>
					<Grid item lg={4} md={6} xl={3} xs={12}>
						<UsersByType amountUsers={usersPerType} awaitData={awaitDataUserTypes} />
					</Grid>
					<Grid item lg={6} md={6} xl={6} xs={12}>
						<ForumMessagesByForum amountItens={forumMessages} awaitData={awaitForumMessages} />
					</Grid>
					<Grid item lg={6} md={6} xl={6} xs={12}>
						<ChallengesDoneLast24h amountItens={challenges24h} awaitData={awaitChallenges24h} />
					</Grid>
					<Grid item lg={12} sm={12} xl={12} xs={12}>
						<PanicTriggersLast24Hours amountItens={panic24h} awaitData={awaitPanic24h} />
					</Grid>
					<Grid item lg={12} md={12} xl={12} xs={12}>
						<MessagesSentLast24h amountItens={messages24h} awaitData={awaitMessages24h} />
					</Grid>
					<Grid item lg={12} sm={12} xl={12} xs={12}>
						<LastForumMessages amountItens={messagesTop10} awaitData={awaitMessagesTop10} />
					</Grid>
					<Grid item lg={12} sm={12} xl={12} xs={12}>
						<LastGroupMessages amountItens={messagesTop10} awaitData={awaitMessagesTop10} />
					</Grid>
				</Grid>
			</Container>
		</Page>
	);
}

export default Dashboard;