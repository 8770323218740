import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import moment from 'moment';
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    CircularProgress,
    Typography,
    IconButton,
    Paper,
    Grid,
    Avatar,
    Collapse,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ExpandMore } from '@mui/icons-material';
import ErrorIcon from '@mui/icons-material/Error';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    paperContainer: {
        padding: 5
    },
    breakText: {
        wordBreak: 'break-word',
    },
    expandMore: {
        cursor: 'pointer'
    },
}));

function PanicTriggersLast24Hours({ className, amountItens, awaitData, ...rest }) {
    const classes = useStyles();

    const [idCard, setIdCard] = useState('');

    const handleExpandClick = (idCardOpen) => {
        if (idCardOpen == idCard) {
            setIdCard(null);
        } else {
            setIdCard(idCardOpen);
        }
    };

    return (
        <Card className={clsx(classes.root, className)} {...rest}>
            <CardHeader
                title="ACIONAMENTOS DO BOTÃO DE PÂNICO NAS ÚLTIMAS 24 HORAS"
            />
            <Divider />
            <CardContent>
                {!awaitData ? (
                    <>
                        {amountItens?.length > 0 ? (
                            <Grid container justifyContent="center" justifyItems="center" alignItems="center" spacing={1}>
                                {amountItens.map((panic, idx) => (
                                    <Grid key={idx} item lg={12} md={12} sm={12} xs={12}>
                                        <Paper elevation={3} className={classes.paperContainer}>
                                            <CardHeader
                                                avatar={
                                                    <Avatar aria-label="errorIcon">
                                                        <ErrorIcon/>
                                                    </Avatar>
                                                }
                                                action={
                                                    <>
                                                        <IconButton
                                                            expand={idCard == panic.idRequestHelp}
                                                            onClick={() => handleExpandClick(panic.idRequestHelp)}
                                                            aria-expanded={idCard == panic.idRequestHelp}
                                                            aria-label="show more"
                                                            className={classes.expandMore}
                                                        >
                                                            {idCard == panic.idRequestHelp ? (
                                                                <ExpandLessIcon />
                                                            ) : (
                                                                <ExpandMoreIcon />
                                                            )}
                                                            
                                                        </IconButton>
                                                    </>
                                                }
                                                title={
                                                    <a className={classes.breakText}>
                                                        {panic.idUserNavigation.name}
                                                    </a>
                                                }
                                                subheader={moment(panic.date).format('DD/MM/YYYY')}
                                            />
                                            <Collapse in={idCard == panic.idRequestHelp} timeout="auto" unmountOnExit>
                                                <CardContent style={{width: '100%', display: 'flex', justifyContent: "space-between"}}>
                                                    <Box>
                                                        <Typography variant="body1" className={classes.breakText}>
                                                            Id do usuário: {panic.idUser}
                                                        </Typography>
                                                        <Typography variant="body1" className={classes.breakText}>
                                                            Email: {panic.idUserNavigation.email}
                                                        </Typography>
                                                        <Typography variant="body1" className={classes.breakText}>
                                                            Telefone: {panic.idUserNavigation.phone}
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <IconButton 
                                                            size="small" 
                                                            component={RouterLink}
                                                            to={`/usuarios/editar/${panic.idUser}`}
                                                        >
                                                            <ZoomInIcon />
                                                        </IconButton>
                                                    </Box>
                                                </CardContent>
                                            </Collapse>
                                        </Paper>
                                    </Grid>
                                ))}
                            </Grid>
                        ) : (
                            <Box mt={3} mb={3} display="flex" justifyContent="center" alignItems='center' style={{height: 300}}>
                                <Typography variant="h6">
                                    Não ocorreram acionamentos nas últimas 24 horas.
                                </Typography>
                            </Box>
                        )}
                    </>
                ) : (
                    <Box mt={3} display="flex" justifyContent="center">
                        <CircularProgress />
                    </Box>
                )}
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
            </Box>
        </Card>
    );
};

PanicTriggersLast24Hours.propTypes = {
    className: PropTypes.string,
    amountItens: PropTypes.array,
    awaitData: PropTypes.bool
};

export default PanicTriggersLast24Hours;