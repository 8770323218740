import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
  divPicker: {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px"
  },
  popover: {
    position: "absolute",
    zIndex: 2
  },
  color: {
    background: props => props.color,
    [theme.breakpoints.up("md")]: {
      width: '148px',
      height: '148px',
    },
    [theme.breakpoints.down("md")]: {
      width: '88px',
      height: '88px',
    },
    borderRadius: '2px'
  },
  swatch: {
    padding: '2px',
    borderRadius: '2px',
    background: '#fff',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: 'pointer',
  },
}));
