import React, { useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import CreateIcon from '@mui/icons-material/Create';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { NavLink as RouterLink } from 'react-router-dom';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {
	Box, Card,
	CardContent, Divider, Grid,
	Typography, CardHeader,
	IconButton, Menu, MenuItem
} from '@mui/material';

import stringRedutor from 'modules/utils/stringRedutor';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
	},
	statsItem: {
		alignItems: 'center',
		display: 'flex'
	},
	statsIcon: {
		marginRight: theme.spacing(1)
	}
}));

function MarathonCard({ className, marathon, updateStatusMarathon, ...rest }) {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	function handleClick(currentTarget) {
		setAnchorEl(currentTarget);
	}

	function handleClose() {
		setAnchorEl(null);
	}

	return (
		<Card className={clsx(classes.root, className)} {...rest}>
			<CardHeader
				title={stringRedutor(marathon.title, 25)}
				action={
					<Box>
						<IconButton
							aria-label="edit"
							component={RouterLink}
							to={`/desafio/editar/${marathon.idChallengeMarathon}`}
						>
							<CreateIcon />
						</IconButton>
						{/* <IconButton aria-label="settings" onClick={(e) => handleClick(e.currentTarget)} >
							<MoreVertIcon />
						</IconButton>

						<Menu
							id="basic-menu"
							anchorEl={anchorEl}
							open={open}
							onClose={handleClose}
						>
							<MenuItem onClick={(e) => {
								updateStatusMarathon(marathon.idChallengeMarathon)
								handleClick(e.currentTarget)
							}}>
								{marathon.isActive ? "Desativar maratona" : "Ativar maratona"}
							</MenuItem>
						</Menu> */}
					</Box>
				}
			/>
			<Box flexGrow={1} />
			<Divider />
			<CardContent>
				<Typography
					align="center"
					color="textPrimary"
					variant="body1"
				>
					{stringRedutor(marathon?.description, 50)}
				</Typography>
			</CardContent>
			<Box flexGrow={1} />
			<Divider />
			<Box p={2}>
				<Grid
					container
					justifyContent="space-between"
					spacing={2}
				>
					<Grid
						className={classes.statsItem}
						item
					>
						<AccessTimeIcon
							className={classes.statsIcon}
							color="action"
						/>
						<Typography
							color="textSecondary"
							display="inline"
							variant="body2"
						>
							{marathon?.createdAt ? moment(marathon?.createdAt).format('DD/MM/YYYY') : "--/--/----"}
						</Typography>
					</Grid>
					<Grid
						className={classes.statsItem}
						item
					>
						<FiberManualRecordIcon
							className={classes.statsIcon}
							sx={{ color: marathon.isActive ? 'green' : 'red' }}
						/>
						<Typography
							color="textSecondary"
							display="inline"
							variant="body2"
						>
							{marathon.isActive ? 'Ativo' : 'Inativo'}
						</Typography>
					</Grid>
				</Grid>
			</Box>
		</Card>
	);
};

MarathonCard.propTypes = {
	className: PropTypes.string,
	marathon: PropTypes.object.isRequired,
	updateStatusMarathon: PropTypes.func.isRequired
};

export default MarathonCard;