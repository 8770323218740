import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { format as formatDate } from 'date-fns';
import {
	Avatar,
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	Divider,
	Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const user = {
	avatar: '/static/images/avatars/avatar_6.png',
	city: 'Los Angeles',
	country: 'USA',
	jobTitle: 'Senior Developer',
	name: 'Katarina Smith',
	timezone: 'GTM-7'
};

const useStyles = makeStyles(() => ({
	root: {},
}));

function Profile({ className, ...rest }) {
	const classes = useStyles();
	return (
		<Card className={clsx(classes.root, className)} {...rest}>
			<CardContent>
				<Box
					alignItems="center"
					display="flex"
					flexDirection="column"
				>
					<Avatar
						sx={{ width: 100, height: 100 }}
						src={user.avatar}
					/>
					<Typography
						color="textPrimary"
						gutterBottom
						variant="h3"
					>
						{user.name}
					</Typography>
					<Typography
						color="textSecondary"
						variant="body1"
					>
						{`${user.city} ${user.country}`}
					</Typography>
					<Typography
						className={classes.dateText}
						color="textSecondary"
						variant="body1"
					>
						{`${formatDate(new Date(), 'hh:mm aa')} ${user.timezone}`}
					</Typography>
				</Box>
			</CardContent>
			<Divider />
			<CardActions>
				<Button
					color="primary"
					fullWidth
					variant="text"
				>
					Upload picture
        </Button>
			</CardActions>
		</Card>
	);
};

Profile.propTypes = {
	className: PropTypes.string
};

export default Profile;
