import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Search as SearchIcon } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
    Box, Button, Card,
    CardContent, TextField, InputAdornment,
    Grid, Select, MenuItem,
    InputLabel, FormControl
} from '@mui/material';

const useStyles = makeStyles((theme) => ({
    root: {},
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    },
    selectWidth: {
        width: '100%'
    }
}));

function Toolbar({
    className,
    inputValue,
    pagedObject,
    onPressButton,
    handleChange,
    inputLabel = '',
    ...rest
}) {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, className)} {...rest}>
            <Box mt={3}>
                <Card>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item md={6} xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item md={12} xs={12}>
                                        <TextField
                                            fullWidth
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <LoadingButton variant="contained" onClick={onPressButton}>
                                                            <SearchIcon fontSize="small" color="white" />
                                                        </LoadingButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                            placeholder={"Buscar por solicitação de ajuda"}
                                            variant="outlined"
                                            label={inputLabel}
                                            value={inputValue}
                                            onChange={(e) => handleChange("searchTerm", e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={3} xs={6}>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel id="select-requestHelpDate-label">Data da solicitação de ajuda</InputLabel>
                                    <Select
                                        labelId="select-requestHelpDate-label"
                                        id="select-requestHelpDate"
                                        value={pagedObject.requestHelpDate}
                                        label="Data da solicitação de ajuda"
                                        onChange={(e) => handleChange("requestHelpDate", e.target.value)}
                                        fullWidth
                                    >
                                        <MenuItem value={365}>1 ano atrás</MenuItem>
                                        <MenuItem value={180}>6 meses atrás</MenuItem>
                                        <MenuItem value={90}>3 meses atrás</MenuItem>
                                        <MenuItem value={30}>1 mês atrás</MenuItem>
                                        <MenuItem value={7}>1 semana atrás</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        </div>
    );
};

Toolbar.propTypes = {
    className: PropTypes.string,
    inputValue: PropTypes.string.isRequired,
    pagedObject: PropTypes.object.isRequired,
    onPressButton: PropTypes.func.isRequired,
    inputLabel: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
};

export default Toolbar;