import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import {
    Box, Container, Grid,
    Pagination, Typography,
    CircularProgress
} from '@mui/material';

import Page from 'components/Page';
import useArray from 'hooks/useArray';
import useSnackbar from 'hooks/useSnackbar';
import { Toolbar, VideoCard } from './components';
import RequestUtils from 'modules/api/RequestUtils';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
}));

function VideoList() {
    const classes = useStyles();
    const snackbar = useSnackbar();
    const arrVideos = useArray([]);
    const [awaitData, setAwaitingData] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [pagedObject, setPagedObject] = useState({
        page: 1,
        itensPerPage: 12,
        amountPage: 1,
        premium: false,
        videoActive: true,
        searchTerm: '',
    });

    function componentMount() {
        handlePagedVideos(pagedObject);
    }
    useEffect(componentMount, []);

    async function handlePagedVideos(pagedObject) {
        setAwaitingData(true);
        RequestUtils.getVideoPaged(
            pagedObject.searchTerm,
            pagedObject.page,
            pagedObject.itensPerPage,
            pagedObject.premium,
            pagedObject.videoActive,
        ).then((res) => {
            if (res?.svStatus && res.data) {
                const data = res.data;
                arrVideos.setValue(data.listVideos);
                setPagedObject({ ...pagedObject, amountPage: data.pageCount });
            } else {
                snackbar(res.msg || "Não foi possível listar vídeos.").error();
            }
            setAwaitingData(false);
        }).catch((e) => {
            snackbar("Erro ao listar vídeos.").error();
            setAwaitingData(false);
        });
    }

    function onPressButton() {
        const obj = { ...pagedObject, page: 1, searchTerm: inputValue };
        setPagedObject(obj);
        handlePagedVideos(obj);
    }

    async function updateStatusVideo(idVideoUpdate) {
        setAwaitingData(true);
		RequestUtils.updateStatusVideo(idVideoUpdate).then((res) => {
			if (res?.svStatus && res.data) {
				let arr = arrVideos.value.filter((video) => {
					if (video?.idVideo != idVideoUpdate) return video;
				});
				arrVideos.setValue(arr);
				snackbar("Status do vídeo atualizado com sucesso.").success();
			} else {
				snackbar(res.msg || "Não foi possível atualizar status do vídeo.").error();
			}
			setAwaitingData(false);
		}).catch((e) => {
			snackbar("Erro ao atualizar status do vídeo.").error();
			setAwaitingData(false);
		});
    }

    function handleChange(label, value) {
        let obj = pagedObject;

        switch (label) {
            case "searchTerm":
                setInputValue(value);
                if (value === "") obj = { ...obj, page: 1, searchTerm: value };
                break;
            case "videoActive":
                obj = { ...obj, page: 1, videoActive: value };
                break;
            case "premium":
                obj = { ...obj, page: 1, premium: value };
                break;
            default:
                break;
        }

        setPagedObject(obj);
        if (label !== "searchTerm" || value === "") handlePagedVideos(obj);
    }

    function handleChangePage(event, value) {
        const obj = { ...pagedObject, page: value };
        setPagedObject(obj);
        handlePagedVideos(obj);
    };

    return (
        <Page className={classes.root} title="Vídeos">
            <Container maxWidth={false}>
                <Toolbar
                    inputValue={inputValue}
                    pagedObject={pagedObject}
                    onPressButton={onPressButton}
                    handleChange={handleChange}
                />
                {!awaitData ? (
                    <>
                        {arrVideos.value.length > 0 ? (
                            <>
                                <Box mt={3}>
                                    <Grid container spacing={3}>
                                        {arrVideos.value.map((video) => (
                                            <Grid
                                                item key={video.idVideo}
                                                xs={12} md={6} lg={4}
                                            >
                                                <VideoCard
                                                    video={video}
                                                    updateStatusVideo={updateStatusVideo}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>
                                <Box mt={3} display="flex" justifyContent="center">
                                    <Pagination
                                        color="primary"
                                        size="small"
                                        count={pagedObject.amountPage}
                                        page={pagedObject.page}
                                        onChange={handleChangePage}
                                    />
                                </Box>
                            </>
                        ) : (
                            <Box mt={3} display="flex" justifyContent="center">
                                <Typography variant="body1" gutterBottom color="primary">
                                    Nenhum item encontrado.
                                </Typography>
                            </Box>
                        )}
                    </>
                ) : (
                    <Box mt={3} display="flex" justifyContent="center">
                        <CircularProgress />
                    </Box>
                )}
            </Container>
        </Page>
    )
}

export default VideoList;