import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import {
    Card, Grid, Modal, Box,
    CardHeader, Divider,
    CardContent
} from '@mui/material';

import { FormikTextField } from 'components/formElements';

const useStyles = makeStyles(() => ({
    modalContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        boxShadow: 24,
    },
}));

function AddStepModal({
    className,
    formik,
    awaitData,
    statusModal,
    setStatusModal,
    ...rest
}) {
    const classes = useStyles();

    function closeModal() {
        formik.resetForm();
        setStatusModal(false);
    }

    return (
        <Modal
            open={statusModal}
            onClose={() => closeModal()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            {...rest}
        >
            <Card className={classes.modalContainer}>
                <CardHeader
                    title="Cadastrar etapa do desafio"
                    subheader="Informe todos os dados para cadastrar uma nova etapa"
                />
                <Divider />
                <form onSubmit={formik.handleSubmit}>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item md={12} xs={12}>
                                <FormikTextField fullWidth formik={formik} name="title" label="Título" />
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <FormikTextField fullWidth formik={formik} name="position" label="Posição da etapa" type="number" />
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <FormikTextField
                                    fullWidth
                                    formik={formik}
                                    name="description"
                                    label="Descrição"
                                    multiline
                                    rows={3}
                                />
                            </Grid>
                        </Grid>
                        <Divider />
                    </CardContent>
                    <Divider />
                    <Box display="flex" justifyContent="flex-end" p={2}>
                        <LoadingButton
                            type="submit"
                            color="primary"
                            variant="contained"
                            loading={awaitData}
                        >
                            Cadastrar
                        </LoadingButton>
                    </Box>
                </form>
            </Card>
        </Modal>
    )
}

AddStepModal.propTypes = {
    className: PropTypes.string,
    formik: PropTypes.object.isRequired,
    awaitData: PropTypes.bool.isRequired,
    statusModal: PropTypes.bool.isRequired,
    setStatusModal: PropTypes.func.isRequired,
}

export default AddStepModal;
