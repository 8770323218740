import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { makeStyles } from '@mui/styles';
import { Container, Grid } from '@mui/material';
import { useParams, useHistory } from 'react-router-dom';

import Page from 'components/Page';
import useArray from 'hooks/useArray';
import useSnackbar from 'hooks/useSnackbar';
import RequestUtils from 'modules/api/RequestUtils';
import { encryptPassword } from 'modules/utils/encryption';
import useLocalStorage from 'hooks/useLocalStorage';
import { masks, regex, unmask, applyMask } from 'modules/utils/mask';
import {
    UserForm, PasswordForm, GroupsByUserList,
    SmokingLoadModal, FagerstromModal, PercentageModal,
    DiaryModal, UserChallengesList, HealthModal,
    StepsModal,
    UserDeleteModal
} from './components';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const validationUserCreate = Yup.object({
    name: Yup.string()
        .required('Campo obrigatório')
        .max(200, "O nome não pode ultrapassar 200 caracteres."),
    email: Yup.string()
        .required('Campo obrigatório')
        .max(150, "O E-mail não pode ultrapassar 150 caracteres.")
        .email('Digite um email válido'),
    phone: Yup.string()
        .required('Campo obrigatório')
        .min(masks.phone.length, "Telefone está incompleto")
        .max(masks.phone.length, `O telefone não pode ultrapassar ${masks.phone.length} caracteres.`)
        .matches(regex.phone, "Telefone não aceita letras ou caracteres especiais"),
    userType: Yup.number()
        .required('Campo obrigatório'),
    userIsPremium: Yup.number()
        .required('Campo obrigatório'),
    gender: Yup.string()
        .required('Campo obrigatório'),
    password: Yup.string()
        .required('Campo obrigatório')
        .max(150, "A senha não pode ultrapassar 150 caracteres."),
    confirmPassword: Yup.string()
        .required('Campo obrigatório')
        .oneOf([Yup.ref('password'), null], 'Senhas diferentes'),
    birthDate: Yup.string()
        .required('Campo obrigatório')
        .nullable('Campo obrigatório')
});

const validationUserEdit = Yup.object({
    name: Yup.string()
        .required('Campo obrigatório')
        .max(200, "O nome não pode ultrapassar 200 caracteres."),
    email: Yup.string()
        .required('Campo obrigatório')
        .max(150, "O E-mail não pode ultrapassar 150 caracteres.")
        .email('Digite um email válido'),
    phone: Yup.string()
        .required('Campo obrigatório')
        .min(masks.phone.length, "Telefone está incompleto")
        .max(masks.phone.length, `O telefone não pode ultrapassar ${masks.phone.length} caracteres.`)
        .matches(regex.phone, "Telefone não aceita letras ou caracteres especiais"),
    userType: Yup.number()
        .required('Campo obrigatório'),
    userIsPremium: Yup.number()
        .required('Campo obrigatório'),
    gender: Yup.string()
        .required('Campo obrigatório'),
    birthDate: Yup.string()
        .required('Campo obrigatório')
        .nullable('Campo obrigatório')
});

const validationPasswordEdit = Yup.object({
    password: Yup.string()
        .required('Campo obrigatório')
        .max(150, "A senha não pode ultrapassar 150 caracteres."),
    confirmPassword: Yup.string()
        .required('Campo obrigatório')
        .oneOf([Yup.ref('password'), null], 'Senhas diferentes')
});

function User() {
    const classes = useStyles();
    const snackbar = useSnackbar();
    const history = useHistory();
    const { idUser } = useParams();
    const arrGroups = useArray([]);
    const [currentUser, setCurrentUser] = useLocalStorage('currentUser');
    const [userData, setUserData] = useState();
    const [awaitData, setAwaitingData] = useState(false);
    const [awaitDataGroups, setAwaitingDataGroups] = useState(false);
    const [awaitDataPassword, setAwaitingDataPassword] = useState(false);

    const [healthData, setHealthData] = useState();
    const [awaitDataHealth, setAwaitDataHealth] = useState(false);
    const [healthModal, setHealthModal] = useState(false);

    const [diaryData, setDiaryData] = useState();
    const [diarySelected, setDiarySelected] = useState();
    const [diaryModal, setDiaryModal] = useState(false);
    const [awaitDataDiary, setAwaitDataDiary] = useState(false);

    const [smokingLoadData, setSmokingLoadData] = useState();
    const [smokingLoadModal, setSmokingLoadModal] = useState(false);
    const [awaitDataSmokingLoad, setAwaitDataSmokingLoad] = useState(false);

    const [percentageData, setPercentageData] = useState();
    const [percentageModal, setPercentageModal] = useState(false);
    const [awaitDataPercentage, setAwaitDataPercentage] = useState(false);

    const [fagerstromData, setFagerstromData] = useState();
    const [fagerstromModal, setFagerstromModal] = useState(false);
    const [awaitDataFagerstrom, setAwaitDataFagerstrom] = useState(false);

    const arrChallengeUser = useArray([]);
    const [awaitDataUserChallenges, setAwaitDataUserChallenges] = useState(false);

    const arrStepsUser = useArray([]);
    const [stepsModal, setStepsModal] = useState(false);
    const [awaitDataUserSteps, setAwaitDataUserSteps] = useState(false);

    const formikUser = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            userType: 0,
            password: '',
            confirmPassword: '',
            gender: 'Feminino',
            birthDate: null,
            userIsPremium: 0
        },
        validationSchema: idUser ? validationUserEdit : validationUserCreate,
        onSubmit: (values) => {
            handleSubmit(values);
        }
    });

    const formikDiary = useFormik({
        initialValues: {
            diaryDate: ''
        }
    })

    const formikPassword = useFormik({
        initialValues: {
            password: '',
            confirmPassword: '',
        },
        validationSchema: validationPasswordEdit,
        onSubmit: (values) => {
            handleEditPasswordGetKey(values);
        }
    });

    function componentMount() {
        if (!idUser) return;

        getUserData(idUser);
        getUserHealthData(idUser);
        getGroupsByUser(idUser);
        getSmokingLoadData(idUser);
        getPercentageData(idUser);
        getFagerstromData(idUser);
        getDiaryData(idUser);
        getChallengeUserParticipateAndProgress(idUser);
    }

    useEffect(componentMount, [])

    useEffect(() => {
        if (!diaryData) return;
        handleDiaryDate();
    }, [formikDiary.values.diaryDate])

    useEffect(() => {
        if (formikUser.values.userType == 1) {
            formikUser.values.userIsPremium = 0
        }
    }, [formikUser.values.userType, formikUser.values.userIsPremium])

    function getUserData(idUser) {
        setAwaitingData(true);
        RequestUtils.getUserById(idUser).then((res) => {
            if (res?.svStatus && res.data) {
                const user = res.data;
                setUserData(user);
                formikUser.setValues({
                    name: user?.name || '',
                    email: user?.email || '',
                    phone: applyMask(masks.phone, user?.phone) || '',
                    userType: Number(user?.userType) || 0,
                    password: user?.password || '',
                    confirmPassword: user?.password || '',
                    gender: user?.gender || 'Feminino',
                    birthDate: user?.birthDate || null,
                    userIsPremium: Number(user?.userIsPremium) || 0,
                });
            } else {
                snackbar(res.msg || "Não foi possível buscar dados do usuário.").error();
            }
            setAwaitingData(false);
        }).catch((e) => {
            snackbar("Erro ao buscar dados do usuário.").error();
            setAwaitingData(false);
        });
    }

    function getPercentageData(idUser) {
        setAwaitDataPercentage(true);
        RequestUtils.userPercentageOfConclusion(idUser).then((res) => {
            if (res?.svStatus && res.data) {
                let percentage = res.data;
                setPercentageData(percentage);
            } else {
                snackbar(res.msg || "Erro ao buscar a porcentagem de conclusão do usuário.").error();
            }
            setAwaitDataPercentage(false);
        }).catch((e) => {
            snackbar("Erro ao buscar a porcentagem de conclusão do usuário.").error();
            setAwaitDataPercentage(false);
        });
    }

    function getGroupsByUser(idUser) {
        setAwaitingDataGroups(true);
        RequestUtils.getGroupsByIdUser(idUser).then((res) => {
            if (res?.svStatus && res.data) {
                const groups = res.data;
                arrGroups.setValue(groups);
            } else {
                snackbar(res.msg || "Não foi listar os grupos do usuário.").error();
            }
            setAwaitingDataGroups(false);
        }).catch((e) => {
            snackbar("Erro ao listar os grupos do usuário.").error();
            setAwaitingDataGroups(false);
        });
    }

    function handleCreateUserGetKey(data) {
        setAwaitingData(true);
        RequestUtils.getPasswordKey().then((res) => {
            if (res?.svStatus && res.data) {
                createUser({
                    name: data.name,
                    email: data.email,
                    userType: Number(data.userType),
                    phone: unmask(data.phone),
                    password: encryptPassword(data.confirmPassword, res.data.key),
                    gender: data.gender,
                    birthDate: data.birthDate,
                    userIsPremium: Number(data.userIsPremium),
                })
            } else {
                snackbar(res.msg || "Não foi possível autenticar o usuário.").error();
            }
            setAwaitingData(false);
        }).catch((e) => {
            snackbar("Erro ao autenticar o usuário.").error();
            setAwaitingData(false);
        });
    }

    function getSmokingLoadData(idUser) {
        setAwaitDataSmokingLoad(true);
        RequestUtils.getTestSmokingLoadByIdUser(idUser).then((res) => {
            if (res?.svStatus && res.data) {
                const smokingLoad = res.data;
                setSmokingLoadData(smokingLoad);
            }
            setAwaitDataSmokingLoad(false);
        }).catch((e) => {
            snackbar("Erro ao buscar dados do teste Carga Tabágica do usuário.").error();
            setAwaitDataSmokingLoad(false);
        });
    }

    function getFagerstromData(idUser) {
        setAwaitDataFagerstrom(true);
        RequestUtils.getTestFargerstromByIdUser(idUser).then((res) => {
            if (res?.svStatus && res.data) {
                const fargerstrom = res.data;
                setFagerstromData(fargerstrom);
            }
            setAwaitDataFagerstrom(false);
        }).catch((e) => {
            snackbar("Erro ao buscar dados do teste Fargerstrom do usuário.").error();
            setAwaitDataFagerstrom(false);
        });
    }

    function getUserHealthData(idUser) {
        setAwaitDataHealth(true);
        RequestUtils.getHealthDataByIdUser(idUser).then((res) => {
            if (res?.svStatus) {
                const health = res.data;
                setHealthData(health);
            }
            setAwaitDataHealth(false);
        }).catch((e) => {
            snackbar("Erro ao buscar dados de saúde do usuário.").error();
            setAwaitDataHealth(false);
        });
    }

    function getDiaryData(idUser) {
        setAwaitDataDiary(true);
        RequestUtils.getDiaryByIdUser(idUser).then((res) => {
            if (res?.svStatus && res.data) {
                const diary = res.data;
                setDiaryData(diary);
                setDiarySelected(diary[0]);
            } else {
                snackbar(res.msg || "Não foi possível buscar dados de diário do usuário.").error();
            }
            setAwaitDataDiary(false);
        }).catch((e) => {
            snackbar("Erro ao buscar dados de diário do usuário.").error();
            setAwaitDataDiary(false);
        })
    }

    function getChallengeUserParticipateAndProgress(idUser) {
        setAwaitDataUserChallenges(true);
        RequestUtils.getChallengeUserParticipateAndProgress(idUser).then((res) => {
            if (res?.svStatus && res.data) {
                const challengeList = res.data;
                arrChallengeUser.setValue(challengeList);
            } else {
                snackbar(res.msg || "Não foi possível buscar os desafios do usuário.").error();
            }
            setAwaitDataUserChallenges(false);
        }).catch((e) => {
            snackbar("Erro ao buscar desafios do usuário.").error();
            setAwaitDataUserChallenges(false);
        })
    }

    function getChallengeSteps(idChallenge) {
        if (!idUser) return;

        setStepsModal(true);
        arrStepsUser.setValue([]);

        const index = arrChallengeUser.value.findIndex((challenge) => challenge?.challenge?.idChallenge == idChallenge);
        if (index < 0) return;

        if (arrChallengeUser.value[index].steps) {
            arrStepsUser.setValue(arrChallengeUser.value[index].steps);
        } else {
            setAwaitDataUserSteps(true);
            RequestUtils.getChallengeStepsByUserAndChallenge(idUser, idChallenge).then((res) => {
                if (res?.svStatus && res.data) {
                    const stepsList = res.data;
                    arrChallengeUser.value[index].steps = stepsList;
                    arrStepsUser.setValue(stepsList);
                } else {
                    snackbar(res.msg || "Não foi possível buscar as etapas do desafio.").error();
                }
                setAwaitDataUserSteps(false);
            }).catch((e) => {
                snackbar("Erro ao buscar as etapas do desafio.").error();
                setAwaitDataUserSteps(false);
            })
        }
    }

    function createUser(dto) {
        setAwaitingData(true);
        RequestUtils.addUser(dto).then((res) => {
            if (res?.svStatus && res.data) {
                snackbar("Usuário cadastrado com sucesso.").success();
                history.push(`/usuarios/editar/${res.data.idUser}`);
            } else {
                snackbar(res.msg || "Não foi possível cadastrar usuário.").error();
            }
            setAwaitingData(false);
        }).catch((e) => {
            snackbar("Erro ao cadastrar usuário.").error();
            setAwaitingData(false);
        });
    }

    function handleDiaryDate() {
        if (!diaryData) return;

        let diaryLocal = diaryData;

        let diaryFound = diaryLocal.find(diary => diary.idUserDiary == formikDiary.values.diaryDate);

        setDiarySelected(diaryFound);
    }

    function editUser(data) {
        const editUser = {
            idUser: userData.idUser,
            name: data.name,
            email: data.email,
            userType: Number(data.userType),
            phone: unmask(data.phone),
            gender: data.gender,
            birthDate: data.birthDate,
            userIsPremium: Number(data.userIsPremium),
        }

        setAwaitingData(true);
        RequestUtils.updateUser(editUser).then((res) => {
            if (res?.svStatus && res.data) {
                const user = res.data;
                formikUser.setValues({
                    name: user?.name || '',
                    email: user?.email || '',
                    phone: applyMask(masks.phone, user?.phone) || '',
                    userType: Number(user?.userType) || 0,
                    password: user?.password || '',
                    confirmPassword: user?.password || '',
                    gender: user?.gender || 'Feminino',
                    birthDate: user?.birthDate || null,
                    userIsPremium: Number(user?.userIsPremium) || 0,
                });

                setUserData(user);
                snackbar("Usuário atualizado com sucesso.").success();

                if (idUser && Number(idUser) == Number(currentUser.idUser)) {
                    setCurrentUser(user);
                    window.location.reload();
                }
            } else {
                snackbar(res.msg || "Não foi possível atualizar usuário.").error();
            }
            setAwaitingData(false);
        }).catch((e) => {
            snackbar("Erro ao atualizar usuário.").error();
            setAwaitingData(false);
        });
    }

    function handleSubmit(data) {
        if (idUser === undefined) {
            handleCreateUserGetKey(data);
        } else {
            editUser(data);
        }
    }

    function handleEditPasswordGetKey(data) {
        setAwaitingDataPassword(true);
        RequestUtils.getPasswordKey().then((res) => {
            if (res?.svStatus && res.data) {
                editPassword({
                    idUser: Number(idUser),
                    newPassword: encryptPassword(data.confirmPassword, res.data.key),
                })
            } else {
                snackbar(res.msg || "Não foi possível autenticar o usuário.").error();
            }
            setAwaitingDataPassword(false);
        }).catch((e) => {
            snackbar("Erro ao autenticar o usuário.").error();
            setAwaitingDataPassword(false);
        });
    }

    function editPassword(dto) {
        setAwaitingDataPassword(true);
        RequestUtils.updatePasswordAdmin(dto).then((res) => {
            if (res?.svStatus && res.data) {
                formikPassword.resetForm();
                snackbar("Senha atualizada com sucesso.").success();
            } else {
                snackbar(res.msg || "Não foi possível atualizar senha.").error();
            }
            setAwaitingDataPassword(false);
        }).catch((e) => {
            snackbar("Erro ao atualizar senha.").error();
            setAwaitingDataPassword(false);
        });
    }

    return (
        <Page className={classes.root} title={idUser === undefined ? "Cadastrar usuário" : "Editar usuário"}>
            <Container maxWidth="lg">
                <Grid container justifyContent="center" spacing={3}>
                    <Grid item lg={10} md={10} xs={12}>
                        <UserForm
                            formik={formikUser}
                            idUser={idUser}
                            awaitData={awaitData}
                            userData={userData}
                            setHealthModal={setHealthModal}
                            setDiaryModal={setDiaryModal}
                            setSmokingLoadModal={setSmokingLoadModal}
                            setFargerstromModal={setFagerstromModal}
                            setPercentageModal={setPercentageModal}
                        />
                    </Grid>
                    {idUser &&
                        <>
                            <Grid item lg={10} md={10} xs={12}>
                                <PasswordForm formik={formikPassword} awaitData={awaitDataPassword} />
                            </Grid>
                            <Grid item lg={10} md={10} xs={12}>
                                <GroupsByUserList arrGroups={arrGroups.value} awaitData={awaitDataGroups} />
                            </Grid>
                            <Grid item lg={10} md={10} xs={12}>
                                <UserChallengesList
                                    getSteps={getChallengeSteps}
                                    arrChallenges={arrChallengeUser.value}
                                    awaitData={awaitDataUserChallenges}
                                />
                                <StepsModal
                                    arrSteps={arrStepsUser.value}
                                    awaitData={awaitDataUserSteps}
                                    statusModal={stepsModal}
                                    setStatusModal={setStepsModal}
                                />
                            </Grid>
                            <Grid item lg={10} md={10} xs={12}>
                                <HealthModal
                                    statusModal={healthModal}
                                    setStatusModal={setHealthModal}
                                    awaitData={awaitDataHealth}
                                    testData={healthData}
                                />
                            </Grid>
                            <Grid item lg={10} md={10} xs={12}>
                                <SmokingLoadModal
                                    statusModal={smokingLoadModal}
                                    setStatusModal={setSmokingLoadModal}
                                    awaitData={awaitDataSmokingLoad}
                                    testData={smokingLoadData}
                                />
                            </Grid>
                            <Grid item lg={10} md={10} xs={12}>
                                <DiaryModal
                                    statusModal={diaryModal}
                                    setStatusModal={setDiaryModal}
                                    diarySelected={diarySelected}
                                    formik={formikDiary}
                                    awaitData={awaitDataDiary}
                                    testData={diaryData}
                                />
                            </Grid>
                            <Grid item lg={10} md={10} xs={12}>
                                <FagerstromModal
                                    statusModal={fagerstromModal}
                                    setStatusModal={setFagerstromModal}
                                    awaitData={awaitDataFagerstrom}
                                    testData={fagerstromData}
                                />
                            </Grid>
                            <Grid item lg={10} md={10} xs={12}>
                                <PercentageModal
                                    statusModal={percentageModal}
                                    setStatusModal={setPercentageModal}
                                    userName={formikUser.values.name}
                                    awaitData={awaitDataPercentage}
                                    percentageData={percentageData}
                                />
                            </Grid>
                        </>
                    }
                </Grid>
            </Container>
        </Page>
    );
}

export default User;