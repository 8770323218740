import React, { useState } from "react";
import PropTypes from "prop-types";
import { SketchPicker } from 'react-color';

import useStyles from './ColorPicker.styles';

const propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

const defaultProps = {
  disabled: false,
  value: '#fff',
};

function ColorPicker(props) {
  const { disabled, onChange, value } = props;
  const classes = useStyles({ color: value });

  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = () => {
    if (disabled) return;
    setDisplayColorPicker(!displayColorPicker)
  };

  const handleClose = () => {
    setDisplayColorPicker(false)
  };

  const handleChange = (color, event) => {
    onChange(color.hex, event);
  };

  return (
    <div style={{ opacity: disabled ? 0.5 : 1 }}>
      <div className={classes.swatch} onClick={handleClick}>
        <div className={classes.color} />
      </div>
      <div>
        {displayColorPicker ?
          <div className={classes.popover}>
            <div className={classes.divPicker} onClick={handleClose} />
            <SketchPicker color={value} onChange={handleChange} />
          </div>
          : null}
      </div>
    </div>
  )
}

ColorPicker.propTypes = propTypes;
ColorPicker.defaultProps = defaultProps;

export default ColorPicker;