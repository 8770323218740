import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography,
  colors
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Notifications from '@mui/icons-material/Notifications';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.indigo[600],
    height: 56,
    width: 56
  }
}));

function Notification({ className, amountItens, awaitData, ...rest }) {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest} >
      <CardContent>
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item>
            <Typography color="textSecondary" variant="h6" gutterBottom>
              NOTIFICAÇÕES
            </Typography>
            {!awaitData && (
              <Typography color="textPrimary" variant="h3">
                {amountItens?.amountNotification}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <Notifications />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

Notification.propTypes = {
  className: PropTypes.string
};

export default Notification;
