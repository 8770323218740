import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import Refresh from '@mui/icons-material/Refresh';
import {
    Grid, Divider, Card, CircularProgress,
    Box, Paper, CardActions, IconButton,
    CardContent, CardHeader, Typography
} from '@mui/material';



import { InputComment, MessageCard } from './index';

const useStyles = makeStyles(() => ({
    root: {
        height: '100%'
    },
    containerMessage: {
        padding: '10px',
        overflowY: 'scroll',
        height: '400px',
        maxHeight: '400px'
    },
    noItensBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
    },
    footerStyle: {
        display: 'flex',
        justifyContent: 'center',
    },
    footerInputWidth: {
        width: '80%',
    },
    progress: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
    loadingSize: {
        width: '1em !important',
        height: '1em !important',
    }
}));

function ChatRender({
    className, messages, idUser, refresh,
    awaitData, awaitDataMessage, sendMessage,
    forumData, deleteMessage, getPagedMessages,
    awaitDataAddMessage, pagedObject, messageReaction,
    scrollToEnd, ...rest
}) {
    const classes = useStyles();
    const [commentValue, setCommentValue] = useState('');
    const [commentChildValue, setCommentChildValue] = useState('');

    return (
        <Card {...rest} className={clsx(classes.root, className)}>
            <CardHeader
                title={forumData?.forumTitle || 'Fórum'}
                subheader={`Desafio: ${forumData?.idChallengeNavigation?.challengeTitle || '--'}`}
                action={
                    <Box>
                        <IconButton
                            aria-label="edit"
                            onClick={() => refresh()}
                        >
                            <Refresh />
                        </IconButton>
                    </Box>
                }
            />
            <Divider />
            <CardContent>
                <Paper variant="outlined" className={classes.containerMessage}>
                    {!awaitData ? (
                        <>
                            {messages.length > 0 ? (
                                <>
                                    {(pagedObject.page < pagedObject.amountPage) &&
                                        <Box mb={1} display={'flex'} justifyContent={'center'}>
                                            <IconButton onClick={getPagedMessages}>
                                                {awaitDataAddMessage ? (
                                                    <CircularProgress color="inherit" className={classes.loadingSize} />
                                                ) : (
                                                    <AddCircleOutlineRoundedIcon />
                                                )}
                                            </IconButton>
                                        </Box>
                                    }
                                    <Grid container spacing={3}>
                                        {messages.map((message, idx) => (
                                            <MessageCard
                                                key={idx}
                                                idUser={idUser}
                                                message={message}
                                                userMessage={message.idUser == idUser}
                                                deleteMessage={deleteMessage}
                                                awaitDataMessage={awaitDataMessage}
                                                setCommentValue={setCommentChildValue}
                                                commentValue={commentChildValue}
                                                sendMessage={sendMessage}
                                                messageReaction={messageReaction}
                                            />
                                        ))}
                                    </Grid>
                                </>
                            ) : (
                                <Box className={classes.noItensBox}>
                                    <Typography variant="body1">
                                        Nenhum item encontrado.
                                    </Typography>
                                </Box>
                            )}
                        </>
                    ) : (
                        <Box className={classes.progress}>
                            <CircularProgress />
                        </Box>
                    )}
                    <div style={{ float: "left", clear: "both" }} ref={scrollToEnd} />
                </Paper>
            </CardContent>
            <Divider />
            <CardActions disableSpacing className={classes.footerStyle}>
                <Box className={classes.footerInputWidth}>
                    <InputComment
                        awaitDataMessage={awaitDataMessage}
                        allowImage={forumData?.allowImage}
                        setCommentValue={setCommentValue}
                        commentValue={commentValue}
                        replyValue={null}
                        sendMessage={sendMessage}
                    />
                </Box>
            </CardActions>
        </Card >
    )
}

ChatRender.propTypes = {
    className: PropTypes.string,
    refresh: PropTypes.func,
    messages: PropTypes.array.isRequired,
    idUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    awaitData: PropTypes.bool.isRequired,
    awaitDataMessage: PropTypes.bool.isRequired,
    sendMessage: PropTypes.func.isRequired,
    forumData: PropTypes.object,
    deleteMessage: PropTypes.func.isRequired,
    getPagedMessages: PropTypes.func.isRequired,
    awaitDataAddMessage: PropTypes.bool.isRequired,
    pagedObject: PropTypes.object.isRequired,
    messageReaction: PropTypes.func.isRequired,
    scrollToEnd: PropTypes.object.isRequired
}

export default ChatRender;