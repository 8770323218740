import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Box, Card, CardContent,
    Grid, Typography, CardHeader,
    Divider, Paper, Avatar,
    IconButton, CircularProgress, Collapse, FormControl, InputLabel, Select, MenuItem
} from '@mui/material';

const useStyles = makeStyles(() => ({
    statusMarathon: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
    },
    paperContainer: {
        padding: 5,
    },
    breakText: {
        wordBreak: 'break-word'
    }
}));

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

function MarathonTree({ className, arrChallenge, awaitData, ...rest }) {
    const classes = useStyles();
    const [idCard, setIdCard] = useState('');
    const [isChallengeActive, setIsChallengeActive] = useState(true);

    const handleExpandClick = (idCardOpen) => {
        if (idCardOpen == idCard) {
            setIdCard('');
        } else {
            setIdCard(idCardOpen);
        }
    };

    const filterList = arrChallenge[0]?.filter((cha) => cha?.challengeIsActive === isChallengeActive);

    const filterDesorted =  arrChallenge[1]?.filter((cha) => cha?.challengeIsActive === isChallengeActive);

    function renderTree(data, isDisorganized) {
        return (
            <>
                {!awaitData ? (
                    <>
                        {data?.length > 0 ? (
                            <Grid container justifyContent="center" justifyItems="center" alignItems="center" spacing={1}>
                                {data.map((challenge, idx) => (
                                    <Grid key={idx} item lg={12} md={12} sm={12} xs={12}>
                                        <Paper elevation={3} className={classes.paperContainer}>
                                            <CardHeader
                                                avatar={
                                                    <Avatar aria-label="recipe">
                                                        {isDisorganized ? challenge.idChallenge : (idx + 1)}
                                                    </Avatar>
                                                }
                                                action={
                                                    <>
                                                        <ExpandMore
                                                            expand={idCard == challenge.idChallenge}
                                                            onClick={() => handleExpandClick(challenge.idChallenge)}
                                                            aria-expanded={idCard == challenge.idChallenge}
                                                            aria-label="show more"
                                                        >
                                                            <ExpandMoreIcon />
                                                        </ExpandMore>
                                                    </>
                                                }
                                                title={
                                                    <a className={classes.breakText}>
                                                        {challenge.challengeTitle}
                                                    </a>
                                                }
                                                subheader={isDisorganized ? `Próximo desafio: ${challenge.nextChallengeTitle || '--'}` : ""}
                                            />
                                            <Collapse in={idCard == challenge.idChallenge} timeout="auto" unmountOnExit>
                                                <CardContent>
                                                    <Typography variant="body1" className={classes.breakText}>
                                                        {challenge.challengeDescription}
                                                    </Typography>
                                                </CardContent>
                                            </Collapse>
                                        </Paper>
                                    </Grid>
                                ))}
                            </Grid>
                        ) : (
                            <Box mt={3} display="flex" justifyContent="center">
                                <Typography variant="body1" gutterBottom>
                                    Nenhum fluxo encontrado.
                                </Typography>
                            </Box>
                        )}
                    </>
                ) : (
                    <Box mt={3} display="flex" justifyContent="center">
                        <CircularProgress />
                    </Box>
                )}
            </>
        );
    }

    return (
        <Card {...rest} className={clsx(classes.root, className)}>
            <Grid container justifyContent="space-between" alignItems="center" pr={2}>
                <Grid item md={3} xs={4}>
                    <CardHeader
                        title={"Ordem dos desafios"}
                        subheader={"Desafios ordenados"}
                    />
                </Grid>
                <Grid item md={3} xs={4}>
                    <FormControl sx={{ width: '100%' }}>
                        <InputLabel id="select-marathonIsPremium-label">Status dos desafios</InputLabel>
                        <Select
                            labelId="select-challenge-active-label"
                            id="select-challenge-active"
                            label="Status do desafio"
                            value={isChallengeActive}
                            onChange={(e) => setIsChallengeActive(e.target.value)}
                            fullWidth
                        >
                            <MenuItem value={true}>Ativo</MenuItem>
                            <MenuItem value={false}>Inativo</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Divider />
            <CardContent>
                {renderTree(filterList, false)}
            </CardContent>

            <Divider />
            <CardHeader subheader={"Desafios desordenados"} />
            <Divider />
            <CardContent>
                {renderTree(filterDesorted, true)}
            </CardContent>
        </Card>
    )
}

MarathonTree.propTypes = {
    className: PropTypes.string,
    arrChallenge: PropTypes.array,
    awaitData: PropTypes.bool.isRequired,
}

export default MarathonTree;