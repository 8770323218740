import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import { useFormik } from 'formik';
import { makeStyles } from '@mui/styles';
import { Container, Grid } from '@mui/material';
import { useParams, useHistory } from 'react-router-dom';

import Page from 'components/Page';
import useSnackbar from 'hooks/useSnackbar';
import { NotificationForm, AddUserNotificationModal } from './components';
import RequestUtils from 'modules/api/RequestUtils';
import useLocalStorage from 'hooks/useLocalStorage';
import useArray from 'hooks/useArray';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const validationNotification = Yup.object({
    title: Yup.string()
        .required('Campo obrigatório')
        .max(100, "O título não pode ultrapassar 100 caracteres."),
    topic: Yup.string()
        .required('Campo obrigatório')
        .max(100, "O tópico não pode ultrapassar 100 caracteres."),
    body: Yup.string()
        .required('Campo obrigatório')
        .max(200, "A descrição não pode ultrapassar 200 caracteres."),
});

function Notification() {
    const classes = useStyles();
    const history = useHistory();
    const snackbar = useSnackbar();
    const { idNotification } = useParams();
    const [currentUser, setCurrentUser] = useLocalStorage('currentUser');
    const arrUsers = useArray([]);
    const [awaitData, setAwaitingData] = useState(false);
    const [statusModal, setStatusModal] = useState(false);
    const [userSelected, setUserSelected] = useState({
        name: '',
        id: 0,
        disabled: true
    });
    const [inputValue, setInputValue] = useState('');
    const [pagedObject, setPagedObject] = useState({
        searchTerm: '',
        page: 1,
        itensPerPage: 6,
        userType: 0,
        userPlan: 2,
        amountPage: 1
    });

    const formikNotification = useFormik({
        initialValues: {
            type: 'TYPE_TOPIC',
            title: '',
            topic: 'USER_TYPE_FREE',
            body: ''
        },
        validationSchema: validationNotification,
        onSubmit: (values) => {
            createNotification(values);
        }
    });

    function componentMount() {
        handlePagedUsers(pagedObject);
        if (!idNotification) return;
        getNotificationData(idNotification);
    }
    useEffect(componentMount, [])

    function getNotificationData(idNotification) {
        setAwaitingData(true);
        RequestUtils.getNotificationById(idNotification).then((res) => {
            if (res?.svStatus && res.data) {
                const notification = res.data;
                formikNotification.setValues({
                    title: notification.title || '',
                    body: notification.body || '',
                    topic: notification.topic || '',
                });
            } else {
                snackbar(res.msg || "Não foi possível buscar dados da notificação.").error();
            }
            setAwaitingData(false);
        }).catch((e) => {
            snackbar("Erro ao buscar dados da notificação.").error();
            setAwaitingData(false);
        });
    }

    function onPressButton() {
        const obj = { ...pagedObject, page: 1, searchTerm: inputValue };
        setPagedObject(obj);
        handlePagedUsers(obj);
    }

    async function handlePagedUsers(pagedObject) {
        setAwaitingData(true);

        const plan = pagedObject.userPlan == 2 ? null : !!pagedObject.userPlan;
        
        RequestUtils.getUsersPaged(
            pagedObject.searchTerm,
            pagedObject.page,
            pagedObject.itensPerPage,
            pagedObject.userType,
            true,
            plan
        ).then((res) => {
            if (res?.svStatus && res.data) {
                const data = res.data;
                setPagedObject({ ...pagedObject, amountPage: data.pageCount });
                arrUsers.setValue(data.listUser);
            } else {
                snackbar(res.msg || "Não foi possível listar usuários.").error();
            }
            setAwaitingData(false);
        }).catch((e) => {
            snackbar("Erro ao listar usuários.").error();
            setAwaitingData(false);
        });
    }

    function createNotification(data) {
        if (!currentUser.idUser) return;

        let dto = {
            idUserSent: currentUser.idUser,
            title: data.title,
            topic: data.type === "TYPE_USER" ? `U${userSelected.id}` : data.topic,
            body: data.body,
            dateSend: moment().format(),
        }

        setAwaitingData(true);
        RequestUtils.addNotification(dto).then((res) => {
            if (res?.svStatus && res.data) {
                formikNotification.resetForm();
                snackbar("Notificação enviada com sucesso.").success();
                history.push(`/notificacoes`);
            } else {
                snackbar(res.msg || "Não foi possível cadastrar notificação.").error();
            }
            setAwaitingData(false);
        }).catch((e) => {
            snackbar("Erro ao cadastrar notificação.").error();
            setAwaitingData(false);
        });
    }

    function handleChangePage(event, value) {
        const obj = { ...pagedObject, page: value };
        setPagedObject(obj);
        handlePagedUsers(obj);
    };

    function handleChange(label, value) {
        let obj = pagedObject;

        switch (label) {
            case "searchTerm":
                setInputValue(value);
                if (value === "") obj = { ...obj, page: 1, searchTerm: value };
                break;
            case "userType":
                obj = { ...obj, page: 1, userType: value };
                break;
            case "userPlan":
                obj = { ...obj, page: 1, userPlan: value };
                break;
            default:
                break;
        }

        setPagedObject(obj);
        if (label !== "searchTerm" || value === "") handlePagedUsers(obj);
    }

    function addNotification(idUser, name) {
        let userSelected = {
            name: name,
            id: idUser,
            disabled: false
        };

        setUserSelected(userSelected);
        setStatusModal(false);
    }

    return (
        <Page className={classes.root} title={idNotification == undefined ? "Cadastrar notificação" : "Duplicar notificação"}>
            <Container maxWidth="lg">
                <Grid container justifyContent="center" spacing={3}>
                    <Grid item lg={10} md={10} xs={12}>
                        <AddUserNotificationModal
                            addNotification={addNotification}
                            statusModal={statusModal}
                            setStatusModal={setStatusModal}
                            handleChange={handleChange}
                            pagedObject={pagedObject}
                            inputValue={inputValue}
                            onPressButton={onPressButton}
                            handleChangePage={handleChangePage}
                            arrUsers={arrUsers.value}
                        />
                        <NotificationForm
                            formik={formikNotification}
                            awaitData={awaitData}
                            userSelected={userSelected}
                            setStatusModal={setStatusModal} 
                        />
                    </Grid>
                </Grid>
            </Container>
        </Page>
    )
}

export default Notification;
