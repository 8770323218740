import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import { Pagination } from '@mui/lab';
import {
    Box, Container, Grid,
    Typography, CircularProgress
} from '@mui/material';

import Page from 'components/Page';
import useArray from 'hooks/useArray';
import { Toolbar, RequestHelpCard } from './components';
import useSnackbar from 'hooks/useSnackbar';
import RequestUtils from 'modules/api/RequestUtils';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
}));

function RequestHelpList() {
    const classes = useStyles();
    const snackbar = useSnackbar();
    const [awaitData, setAwaitingData] = useState(false);
    const arrRequestHelp = useArray([]);
    const [inputValue, setInputValue] = useState('');
    const [pagedObject, setPagedObject] = useState({
        searchTerm: '',
        page: 1,
        itensPerPage: 12,
        requestHelpDate: 7,
        amountPage: 1
    });

    function componentMount() {
        handlePagedRequestHelp(pagedObject);
    }
    useEffect(componentMount, []);

    async function handlePagedRequestHelp(pagedObject) {
        setAwaitingData(true);

        RequestUtils.getRequestHelpPaged(
            pagedObject.requestHelpDate,
            pagedObject.searchTerm,
            pagedObject.page,
            pagedObject.itensPerPage
        ).then((res) => {
            if (res?.svStatus && res.data) {
                const data = res.data;
                setPagedObject({ ...pagedObject, amountPage: data.pageCount });
                arrRequestHelp.setValue(data.listRequestHelp);
            } else {
                snackbar(res.msg || "Não foi possível listar as solicitações de ajuda.").error();
            }
            setAwaitingData(false);
        }).catch((e) => {
            snackbar("Erro ao listar as solicitações de ajuda.").error();
            setAwaitingData(false);
        });
    }

    function onPressButton() {
        const obj = { ...pagedObject, page: 1, searchTerm: inputValue };
        setPagedObject(obj);
        handlePagedRequestHelp(obj);
    }

    function handleChange(label, value) {
        let obj = pagedObject;

        switch (label) {
            case "searchTerm":
                setInputValue(value);
                if (value === "") obj = { ...obj, page: 1, searchTerm: value };
                break;
            case "requestHelpDate":
                obj = { ...obj, page: 1, requestHelpDate: value };
                break;
            default:
                break;
        }

        setPagedObject(obj);
        if (label !== "searchTerm" || value === "") handlePagedRequestHelp(obj);
    }

    function handleChangePage(event, value) {
        const obj = { ...pagedObject, page: value };
        setPagedObject(obj);
        handlePagedRequestHelp(obj);
    };

    return (
        <Page className={classes.root} title="Solicitações de ajuda">
            <Container maxWidth={false}>
                <Toolbar
                    inputValue={inputValue}
                    pagedObject={pagedObject}
                    onPressButton={onPressButton}
                    handleChange={handleChange}
                />
                {!awaitData ? (
                    <>
                        {arrRequestHelp.value.length > 0 ? (
                            <>
                                <Box mt={3}>
                                    <Grid container spacing={3}>
                                        {arrRequestHelp.value?.map((help, idx) => (
                                            <Grid
                                                item
                                                key={idx}
                                                lg={4}
                                                md={6}
                                                xs={12}
                                            >
                                                <RequestHelpCard
                                                    requestHelp={help}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>
                                <Box mt={3} display="flex" justifyContent="center">
                                    <Pagination
                                        color="primary"
                                        size="small"
                                        count={pagedObject.amountPage}
                                        page={pagedObject.page}
                                        onChange={handleChangePage}
                                    />
                                </Box>
                            </>
                        ) : (
                            <Box mt={3} display="flex" justifyContent="center">
                                <Typography variant="body1" gutterBottom color="primary">
                                    Nenhum item encontrado.
                                </Typography>
                            </Box>
                        )}
                    </>
                ) : (
                    <Box mt={3} display="flex" justifyContent="center">
                        <CircularProgress />
                    </Box>
                )}
            </Container>
        </Page>
    )
}

export default RequestHelpList;