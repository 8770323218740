import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Person from '@mui/icons-material/Person';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.red[600],
    height: 56,
    width: 56
  },
  differenceIcon: {
    color: colors.red[900]
  },
  differenceValue: {
    color: colors.red[900],
    marginRight: theme.spacing(1)
  }
}));

function Users({ className, amountItens, awaitData, ...rest }) {
  const classes = useStyles();
  return (

    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item>
            <Typography color="textSecondary" variant="h6" gutterBottom>
              USUÁRIOS
            </Typography>
            {!awaitData && (
              <Typography color="textPrimary" variant="h3">
                {amountItens?.amountUser}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <Person />
            </Avatar>
          </Grid>
        </Grid>
        {/* <Box mt={2} display="flex" alignItems="center">
          
          <Typography className={classes.differenceValue} variant="body2">
            16%
          </Typography>
          <Typography color="textSecondary" variant="caption">
            Since last month
          </Typography>
        </Box> */}
      </CardContent>
    </Card>
  );
};

Users.propTypes = {
  className: PropTypes.string,
  amountItens: PropTypes.object,
  awaitData: PropTypes.bool,
};

export default Users;
